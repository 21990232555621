import { create } from "zustand";

export enum SitePage {
  Home,
  Assets,
  Performance,
  Finance,
  Carbon,
  Tariff,
}

interface SitesStore {
  page: SitePage;
  setPage: (page: SitePage) => void;
}

const useCurrentSiteStore = create<SitesStore>((set) => ({
  page: SitePage.Home,
  setPage: (page: SitePage) => set({ page }),
}));

export default useCurrentSiteStore;
