import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  closeButton: {
    all: "unset",
    color: "white",
    padding: 10,
    cursor: "pointer",
  },
});

export default useStyles;
