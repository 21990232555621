import useStyles from "./styles";
import { Line } from "react-chartjs-2";
import useWindowSize from "../../../hooks/use-window-size";
import { ChartData, Point } from "chart.js";
import Loader from "../../loader";
import { useEffect, useRef, useState } from "react";
import Button from "../../button";
import Export from "./export";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  data: ChartData<"line", (number | Point | null)[], unknown>;
  options?: any;
  chartHeight?: number | string;
  canZoom?: boolean;
  canExport?: boolean;
  exportName?: string;
}

const LineChart = (props: Props) => {
  const {
    data,
    loading,
    chartHeight = 500,
    canZoom,
    canExport,
    exportName,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles({ chartHeight });
  const { width } = useWindowSize();
  const chartRef = useRef<any>(null);
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    onResetZoom();
  }, [data]);

  if (canZoom) {
    props.options.plugins.zoom = {
      zoom: {
        drag: {
          enabled: true,
        },
        mode: "x",
        onZoom: () => {
          setIsZoomed(true);
        },
      },
    };
  }

  const onResetZoom = () => {
    chartRef.current.resetZoom();
    setTimeout(() => setIsZoomed(false), 100);
  };

  return (
    <div className={classes.graph}>
      <div className={classes.actions}>
        {canZoom && (
          <Button
            onClick={onResetZoom}
            name={t("Reset_Zoom")}
            disabled={!isZoomed}
          />
        )}
        <Export
          chartRef={chartRef}
          canExport={canExport!}
          exportName={exportName!}
        />
      </div>
      <Line
        ref={chartRef}
        options={props.options}
        data={data}
        style={{ gridRow: 2, gridColumn: 1 }}
      />

      {loading && (
        <div className={classes.overlayContainer}>
          <div className={classes.overlay} />
          <div className={classes.loader}>
            <Loader />
          </div>
        </div>
      )}
    </div>
  );
};

export default LineChart;
