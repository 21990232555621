import { Stack } from "@mui/material";
import useStyles from "./styles";
import DaysActiveDisplay from "../../../../../core/days-active-display";
import Button from "../../../../../core/button";
import { getCurrency } from "../../../../../utils/constant";
import moment from "moment";
import { Fragment, useState } from "react";
import useConfirmationStore from "../../../../../hooks/use-confirmation";
import { enqueueSnackbar } from "notistack";
import client from "../../../../../utils/client";
import EditRatePeriodDialog from "./edit-dialog";
import AddRatePeriodDialog from "./add-dialog";
import { useTranslation } from "react-i18next";
import Box from "../../../../../core/box";

interface Props {
  tariff: any;
  selectedRateId: number;
  load: () => void;
}

const RatePeriods = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tariff, selectedRateId, load } = props;
  const { onOpen } = useConfirmationStore();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRatePeriod, setSelectedRatePeriod] = useState<any>({});
  const selectedRate =
    tariff.tariffRates.find((r: any) => r.rateId == selectedRateId) || {};

  const { ratePeriods = [] } = selectedRate;

  const onDelete = (periodId: number) => {
    onOpen({
      title: t("Confirm_delete_rate"),
      description: t("permanent_action_warning"),
      callback: async () => {
        try {
          await client.delete(`/tariffRatePeriods/delete/${periodId}`);
          load();
          enqueueSnackbar(t("Rate_successfully_deleted"), {
            variant: "success",
            persist: false,
          });
        } catch {
          enqueueSnackbar(t("Error_deleting_rate"), {
            variant: "error",
            persist: false,
          });
        }
      },
    });
  };

  return (
    <Box>
      <h4>{t("Tariff_Rate_Periods")}</h4>

      {ratePeriods == 0 ? (
        <div className={classes.noRatePeriods}>
          {t("No_tariff_rates_to_show_for_period")}
        </div>
      ) : (
        <div className={classes.ratePeriodGrid}>
          {ratePeriods.map((rate: any) => {
            return (
              <Fragment key={rate.ratePeriodId}>
                <div>{rate.periodName}</div>
                <div>{rate.direction}</div>
                <div>
                  {getCurrency(tariff.currency)}
                  {rate.unitPriceKwh} {t("per_kWh")}
                </div>
                {rate.startTime == rate.endTime ? (
                  <div>{t("All_Day")}</div>
                ) : (
                  <Stack direction={"row"}>
                    <div>
                      {moment(rate.startTime, t("time_format_s")).format(
                        t("time_format")
                      )}
                    </div>
                    <div> - </div>
                    <div>
                      {moment(rate.endTime, t("time_format_s")).format(
                        t("time_format")
                      )}
                    </div>
                  </Stack>
                )}

                <DaysActiveDisplay
                  monday={rate.monday}
                  tuesday={rate.tuesday}
                  wednesday={rate.wednesday}
                  thursday={rate.thursday}
                  friday={rate.friday}
                  saturday={rate.saturday}
                  sunday={rate.sunday}
                />
                <Stack direction={"row"}>
                  <Button
                    onClick={() => onDelete(rate.ratePeriodId)}
                    colour={"error"}
                  >
                    {t("Delete")}
                  </Button>
                  <Button
                    onClick={() => {
                      setEditOpen(true);
                      setSelectedRatePeriod({ ...rate });
                    }}
                  >
                    {t("Edit")}
                  </Button>
                </Stack>
              </Fragment>
            );
          })}
        </div>
      )}
      <Stack direction="row" justifyContent={"end"} marginTop={1}>
        <Button
          onClick={() => setAddOpen(true)}
          variant={"contained"}
          size="small"
        >
          {t("Add_Rate")}
        </Button>
      </Stack>

      <EditRatePeriodDialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        load={load}
        selectedRatePeriod={selectedRatePeriod}
        currency={getCurrency(tariff.currency)}
      />

      <AddRatePeriodDialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        load={load}
        rateId={selectedRateId}
        currency={getCurrency(tariff.currency)}
      />
    </Box>
  );
};

export default RatePeriods;
