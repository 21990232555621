import { Divider, Stack } from "@mui/material";
import useNewTariffStore from "../../new-tariff-store";
import useStyles from "./styles";
import DaysActiveDisplay from "../../../../../../../core/days-active-display";
import { useTranslation } from "react-i18next";
import { AdditionalChargeProps } from "../../types";

interface Props {
  additionalCharges: AdditionalChargeProps[];
}

const OverviewCharges = (props: Props) => {
  const classes = useStyles();
  const { additionalCharges } = props;
  const { t } = useTranslation();
  const { baseCharges, pricingUnits, currency } = useNewTariffStore();

  return (
    <div className={classes.chargeGrid}>
      {additionalCharges.map((charge) => {
        const chargeName = baseCharges.find(
          (bc) => bc.baseChargeId == charge.fkBaseChargeId
        )?.chargeName;

        const name = chargeName != null ? chargeName : charge.chargeName;

        const pricingUnit = pricingUnits.find(
          (pu) => pu.pricingUnitId == charge.fkPricingUnitId
        )?.pricingUnit;

        return (
          <>
            <div>{name}</div>
            {charge.startTime == charge.endTime ? (
              <div>{t("All_Day")}</div>
            ) : (
              <Stack direction={"row"}>
                <div>{charge.startTime}</div> - <div>{charge.endTime}</div>
              </Stack>
            )}
            <div>
              {currency}
              {charge.amount} {pricingUnit}
            </div>
            <DaysActiveDisplay
              monday={charge.monday}
              tuesday={charge.tuesday}
              wednesday={charge.wednesday}
              thursday={charge.thursday}
              friday={charge.friday}
              saturday={charge.saturday}
              sunday={charge.sunday}
            />
          </>
        );
      })}
    </div>
  );
};

export default OverviewCharges;
