import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../../utils/ionic";
import BackButton from "../../../../core/back";
import useStyles from "./styles";
import Divider from "../../../../core/divider";
import { getCurrency } from "../../../../utils/constant";

interface Props {
  currentExport: number;
  currency: string;
  importLast7: number;
  exportLast7: number;
}

const MeteringPointDetails = (props: Props) => {
  const { currency, currentExport, importLast7, exportLast7 } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const currencySymbol = getCurrency(currency);

  return (
    <div>
      {!isNativeMobile() && (
        <>
          <BackButton defaultRoute={"/"} />
          <Divider />
          <h2>{t("Metering_Point")}</h2>
        </>
      )}

      {currentExport != null && (
        <div className={classes.detailsRow}>
          <div className={classes.label}>{t("Current_Export_Rate")}</div>
          <div className={classes.value}>
            {currencySymbol}
            {currentExport}
          </div>
        </div>
      )}

      <div className={classes.detailsRow} data-testid="metering-point-export">
        <div className={classes.label}>{t("Exp_last_7_days")}</div>
        <div className={classes.value}>{(exportLast7 || 0).toFixed(2)} kWh</div>
      </div>

      <div className={classes.detailsRow} data-testid="metering-point-import">
        <div className={classes.label}>{t("Imp_last_7_days")}</div>
        <div className={classes.value}>{(importLast7 || 0).toFixed(2)} kWh</div>
      </div>

      <Divider />
    </div>
  );
};

export default MeteringPointDetails;
