import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: 30,
    marginBottom: 30,
  },

  title: {
    fontSize: 13,
  },

  subtitle: {
    size: 14,
    color: theme.primary.textColor2,
    fontWeight: 500,
  },

  detailsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
  },

  label: {
    color: theme.primary.textColor2,
    display: "flex",
    gap: 5,
  },

  value: {
    color: theme.primary.textColor,
  },
}));

export default useStyles;
