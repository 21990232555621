import { useEffect } from "react";
import { getAuth, signInWithEmailLink } from "firebase/auth";
import useAuthStore from "../../../hooks/use-auth";
import client from "../../../utils/client";
import { useTranslation } from "react-i18next";
import Loader from "../../../core/loader";
import { isPlatform } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "../../../hooks/use-navigate";
import { Domain, getDomain } from "../../../utils/get-domain";

const AuthEmailLogin = () => {
  const { t } = useTranslation();
  const { onLogin } = useAuthStore();
  const { goTo } = useNavigate();

  useEffect(() => {
    const signInWithEmail = async () => {
      const auth = getAuth();
      try {
        const email = window.localStorage.getItem('emailForTESCSignIn');
        if (!email) throw new Error("Email could not be retrieved from local storage");
        const loginResponse = await signInWithEmailLink(auth, email, window.location.href);
        const token = await loginResponse.user?.getIdToken();

        client.defaults.headers.common = {
          Authorization: `${token}`,
        };
        useAuthStore.setState({ manualAuth: true });
        onLogin(loginResponse.user as any, t("Verification_email_sent"));
      } catch (error: any) {
        goTo("/login");
      }
    }

    if (isPlatform("ios") && !Capacitor.isNativePlatform()) {
      const domain = getDomain();
      const appScheme = (domain === Domain.SNXT) ? "aileeninsights" : "parisinsights";
      const url = (domain === Domain.SNXT)
          ? process.env.REACT_APP_AI_INSIGHTS_WEBAPP_URL
          : process.env.REACT_APP_PARIS_INSIGHTS_WEBAPP_URL

      window.location.href = window.location.href.replace(`${url}`, `${appScheme}:/`);
    } else {
      signInWithEmail();
    }
  }, [])

  return (
    <Loader/>
  );
};

export default AuthEmailLogin;
