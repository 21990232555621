import { getDomain } from "../../utils/get-domain";

function updateSiteDetails() {
    const domain = getDomain();
    let title = "PARIS Insights";
    let description = "Making the complex, simple";
    let favicon = "images/logo.webp";
    if (domain === "SNXT") {
      title = "SonneNext";
      description = "Das Komplexe einfach machen";
      favicon = "images/snext-logo.png";
    }
    document.title = title;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    }
    const faviconLink = document.querySelector('link[rel="icon"]');
    if (faviconLink) {
      faviconLink.setAttribute("href", favicon);
    }
  }

  document.addEventListener("DOMContentLoaded", function() {
    updateSiteDetails(); // Call the function to update site details based on the current domain
  });

  export default updateSiteDetails;
