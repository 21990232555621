import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import useStyles from "./styles";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
  date: string;
  setDate: (date: string) => void;
}

const DatePicker = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setDate, date } = props;

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const dayRef: any = useRef<HTMLInputElement>(null);
  const monthRef: any = useRef<HTMLInputElement>(null);
  const yearRef: any = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (moment(date, t("date_format"), true).isValid()) {
      const mDate = moment(date, t("date_format"));
      const d = mDate.format(t("date_format_DD"));
      const m = mDate.format(t("date_format_MM"));
      const y = mDate.format(t("date_format_YYYY"));
      setDay(d);
      setMonth(m);
      setYear(y);
    }
  }, [date]);

  useEffect(() => {
    if (
      moment(day + "/" + month + "/" + year, t("date_format"), true).isValid()
    ) {
      setDate(day + "/" + month + "/" + year);
    }
  }, [day, month, year]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    maxLength: number,
    nextRef: React.RefObject<HTMLInputElement> | null
  ) => {
    const input = e.target.value;

    if (input.length <= maxLength) {
      setValue(input);
      setDate(input);

      if (input.length === maxLength && nextRef != null) {
        nextRef!.current!.focus();
      }
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    prevRef: React.RefObject<HTMLInputElement> | null
  ) => {
    if (e.key === "Backspace" && e.currentTarget.value === "") {
      if (prevRef!.current) {
        prevRef!.current.focus();
      }
      setValue("");
    }
  };

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e, setDay, 2, monthRef);
  };

  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e, setMonth, 2, yearRef);
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e, setYear, 4, null);
  };

  return (
    <div className={classes.dateContainer}>
      <input
        ref={dayRef}
        type="number"
        value={day}
        className={classes.dp}
        onChange={handleDayChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleBackspace(e, setDay, null)
        }
        placeholder={t("DD")}
      />
      <div className={classes.divider}>{" / "}</div>

      <input
        type="number"
        className={classes.dp}
        value={month}
        ref={monthRef}
        onChange={handleMonthChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleBackspace(e, setMonth, dayRef)
        }
        placeholder={t("MM")}
      />

      <div className={classes.divider}>{" / "}</div>

      <input
        type="number"
        className={classes.dp}
        style={{ width: 30 }}
        ref={yearRef}
        value={year}
        onChange={handleYearChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleBackspace(e, setYear, monthRef)
        }
        placeholder={t("YYYY")}
      />
    </div>
  );
};

export default DatePicker;
