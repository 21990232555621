import useCurrentSiteStore, { SitePage } from "../hooks/use-sites";
import { useEffect, useMemo, useState } from "react";
import CarbonValues from "./values";
import moment from "moment";
import { DateRange, MQYDateRange } from "../../../utils/types";
import MonthQuarterYearDateSelector from "../../../core/period-selector/month-quarter-year";
import { TZ_OFFSET } from "../../../utils/constant";
import client from "../../../utils/client";
import { useParams } from "react-router-dom";
import Colours from "../../../utils/colours";
import { enqueueSnackbar } from "notistack";
import LineChart from "../../../core/chart/line";
import Loader from "../../../core/loader";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import useStyles from "./styles";
import Box from "../../../core/box";

function mergeTimestamps(
  arrayOfNumbers: number[],
  timestamps: string[]
): { x: number; y: number }[] {
  if (!timestamps) {
    return [];
  }

  return timestamps.map((tz: string, idx: number) => ({
    x: parseInt(tz),
    y: arrayOfNumbers[idx],
  }));
}

const SiteCarbon = () => {
  const { setPage } = useCurrentSiteStore();
  const [dateType, setType] = useState(MQYDateRange.Month);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().subtract(30, "days").toISOString(),
    endDateTime: moment().toISOString(),
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const { siteId } = useParams();
  const [data, setData] = useState<any>({});
  const [totalSavings, setTotalSavings] = useState(0);
  const classes = useStyles();

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    return getChartOptions({
      scales: {
        x: {
          type: "time",
          time: {
            minUnit,
            tooltipFormat: "DD/MM HH:mm",
          },
        },
        y: {
          ticks: {
            callback: (value: any) => {
              return value + " " + t("suffix_kg");
            },
          },
        },
        y1: {
          display: false,
        },
      },

      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;
              return label + ": " + formattedValue + " " + t("suffix_kg");
            },
          },
        },
      },
    });
  }, [dateRange]);

  const loadData = async () => {
    setLoading(true);
    const req = {
      startDate: moment(dateRange.startDateTime)
        .startOf("day")
        .format(t("date_format2")),
      endDate: moment(dateRange.endDateTime).format(t("date_format2")),
      siteId: siteId,
      tzOffset: TZ_OFFSET,
    };

    try {
      const res = await client.post("/kdbGateway/site/getCarbonReporting", req);
      let body = res.data.response;

      const chartData = {
        datasets: [
          {
            label: t("Carbon_Saved"),
            data: mergeTimestamps(body.savingData, body.labels),
            borderColor: Colours.blue5,
            backgroundColor: Colours.light_blue5,
            borderWidth: 1,
          },
        ],
      };

      setData(chartData);
      setTotalSavings(body.totalSavings);
      setLoading(false);
      setInitialLoading(false);
    } catch {
      enqueueSnackbar(t("Error_loading_carbon_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    setPage(SitePage.Carbon);
    void loadData();
  }, [dateRange]);

  if (initialLoading) return <Loader />;

  return (
    <div>
      <MonthQuarterYearDateSelector
        dateType={dateType}
        setType={setType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Carbon_Saved")}
      />
      {!isNativeMobile() && (
        <>
          <br />
          <CarbonValues savings={totalSavings || 0} />
        </>
      )}

      <Box>
        <div className={classes.chart}>
          <LineChart
            loading={loading}
            data={data}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName="carbon-reporting"
            options={options}
          />
        </div>
      </Box>
      {isNativeMobile() && (
        <>
          <br />
          <CarbonValues savings={totalSavings || 0} />
        </>
      )}
    </div>
  );
};

export default SiteCarbon;
