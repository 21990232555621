import { useTranslation } from "react-i18next";
import "./styles.css";
import useStyles from "./styles";

interface Props {
  tariff: any;
}

const TariffDetails = (props: Props) => {
  const {
    supplierName,
    tariffName,
    tariffClass,
    tariffDesc,
    tariffRef,
    marketAreaName,
    daylightSavings,
  } = props.tariff;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Class")}</div>
        <div className={classes.value} data-testid="Class">
          {tariffClass}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Supplier")}</div>
        <div className={classes.value} data-testid="Supplier">
          {supplierName}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Tariff_Name")}</div>
        <div className={classes.value} data-testid="TariffName">
          {tariffName}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Description")}</div>
        <div className={classes.value} data-testid="Description">
          {tariffDesc}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Reference")}</div>
        <div className={classes.value} data-testid="Reference">
          {tariffRef}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Market_Area")}</div>
        <div className={classes.value} data-testid="MarketArea">
          {marketAreaName}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Daylight_Savings")}</div>
        <div className={classes.value} data-testid="DaylightSavings">
          {daylightSavings ? t("Yes") : t("No")}
        </div>
      </div>
    </>
  );
};

export default TariffDetails;
