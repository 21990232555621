import {
  IonButton,
  IonFooter,
  IonGrid,
  IonInput,
  IonLabel,
  IonNote,
  IonRow,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "../../../hooks/use-navigate";
import { useTranslation } from "react-i18next";
import { Keyboard } from "@capacitor/keyboard";
import { isNativeMobile } from "../../../utils/ionic";
import useStyles from "./styles";
import AuthContainer from "../container";
import Input from "../../../core/input";
import Button from "../../../core/button";
import { Domain, getDomain } from "../../../utils/get-domain";
import client from "../../../utils/client";

const Login = () => {
  const classes = useStyles();
  const { goTo } = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showFooter, setShowFooter] = useState(true);

  function handleKeyDown(event: { key: string }) {
    if (event.key === "Enter") {
      void sendEmailLink();
    }
  }

  const getActionUrl = () => {
    return getDomain() == Domain.TESC
      ? process.env.REACT_APP_PARIS_INSIGHTS_WEBAPP_URL
      : process.env.REACT_APP_AI_INSIGHTS_WEBAPP_URL;
  };

  const getSystemOrigin = () => {
    return getDomain() == Domain.TESC ? "parisinsights" : "aileen";
  };

  const sendEmailLink = async () => {
    setMessage("");
    const url = `${getActionUrl()}/email-login`;
    const emailValue = email.replace(/\s+/g, "");

    try {
      client.defaults.headers.common = {
        Authorization: null,
      };

      const systemOrigin = getSystemOrigin();
      const req = { url, email: emailValue, systemOrigin };
      localStorage.setItem("emailForTESCSignIn", emailValue);
      await client.post("/signInWithEmailLink", req);
      setMessage(t("Check_Your_Inbox"));
    } catch (error: any) {
      setMessage(t("Problem_signing_in"));
    }

    setEmail(emailValue);
  };

  if (isNativeMobile()) {
    Keyboard.addListener("keyboardWillShow", () => {
      setShowFooter(false);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setShowFooter(true);
    });

    return (
      <>
        <div className="login">
          <IonGrid>
            <IonRow>
              <div className={classes.title}>{t("Welcome_Back")}</div>
            </IonRow>
            <IonRow>
              <IonNote>{t("Email")}</IonNote>

              <Input
                value={email}
                onChange={setEmail}
                onKeyDown={handleKeyDown}
                autoComplete={"on"}
                style={{ width: "100%" }}
                id="loginPassword"
                type="email"
                autoCapitalize="none"
              />
            </IonRow>

            <Button
              variant="contained"
              onClick={() => sendEmailLink()}
              style={{ width: "100%" }}
            >
              {t("Sign_In_Email_Login_Link")}
            </Button>

            <IonNote>{message}</IonNote>
          </IonGrid>
        </div>
        {showFooter && (
          <IonFooter className="ion-no-border">
            <IonSegmentButton
              value="default"
              onClick={() => goTo("/password-login")}
              className="password-auth"
            >
              <IonLabel color="medium">{t("Sign_In_Password")}</IonLabel>
            </IonSegmentButton>
          </IonFooter>
        )}
      </>
    );
  } else {
    return (
      <AuthContainer>
        <form>
          <div className={classes.subtitle}>{t("Login_to_account")}</div>
          <h4>{t("Email")}</h4>
          <Input
            onChange={setEmail}
            onKeyDown={handleKeyDown}
            value={email}
            autoComplete={"on"}
            style={{ width: "100%" }}
            id="loginEmail"
            type="email"
            autoCapitalize="none"
          />
          <div />
          <div className={classes.error}>{message}</div>
          <div className={classes.break} />
          <Button
            name={t("Sign_In_Email_Login_Link")}
            onClick={() => sendEmailLink()}
            variant={"contained"}
            id={"loginBtn"}
          />
        </form>
        {showFooter && (
          <div>
            <button
              className={classes.signInPassword}
              onClick={() => goTo("/password-login")}
            >
              <div>{t("Sign_In_Password")}</div>
            </button>
          </div>
        )}
      </AuthContainer>
    );
  }
};

export default Login;
