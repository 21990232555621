import { createUseStyles } from "react-jss";
import Colours from "../../../../../../utils/colours";

const useStyles = createUseStyles({
  noCharges: {
    color: "grey",
    fontWeight: 500,
    paddingTop: 20,
    paddingBottom: 20,
    fontStyle: "italic",
  },
});

export default useStyles;
