import { createUseStyles } from "react-jss";
import Colours from "../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  noRates: {
    color: theme.grey.grey3,
    fontWeight: 500,
    fontStyle: "italic",
    fontSize: 15,
  },
}));

export default useStyles;
