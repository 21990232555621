import moment from "moment";
import { useTranslation } from "react-i18next";
import useNewTariffStore from "../new-tariff-store";
import useStyles from "./styles";
import { Stack } from "@mui/material";
import Button from "../../../../../../core/button";
import CustomTariffRatePeriod from "./period";
import CustomTariffAdditionalCharge from "./charge";
import CustomRateActions from "./actions";
import _ from "lodash";

const CustomRate = () => {
  const classes = useStyles();
  const { customRateIndex, tariffRates } = useNewTariffStore();
  const currentRate = tariffRates[customRateIndex];
  const { additionalCharges, ratePeriods, startDate, endDate } = currentRate;
  const { t } = useTranslation();

  const onAddRate = () => {
    const newRates = [...tariffRates];
    newRates[customRateIndex].ratePeriods.push({
      periodName: "",
      unitPrice: 0,
      direction: null,
      startTime: null,
      endTime: null,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });

    useNewTariffStore.setState({ tariffRates: newRates });
  };

  const onAddCharge = () => {
    const newRates = [...tariffRates];
    newRates[customRateIndex].additionalCharges!.push({
      chargeName: "",
      startTime: "",
      endTime: "",
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      amount: 0,
    });

    useNewTariffStore.setState({ tariffRates: newRates });
  };

  const onCopy = () => {
    const newRates = [...tariffRates];
    const periods = newRates[customRateIndex - 1].ratePeriods;
    const charges = newRates[customRateIndex - 1].additionalCharges;
    newRates[customRateIndex].ratePeriods = _.cloneDeep(periods);
    newRates[customRateIndex].additionalCharges = _.cloneDeep(charges);
    useNewTariffStore.setState({ tariffRates: newRates });
  };

  return (
    <div>
      <div className={classes.periodDates}>
        {moment(startDate, "DD/MM/YYYY").format("Do MMM YYYY")} -{" "}
        {moment(endDate, "DD/MM/YYYY").format("Do MMM YYYY")}
      </div>
      <h3>Rates</h3>
      {ratePeriods.length == 0 ? (
        <div>Added rates will display here</div>
      ) : (
        ratePeriods.map((pr, i) => {
          return <CustomTariffRatePeriod key={i} ratePeriodIndex={i} />;
        })
      )}
      <Stack direction={"row"} justifyContent={"end"}>
        <Button onClick={onAddRate}>Add</Button>
      </Stack>

      <h3>{t("Additional_Charges")}</h3>
      {additionalCharges!.length == 0 ? (
        <div>Added charges will go here</div>
      ) : (
        additionalCharges!.map((c, i) => {
          return <CustomTariffAdditionalCharge chargeIndex={i} />;
        })
      )}
      <Stack direction={"row"} justifyContent={"end"}>
        <Button onClick={onAddCharge}>Add</Button>
      </Stack>

      <Button
        variant={"outlined"}
        onClick={onCopy}
        disabled={customRateIndex == 0}
      >
        Copy Last Period
      </Button>

      <CustomRateActions />
    </div>
  );
};

export default CustomRate;
