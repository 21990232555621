import { createUseStyles } from "react-jss";
import Colours from "../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  dateContainer: {
    display: "flex",
  },

  active: {
    color: theme.primary.primary5,
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 3,
    paddingRight: 3,
  },

  inactive: {
    color: theme.grey.grey2,
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 3,
    paddingRight: 3,
  },
}));

export default useStyles;
