import { Stack } from "@mui/material";
import Input from "../../../../../../../core/input";
import useNewTariffStore from "../../new-tariff-store";
import DaysActive from "../../../../../../../core/days-active";
import Select from "../../../../../../../core/select";
import TimePicker from "../../../../../../../core/time-picker";
import Button from "../../../../../../../core/button";

interface Props {
  ratePeriodIndex: number;
}

const CustomTariffRatePeriod = (props: Props) => {
  const { ratePeriodIndex } = props;
  const { tariffRates, currency, customRateIndex } = useNewTariffStore();

  const period = tariffRates[customRateIndex].ratePeriods[ratePeriodIndex];
  const { unitPrice, periodName } = period;

  const onChangePeriod = (value: any, field: string) => {
    const newTariffRates = [...tariffRates] as any;
    newTariffRates[customRateIndex].ratePeriods[ratePeriodIndex][field] = value;
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  const onDelete = () => {
    const newTariffRates = [...tariffRates];
    newTariffRates[customRateIndex].ratePeriods.splice(ratePeriodIndex, 1);
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  return (
    <Stack direction="row" gap={2} marginTop={2}>
      <Input
        label="Name"
        value={periodName}
        onChange={(name) => onChangePeriod(name, "periodName")}
      />

      <Input
        label="Unit amount"
        value={unitPrice}
        onChange={(amount) => onChangePeriod(amount, "unitPrice")}
        prefix={currency}
      />

      <Select
        label="Direction"
        value={period.direction}
        onChange={(direction) => onChangePeriod(direction, "direction")}
        options={[
          { label: "Import", value: "import" },
          { label: "Export", value: "export" },
        ]}
      />

      <TimePicker
        label="Start Time"
        time={period.startTime}
        setTime={(time) => onChangePeriod(time, "startTime")}
      />

      <TimePicker
        label="End Time"
        time={period.endTime}
        setTime={(time) => onChangePeriod(time, "endTime")}
      />

      <DaysActive
        onChange={(value, field) => onChangePeriod(value, field)}
        monday={period.monday}
        tuesday={period.tuesday}
        wednesday={period.wednesday}
        thursday={period.thursday}
        friday={period.friday}
        saturday={period.saturday}
        sunday={period.sunday}
      />
      <Button onClick={onDelete} colour={"error"}>
        X
      </Button>
    </Stack>
  );
};

export default CustomTariffRatePeriod;
