import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { isNativeMobile } from "../../utils/ionic";
import { Box, MenuItem, Select, SvgIcon } from "@mui/material";
import { GlobeIcon } from "../icons";
import { useTheme } from "react-jss";

interface IProps {
  className: string;
}

const LanguageSwitcher = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const theme: any = useTheme();

  React.useEffect(() => {
    const lang = navigator.language.split("-");
    if (lang[0].toLowerCase() === "de") {
      void i18n.changeLanguage("de");
    }
  }, []);

  const handleLanguageChange = (value: any) => {
    const newValue = value[0].value ? value[0].value : value;
    void i18n.changeLanguage(newValue);
    localStorage.setItem("lng", newValue);
  };

  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "de",
      label: "Deutsch",
    },
  ];

  const selectedLanguage =
    languages.find((language) => language.value == i18n.language) ||
    languages[0];

  return (
    <>
      {isNativeMobile() ? (
        <IonSelect
          key="ion-select-switcher"
          data-testid="ion-select-switcher"
          className="ion-hide-sm-up"
          interface="action-sheet"
          onIonChange={(event) => {
            handleLanguageChange(event.detail.value);
          }}
          cancelText={t("Cancel")}
          selectedText={selectedLanguage.label}
          style={{
            fontSize: 16,
            width: "6rem",
          }}
        >
          {languages.map((l) => {
            return (
              <IonSelectOption
                key={`ion-option-${l.label}`}
                data-testid={`ion-option-${l.label}`}
                value={l.value}
              >
                {l.label}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      ) : (
        <div className={className}>
          <Select
            data-testid="select-switcher"
            displayEmpty
            value={selectedLanguage.value}
            size="small"
            onChange={(e) => {
              handleLanguageChange(e.target.value);
            }}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <GlobeIcon />
                  {selectedLanguage.label}
                </Box>
              );
            }}
            sx={{
              color: theme.primary.textColor,
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: theme.primary.textColor3,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.primary.textColor3,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.primary.textColor3,
              },
              ".MuiSvgIcon-root ": {
                fill: theme.primary.textColor,
              },
            }}
          >
            {languages.map((l) => (
              <MenuItem key={l.label} value={l.value}>
                {l.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};

export default LanguageSwitcher;
