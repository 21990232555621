import { ReactNode } from "react";
import MuiButton from "@mui/material/Button";

interface Props {
  name?: string;
  children?: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  variant?: any;
  colour?: any;
  size?: "large" | "medium" | "small";
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  style?: any;
  id?: string;
}

const Button = (props: Props) => {
  const {
    name,
    children,
    disabled,
    onClick,
    size,
    variant,
    colour,
    endIcon,
    startIcon,
    style,
    id,
  } = props;

  return (
    <MuiButton
      onClick={onClick}
      color={colour}
      variant={variant}
      size={size}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={style}
      id={id}
    >
      {name}
      {children}
    </MuiButton>
  );
};

export default Button;
