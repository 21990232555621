import { Divider, Stack } from "@mui/material";
import useNewTariffStore from "../new-tariff-store";
import TariffOverviewPeriod from "./period";
import TariffDetailsOverview from "./details";
import TariffOverviewActions from "./actions";
import OverviewCharges from "./charges";
import useStyles from "./styles";
import moment from "moment";
import { useTranslation } from "react-i18next";

const NewTariffOverview = () => {
  const { t } = useTranslation();
  const { type, tariffRates } = useNewTariffStore();
  const classes = useStyles();

  return (
    <div>
      <TariffDetailsOverview />
      <br />
      <Divider />
      <br />
      <div>
        {tariffRates.map((rate, i) => {
          const { startDate, endDate } = rate;
          return (
            <div>
              <Stack direction={"row"} marginBottom={0.5}>
                <div className={classes.label}>Start Date:</div>
                <div className={classes.date}>
                  {moment(startDate, "DD/MM/YYYY").format("Do MMM YYYY")}
                </div>
              </Stack>
              <Stack direction={"row"}>
                <div className={classes.label}>End Date:</div>
                <div className={classes.date}>
                  {endDate == null || endDate == ""
                    ? "N/A"
                    : moment(endDate, "DD/MM/YYYY").format("Do MMM YYYY")}
                </div>
              </Stack>

              <br />

              <div className={classes.label}>Rates</div>
              <TariffOverviewPeriod key={i} ratePeriods={rate.ratePeriods} />
              <br />
              {rate.additionalCharges!.length > 0 && (
                <>
                  <div className={classes.label}>Charges</div>
                  <OverviewCharges
                    key={i}
                    additionalCharges={rate.additionalCharges!}
                  />
                </>
              )}
              <br />
              <Divider />
              <br />
            </div>
          );
        })}
      </div>
      <TariffOverviewActions />
    </div>
  );
};

export default NewTariffOverview;
