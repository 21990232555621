import { useTranslation } from "react-i18next";
import { Domain, getDomain } from "../../../utils/get-domain";
import useSitesStore from "../../../hooks/use-sites";
import {
  LineIcon,
  LogoutIcon,
  MeterIcon,
  MoneyIcon,
  OverviewIcon,
  TreeIcon,
} from "../../icons";
import NavItem from "./nav-item";
import useStyles from "./styles";
import i18next from "i18next";
import DesktopLogo from "./logo";

const DesktopNavBar = () => {
  const classes = useStyles();
  const siteId = useSitesStore((store) => store.siteId);
  const { t } = useTranslation();
  const domain = getDomain();
  const showTariff = domain != Domain.SNXT;

  return (
    <div
      className={`${classes.nav} ${
        i18next.language === "en" ? classes.nav_en : classes.nav_de
      }`}
    >
      <DesktopLogo />
      <NavItem
        name={t("Assets")}
        icon={<OverviewIcon width={20} height={20} />}
        route={`/sites/${siteId}/assets`}
      />
      <NavItem
        name={t("Performance")}
        icon={<LineIcon width={20} height={20} />}
        route={`/sites/${siteId}/performance`}
      />
      <NavItem
        name={t("Finance")}
        icon={<MoneyIcon width={20} height={20} />}
        route={`/sites/${siteId}/finance`}
      />
      <NavItem
        name={t("Carbon")}
        icon={<TreeIcon width={20} height={20} />}
        route={`/sites/${siteId}/carbon`}
      />
      {showTariff && (
        <NavItem
          name={t("Tariff")}
          icon={<MeterIcon width={20} height={20} />}
          route={`/sites/${siteId}/tariff`}
        />
      )}

      <NavItem
        name={t("Logout")}
        route={"/logout"}
        icon={<LogoutIcon width={20} height={20} />}
        isLogout={true}
      />
    </div>
  );
};

export default DesktopNavBar;
