import { isNativeMobile } from "../../../../../utils/ionic";
import useStyles from "./styles";

interface Props {
  amountOfRates: number;
}

const TariffTimes = (props: Props) => {
  const { amountOfRates } = props;
  const classes = useStyles({ amountOfRates: amountOfRates + 3 });

  return (
    <>
      {!isNativeMobile() ? (
        <>
          <div className={classes.t0 + " " + classes.time}>24.00</div>
          <div className={classes.t3 + " " + classes.time}>03.00</div>
          <div className={classes.t6 + " " + classes.time}>06.00</div>
          <div className={classes.t9 + " " + classes.time}>09.00</div>
          <div className={classes.t12 + " " + classes.time}>12.00</div>
          <div className={classes.t15 + " " + classes.time}>15.00</div>
          <div className={classes.t18 + " " + classes.time}>18.00</div>
          <div className={classes.t21 + " " + classes.time}>21.00</div>
          <div className={classes.t23} data-testid="endTimeColumn">
            24.00
          </div>
          <div className={classes.t24 + " " + classes.time}></div>
        </>
      ) : (
        <>
          <div className={classes.t0 + " " + classes.time}>24.00</div>
          <div className={classes.t6 + " " + classes.time}>06.00</div>
          <div className={classes.t12 + " " + classes.time}>12.00</div>
          <div className={classes.t18 + " " + classes.time}>18.00</div>
          <div className={classes.t23} data-testid="endTimeColumn">
            24.00
          </div>
          <div className={classes.t24 + " " + classes.time}></div>
        </>
      )}
    </>
  );
};

export default TariffTimes;
