import { ReactNode } from "react";
import { ThemeProvider as JssThemeProvider } from "react-jss";
import { Domain, getDomain } from "../../utils/get-domain";
import { tescTheme, tescMuiTheme } from "./tesc-theme";
import { snxtTheme, snxtMuiTheme } from "./snext-theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";

interface Props {
  children: ReactNode;
}

const LocalThemeProvider = (props: Props) => {
  const domain = getDomain();
  const jssTheme = domain == Domain.TESC ? tescTheme : snxtTheme;
  const muiTheme = domain == Domain.TESC ? tescMuiTheme : snxtMuiTheme;

  return (
    <MuiThemeProvider theme={muiTheme}>
      <JssThemeProvider theme={jssTheme}>{props.children}</JssThemeProvider>
    </MuiThemeProvider>
  );
};
export default LocalThemeProvider;
