import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  id: {
    color: theme.primary.primary5,
    all: "unset",
    cursor: "pointer",
  },
}));

export default useStyles;
