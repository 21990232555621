import { useTranslation } from "react-i18next";
import Input from "../../../../../../core/input";
import NewTariffActions from "../actions";
import useNewTariffStore from "../new-tariff-store";
import useStyles from "./styles";

const FlatRate = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPage, tariffRates, currency } = useNewTariffStore();

  const { periodName, unitPrice } = tariffRates[0].ratePeriods[0];

  const canNext = periodName != "" && unitPrice != 0;

  const onChange = (
    value: string | number,
    field: "unitPrice" | "periodName"
  ) => {
    const newTariffRates = [...tariffRates] as any;
    newTariffRates[0].ratePeriods[0][field] = value;
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  return (
    <div>
      <div>
        <br />
        <div className={classes.label}>{t("What_is_name_flat_rate")}</div>
        <Input
          label={t("Name")}
          value={periodName}
          onChange={(name) => onChange(name, "periodName")}
        />
      </div>

      <br />

      <div>
        <div className={classes.label}>{t("How_much_do_you_pay")}</div>
        <Input
          label={t("Unit_Cost")}
          value={unitPrice}
          onChange={(name) => onChange(name, "unitPrice")}
          prefix={currency}
        />
      </div>

      <br />

      <NewTariffActions
        canBack={true}
        canNext={canNext}
        onBack={() => setPage("dates")}
        onNext={() => setPage("charges")}
      />
    </div>
  );
};

export default FlatRate;
