import { IonIcon, IonPopover } from "@ionic/react";
import useStyles from "./styles";

interface Props {
  tooltipText: string;
  icon: string;
  id: string;
}

const ToolTip = ({ tooltipText, icon, id }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <IonIcon
        id={id}
        icon={icon}
        data-testid="tooltip-icon"
        className={classes.icon}
      />
      <IonPopover trigger={id} alignment="center" data-testid="tooltip-popover">
        <div className={classes.popover}>{tooltipText}</div>
      </IonPopover>
    </div>
  );
};

export default ToolTip;
