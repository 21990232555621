import { useState } from "react";
import Button from "../../../core/button";
import { BackIcon } from "../../../core/icons";
import Input from "../../../core/input";
import { useNavigate } from "../../../hooks/use-navigate";
import AuthContainer from "../container";
import useStyles from "./styles";
import InfoTooltip from "../../../core/tool-tip";
import { getAuth, updatePassword } from "firebase/auth";
import useAuthStore from "../../../hooks/use-auth";
import { enqueueSnackbar } from "notistack";
import client from "../../../utils/client";
import { TZ_OFFSET } from "../../../utils/constant";
import { useTranslation } from "react-i18next";

const AuthUpdatePassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goBack, goTo } = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [retypePressed, setRetypePressed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const { logout, login } = useAuthStore();

  const onSubmit = async () => {
    setSubmitted(true);
    setError("");

    const invalidPassword =
      newPassword.length < 8 || newPassword != retypePassword;
    if (invalidPassword) {
      return;
    }

    const auth = getAuth();

    const fbUser = auth.currentUser;
    try {
      await updatePassword(fbUser!, newPassword);
      const response = await client.post(
        "/appUsers/getUserIdByEmail",
        fbUser!.email,
        {
          headers: { "Content-Type": "text/plain" },
        }
      );
      const USER_ID = response.data.response;
      await client.put("/users/updateLastResetDate/" + USER_ID);
      const res = await client.post(
        "/appUsers/getByEmail/" + TZ_OFFSET,
        fbUser!.email,
        {
          headers: { "Content-Type": "text/plain" },
        }
      );
      const user = res.data.response;
      login(user);
      goTo("/");
    } catch (error: any) {
      if (error.code == "auth/requires-recent-login") {
        logout();
        setTimeout(() => {
          enqueueSnackbar(t("Please_reauth"), {
            variant: "error",
            persist: false,
          });
          goTo("/");
        }, 500);
      }
    }
  };

  return (
    <AuthContainer>
      <div className={classes.subtitle}>{t("Pass_expired")}</div>

      <div>
        <h4>{t("New_Password")}:</h4>
        <div className={classes.newPassword}>
          <Input
            value={newPassword}
            onChange={setNewPassword}
            type="password"
          />
          <div className={classes.tooltip}>
            <InfoTooltip id={"new-pass"} children={t("Pass_requirements")} />
          </div>
        </div>
      </div>

      <br />

      <div>
        <h4>{t("Retype_new_Password")}:</h4>
        <div>
          <Input
            value={retypePassword}
            onChange={(value: string) => {
              setRetypePressed(true);
              setRetypePassword(value);
            }}
            type="password"
            error={(retypePressed || submitted) && retypePassword != newPassword}
            errorText={t("Pass_match")}
            onClick={() => setRetypePressed(true)}
          />
        </div>
      </div>

      <div className={classes.bottomContainer}>
        <button className={classes.backToLogin} onClick={() => goBack("/")}>
          <BackIcon width={20} height={20} />
          <div className={classes.backText}>{t("Back_to_login_screen")}</div>
        </button>
        <div>
          <Button name={t("Submit")} onClick={onSubmit} />
        </div>
      </div>
    </AuthContainer>
  );
};

export default AuthUpdatePassword;
