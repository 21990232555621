import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  divider: {
    marginTop: 18,
    marginBottom: 30,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#F2F2F2",
  },
}));

export default useStyles;
