import { createUseStyles } from "react-jss";
import Colours from "../../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  label: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 5,
    color: theme.primary.textColor3,
  },
}));

export default useStyles;
