import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LineChart from "../../../core/chart/line";
import DayWeekMonthDateSelector from "../../../core/period-selector/day-week-month";
import useAuthStore from "../../../hooks/use-auth";
import client from "../../../utils/client";
import { TZ_OFFSET } from "../../../utils/constant";
import { DateRange, MWDDateRange } from "../../../utils/types";
import useStyles from "./styles";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import useSitesStore from "../../../hooks/use-sites";
import { isNativeMobile } from "../../../utils/ionic";
import MeteringPointDetails from "./metering-point-details";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import Box from "../../../core/box";

const MeteringPointPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { assetId } = useParams();
  const [dateType, setDateType] = useState(MWDDateRange.Day);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().startOf("day").toISOString(),
    endDateTime: moment().endOf("day").toISOString(),
  });
  const { currentUser } = useAuthStore();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mpResponse, setMpResponse] = useState<any>({});
  const [siteMeterDetails, setSiteMeterDetails] = useState<any>({});
  const [currentExportRate, setCurrentExport] = useState(0);
  const sites = useSitesStore((store) => store.sites);

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    return getChartOptions({
      scales: {
        x: {
          type: "time",
          time: {
            minUnit,
            tooltipFormat: "DD/MM HH:mm",
          },
        },
        y: {
          ticks: {
            callback: (value: any) => {
              return value + " " + t("suffix_kWh");
            },
            precision: 0,
          },
        },
      },

      elements: {
        line: {
          tension: 0,
        },
      },

      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;
              return `${label}: ${formattedValue} ${t("suffix_kWh")}`;
            },
          },
        },
      },
    });
  }, [dateRange]);

  const loadData = async (dr: typeof dateRange) => {
    try {
      setLoading(true);
      const res = await client.post(
        "/kdbGateway/assetMetrics/getGridConditions",
        {
          sym: assetId,
          userId: currentUser.userId,
          startDate: moment(dr.startDateTime).format(
            t("date_time_format_seconds")
          ),
          endDate: moment(dr.endDateTime).format(t("date_time_format_seconds")),
          tzOffset: TZ_OFFSET,
        }
      );

      const exportRes = await client.get(
        "/tariffPassThroughPrices/getCurrentExportRateByMeteringPointId/" +
          assetId
      );

      const metRes = res.data.response;

      const exportedData = metRes.data.map((x: any) => ({
        x: new Date(x["time"]),
        y: x["energyExportedWh"] / 1000,
      }));

      const importedData = metRes.data.map((x: any) => ({
        x: new Date(x["time"]),
        y: x["energyImportedWh"] / 1000,
      }));

      const d = {
        datasets: [
          {
            label: t("Energy_Exported"),
            data: exportedData,
            borderColor: "rgba(0,184,148,1.0)",
            backgroundColor: "rgba(0,184,148,0.2)",
            borderWidth: 1,
          },

          {
            label: t("Energy_Imported"),
            data: importedData,
            borderColor: "rgba(231,76,60,1.0)",
            backgroundColor: "rgba(231,76,60,0.2)",
            borderWidth: 1,
          },
        ],
      };

      const assetSumRes = await client.post(
        "/kdbGateway/assetMetrics/assetsSummaryMetrics",
        { siteId: metRes.siteId, tzOffset: TZ_OFFSET }
      );

      const { siteMeterSummaryMetrics } = assetSumRes.data.response;

      setSiteMeterDetails(siteMeterSummaryMetrics[0]);
      setCurrentExport(exportRes.data.response);
      setMpResponse(metRes);
      setData(d);
      setLoading(false);
      setInitialLoading(false);
    } catch {
      enqueueSnackbar(t("Error_load_mp_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    void loadData(dateRange);
  }, [dateRange]);

  const site = sites.filter((site) => {
    return site.siteId === mpResponse.siteId;
  });

  let currency = "";
  if (site.length === 1) {
    currency = site[0].parentMarketCurrency;
  }

  if (initialLoading) {
    return <Loader />;
  }

  return (
    <div>
      <MeteringPointDetails
        currentExport={currentExportRate}
        currency={currency}
        importLast7={siteMeterDetails.weekTotalImportedKwh}
        exportLast7={siteMeterDetails.weekTotalExportedKwh}
      />

      <DayWeekMonthDateSelector
        dateType={dateType}
        setType={setDateType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Energy_In_And_Out")}
      />

      <Box>
        <div className={classes.chart}>
          <LineChart
            loading={loading}
            data={data}
            options={options}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName={"metering-point"}
          />
        </div>
      </Box>
    </div>
  );
};

export default MeteringPointPage;
