import moment from "moment";
import mobileChartOptions from "../core/chart/line/mobileOptions";
import chartOptions from "../core/chart/line/options";
import { isNativeMobile } from "./ionic";
import _ from "lodash";

export function getMinUnitsForDateRange(start: string, end: string) {
  const startDate = moment(start, moment.ISO_8601, true);
  const endDate = moment(end, moment.ISO_8601, true);
  let minUnits = "hour";

  if (!startDate.isValid() || !endDate.isValid()) {
    return minUnits;
  }

  const duration = moment.duration(endDate.diff(startDate));

  if (duration.days() > 0) {
    minUnits = "day";
  }

  return minUnits;
}

export function getChartOptions(chartOptionOverrides: object) {
  let options = isNativeMobile() ? mobileChartOptions : chartOptions;
  return _.merge({}, options, chartOptionOverrides);
}
