import { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import { Paper } from "@mui/material";
import { useTheme } from "react-jss";

interface Props {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}

const Modal = (props: Props) => {
  const { open, onClose, children } = props;
  const theme: any = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Paper
        style={{
          backgroundColor: theme.primary.boxBackground,
          overflow: "hidden",
          color: theme.primary.textColor,
        }}
      >
        <div>{children}</div>
      </Paper>
    </Dialog>
  );
};

export default Modal;
