import { useTranslation } from "react-i18next";
import useNewTariffStore from "../../new-tariff-store";
import useStyles from "./styles";

const TariffDetailsOverview = () => {
  const {
    tariffName,
    tariffDesc,
    suppliers,
    tariffSupplierId,
    tariffRef,
    tariffUseDaylightSavings,
    marketAreas,
    tariffMarketAreaId,
    type,
  } = useNewTariffStore();
  const classes = useStyles();
  const { t } = useTranslation();

  const supplier = suppliers.find((s) => s.supplierId == tariffSupplierId);
  const ma = marketAreas.find((m) => m.marketAreaId == tariffMarketAreaId);
  let typeName = "";

  switch (type) {
    case "custom":
      typeName = t("Custom");
      break;
    case "eco7":
      typeName = t("Economy_7");
      break;
    case "flatRate":
      typeName = t("Flat_Rate");
      break;
    case "variableRate":
      typeName = t("Variable_Rate");
      break;
  }

  return (
    <div className={classes.detailsGrid}>
      <div>
        <div className={classes.label}>{t("Type")}</div>
        <div className={classes.value}>{typeName}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Name")}</div>
        <div className={classes.value}>{tariffName}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Description")}</div>
        <div className={classes.value}>{tariffDesc}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Supplier")}</div>
        <div className={classes.value}>{supplier?.supplierName}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Ref")}</div>
        <div className={classes.value}>{tariffRef}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Daylight_Savings")}</div>
        <div className={classes.value}>{tariffUseDaylightSavings}</div>
      </div>

      <div>
        <div className={classes.label}>{t("Market_Area")}</div>
        <div className={classes.value}>{ma?.marketAreaName}</div>
      </div>
    </div>
  );
};

export default TariffDetailsOverview;
