import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  body: {
    backgroundColor: theme.primary.boxBackground,
  },

  headCell: {
    backgroundColor: theme.primary.boxBackground,
    color: "#d6d6d6",
    fontWeight: 500,
    borderBottom: "2px solid " + theme.grey.grey1,
    textTransform: "uppercase",
  },

  dataRow: {
    backgroundColor: theme.primary.boxBackground,
    borderBottom: "none",
    borderTop: "none",

    "&:hover": {
      backgroundColor: theme.primary.backgroundColor,
      cursor: "pointer",
    },
  },

  pagination: {
    backgroundColor: theme.primary.boxBackground,
    borderBottom: "none",
    borderTop: "none",
  },

  cellText: {
    color: theme.primary.textColor2,
    fontWeight: 600,
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

export default useStyles;
