import { useTranslation } from "react-i18next";
import { IonProgressBar } from "@ionic/react";
import { isNativeMobile } from "../../../../utils/ionic";
import BackButton from "../../../../core/back";
import useStyles from "./styles";
import Divider from "../../../../core/divider";

interface Props {
  charge: number;
  type: string;
  serial: string;
  capacity: string;
}

const BatteryDetails = ({ charge, type, serial, capacity }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      {!isNativeMobile() && (
        <>
          <BackButton defaultRoute={"/"} />
          <Divider />

          <h2>{t("Battery_Asset")}</h2>
        </>
      )}
      <div
        className={classes.detailsRow}
        data-testid="battery-current-charge-item"
      >
        <div className={classes.label}>{t("Current_Charge")}</div>
        <div className={classes.value}>{charge}%</div>
      </div>
      <IonProgressBar
        data-testid="battery-current-charge-indicator"
        className={classes.batteryLevelIndicator}
        reversed={true}
        color="light"
        value={(100 - charge) / 100}
      />
      <div className={classes.detailsRow} data-testid="battery-type-item">
        <div className={classes.label}>{t("Type")}</div>
        <div className={classes.value}>{type}</div>
      </div>
      <div className={classes.detailsRow} data-testid="battery-serial-item">
        <div className={classes.label}>{t("Serial")}</div>
        <div className={classes.value}>#{serial}</div>
      </div>
      <div className={classes.detailsRow} data-testid="battery-capacity-item">
        <div className={classes.label}>{t("Capacity")}</div>
        <div className={classes.value}>
          {capacity} {t("suffix_kWh")}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default BatteryDetails;
