import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  button: {
    all: "unset",
    border: "1px solid " + theme.primary.primary5,
    color: theme.primary.primary5,
    borderRadius: 15,
    padding: 3,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 10,
    cursor: "pointer",

    "@media(max-width: 1000px)": {
      padding: 2,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 11,
      fontWeight: 600,
      marginLeft: 5,
    },
  },
}));

export default useStyles;
