import { createUseStyles } from "react-jss";
import Colours from "../../../../../utils/colours";

interface Props {
  amountOfRates: number;
}

const useStyles = createUseStyles((theme: any) => ({
  time: {
    gridRow: (props: Props) => "1 / " + props.amountOfRates,
    borderLeft: "2px solid " + theme.primary.textColor3,
    paddingLeft: 5,
    color: theme.grey.grey6,
  },

  t0: {
    gridColumn: "2/6",
  },

  t3: {
    gridColumn: "8/12",
  },

  t6: {
    gridColumn: "14/17",
  },

  t9: {
    gridColumn: "20/24",
  },

  t12: {
    gridColumn: "26/28",
  },

  t15: {
    gridColumn: "32/36",
  },

  t18: {
    gridColumn: "38/40",
  },

  t21: {
    gridColumn: "44/46",
  },

  t23: {
    gridColumn: 49,
    paddingRight: 5,
    color: theme.grey.grey6,
  },

  t24: {
    gridColumn: 50,
  },
}));

export default useStyles;
