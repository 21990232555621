import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../../utils/ionic";
import BackButton from "../../../../core/back";
import useStyles from "./styles";
import Divider from "../../../../core/divider";

interface Props {
  generationType?: string;
  currentGeneration: number;
  weeklyGeneration: number;
  capacity: number;
}

const ProductionDetails = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { generationType, currentGeneration, weeklyGeneration, capacity } =
    props;
  const isWind = generationType == "WI";

  return (
    <div>
      {!isNativeMobile() && (
        <>
          <BackButton defaultRoute={"/"} />
          <Divider />
          <h2>{isWind ? "Wind Turbine" : t("Solar_Panel")}</h2>
        </>
      )}
      <div
        className={classes.detailsRow}
        data-testid="solar-panel-details-current-generation"
      >
        <div className={classes.label}>{t("Current_generation")}</div>
        <div className={classes.value}>
          {currentGeneration} {t("suffix_kWh")}
        </div>
      </div>

      <div
        className={classes.detailsRow}
        data-testid="solar-panel-details-weekly-generation"
      >
        <div className={classes.label}>{t("Generation_Last_7_Days")}</div>
        <div className={classes.value}>
          {weeklyGeneration} {t("suffix_kWh")}
        </div>
      </div>

      <div
        className={classes.detailsRow}
        data-testid="solar-panel-details-capacity"
      >
        <div className={classes.label}>{t("Capacity")}</div>
        <div className={classes.value}>
          {capacity} {t("suffix_kWh")}
        </div>
      </div>

      <Divider />
    </div>
  );
};

export default ProductionDetails;
