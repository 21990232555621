import DeleteIcon from "@mui/icons-material/Delete";
import useStyles from "./styles";
import useNewTariffStore from "../../new-tariff-store";
import { AdditionalChargeProps } from "../../types";
import Button from "../../../../../../../core/button";
import DaysActiveDisplay from "../../../../../../../core/days-active-display";
import { useTranslation } from "react-i18next";

interface Props {
  charge: AdditionalChargeProps;
  chargeIndex: number;
}

const TariffCharge = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { charge, chargeIndex } = props;
  const { baseCharges, currency, pricingUnits, tariffRates } =
    useNewTariffStore();

  const onDelete = () => {
    const newRates = [...tariffRates];
    newRates[0].additionalCharges = tariffRates[0].additionalCharges!.filter(
      (c, i) => chargeIndex != i
    );
    useNewTariffStore.setState({ tariffRates: newRates });
  };

  const chargeName = baseCharges.find(
    (bc) => bc.baseChargeId == charge.fkBaseChargeId
  )?.chargeName;

  const name = chargeName != null ? chargeName : charge.chargeName;

  const pricingUnit = pricingUnits.find(
    (pu) => pu.pricingUnitId == charge.fkPricingUnitId
  )?.pricingUnit;

  return (
    <div className={classes.charge}>
      <h4>{name}</h4>
      <div>
        <div className={classes.label}>Start Time</div>
        <div>{charge.startTime}</div>
      </div>
      <div>
        <div className={classes.label}>End Time</div>
        <div>{charge.endTime}</div>
      </div>
      <div>
        <div className={classes.label}>Amount</div>
        <div>
          {currency}
          {charge.amount} {pricingUnit}
        </div>
      </div>
      <DaysActiveDisplay
        monday={charge.monday}
        tuesday={charge.tuesday}
        wednesday={charge.wednesday}
        thursday={charge.thursday}
        friday={charge.friday}
        saturday={charge.saturday}
        sunday={charge.sunday}
      />

      <Button
        onClick={onDelete}
        variant={"outlined"}
        colour="error"
        size="small"
        startIcon={<DeleteIcon />}
        style={{ marginLeft: 5 }}
      >
        {t("Delete")}
      </Button>
    </div>
  );
};

export default TariffCharge;
