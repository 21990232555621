import { createUseStyles } from "react-jss";
import Colours from "../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  chargeGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr min-content",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 14,
  },

  noRateCharges: {
    color: theme.grey.grey3,
    fontWeight: 500,
    fontStyle: "italic",
    fontSize: 15,
  },
}));

export default useStyles;
