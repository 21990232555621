import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  nav_de: {
    width: "225px",
  },

  nav_en: {
    width: "175px",
  },

  nav: {
    backgroundColor: theme.nav.backgroundColor,
    margin: 0,
    paddingTop: 30,
    paddingLeft: 20,
    position: "fixed",
    height: "100%",
    overflow: "auto",
    borderRight: "2px solid " + theme.nav.borderRight,
  },

  logo: {
    cursor: "pointer",
    width: 150,
    marginBottom: 15,
  },
}));

export default useStyles;
