import React, { useState } from "react";
import { IonFooter, IonToolbar, IonRow, IonGrid } from "@ionic/react";
import Account from "../../core/icons/account.svg";
import Help from "../../core/icons/helpicon.svg";
import Home from "../../core/icons/home.svg";
import { PageType, useNavigate } from "../../hooks/use-navigate";
import "./styles.css";
import FooterItem from "./footer-item/FooterItem";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Keyboard } from "@capacitor/keyboard";
import { isNativeMobile } from "../../utils/ionic";
import { useTheme } from "react-jss";

export enum FooterPage {
  Home,
  Help,
  Account,
}

const MobileFooter = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigate();
  const { pathname } = useLocation();
  const [isActivePage, setIsActivePage] = useState<FooterPage>(FooterPage.Home);
  const [showFooter, setShowFooter] = useState(true);
  const theme: any = useTheme();

  if (isNativeMobile()) {
    Keyboard.addListener("keyboardWillShow", () => {
      setShowFooter(false);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setShowFooter(true);
    });
  }

  const onClick = (sitePage: FooterPage, route: string) => {
    if (isActivePage === sitePage) return;

    setIsActivePage(sitePage);
    goTo(route);
  };

  if (isActivePage === FooterPage.Home && !pathname.startsWith("/sites"))
    return <></>;

  if (!showFooter) return <></>;

  return (
    <IonFooter>
      <IonToolbar
        color="white"
        className="footer-menu"
        style={{ backgroundColor: theme.primary.boxBackground }}
      >
        <IonGrid fixed={true}>
          <IonRow>
            <FooterItem
              isActive={isActivePage === FooterPage.Home}
              icon={Home}
              label={t("Home")}
              testId={"home-button"}
              onClick={() => onClick(FooterPage.Home, "/sites")}
            />
            <FooterItem
              isActive={isActivePage === FooterPage.Help}
              icon={Help}
              label={t("Help")}
              testId={"help-button"}
              onClick={() => onClick(FooterPage.Help, "/help")}
            />
            <FooterItem
              isActive={isActivePage === FooterPage.Account}
              icon={Account}
              label={t("Account")}
              testId={"account-button"}
              onClick={() => onClick(FooterPage.Account, "/settings")}
            />
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};

export default MobileFooter;
