import { ICellTextProps } from "ka-table/props";
import { useEffect, useState } from "react";
import DataGrid from "../../core/grid";
import useWindowSize from "../../hooks/use-window-size";
import { columns, mobileColumns } from "./grid-def";
import useStyles from "./styles";
import useSitesStore from "../../hooks/use-sites";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Box from "../../core/box";
import { useNavigate } from "../../hooks/use-navigate";

const AllSitesPage = () => {
  const classes = useStyles();
  const [cols, setCols] = useState<any>(columns);
  const { width } = useWindowSize();
  const sites = useSitesStore((store) => store.sites);
  const { pageIndex, pageSize } = useSitesStore();
  const { t } = useTranslation();
  const { goTo } = useNavigate();

  const updatePageIndex = (pageIndex: number) => {
    useSitesStore.setState({ pageIndex });
  };
  const updatePageSize = (pageSize: number) => {
    useSitesStore.setState({ pageSize });
  };

  useEffect(() => {
    setCols(
      width < 700
        ? mobileColumns.map((col) => {
            return {
              ...col,
              title: t(`${col.title}`),
            };
          })
        : columns.map((col) => {
            return {
              ...col,
              title: t(`${col.title}`),
            };
          })
    );
  }, [width]);

  const getChildComponent = (props: ICellTextProps) => {
    if (props.column.key === "siteId") {
      return (
        <NavLink to={"/sites/" + props.rowKeyValue} className={classes.id}>
          #{props.rowKeyValue}
        </NavLink>
      );
    }
  };

  return (
    <Box>
      <h2>{t("All_Sites")}</h2>
      <DataGrid
        data={sites}
        rowKeyField={"siteId"}
        columns={cols}
        getChildComponent={getChildComponent}
        updatePageIndex={updatePageIndex}
        updatePageSize={updatePageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onRowClick={(row) => {
          goTo("/sites/" + row.siteId);
        }}
      />
    </Box>
  );
};

export default AllSitesPage;
