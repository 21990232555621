import { createUseStyles } from "react-jss";

interface Props {
  chartHeight: string | number;
}

const useStyles = createUseStyles({
  graph: {
    height: (props: Props) => props.chartHeight,
    display: "grid",
    gridTemplateRows: "auto auto",
    "@media(max-width: 800px)": {
      marginLeft: -5,
    },
  },

  overlayContainer: {
    gridRow: 2,
    gridColumn: 1,
    display: "grid",
  },

  overlay: {
    backgroundColor: "grey",
    opacity: 0.3,
    width: "100%",
    height: "100%",
    gridRow: 1,
    gridColumn: 1,
  },

  loader: {
    gridRow: 1,
    gridColumn: 1,
    alignSelf: "center",
    justifySelf: "center",
  },

  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default useStyles;
