import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  tariffDetails: {
    marginTop: 10,
    marginBottom: 20,
  },

  detail: {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 7,
  },

  key: {
    color: theme.primary.textColor3,
    paddingRight: 5,
  },
}));

export default useStyles;
