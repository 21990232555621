import { createUseStyles } from "react-jss";
import Colours from "../../utils/colours";

const useStyles = createUseStyles({
  noNotification: {
    fontStyle: "italic",
    fontSize: 14,
    marginTop: 10,
  },
});

export default useStyles;
