import moment from "moment";
import Select from "../../../../core/select";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../../utils/ionic";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { calculateDates } from "../../../../utils/constant";

interface Props {
  rates: any[];
  currentRateId: any;
  setCurrentRateId: any;
  currentRateLabel?: any;
  setCurrentRateLabel?: any;
}

const RateSelect = (props: Props) => {
  const { t } = useTranslation();
  const {
    rates,
    currentRateId,
    setCurrentRateId,
    currentRateLabel,
    setCurrentRateLabel,
  } = props;

  return (
    <>
      {isNativeMobile() ? (
        <IonSelect
          className="select"
          key="ion-select-rates"
          label={t("Period")}
          labelPlacement="stacked"
          data-testid="ion-select-rates"
          interface="action-sheet"
          onIonChange={(event) => {
            setCurrentRateId(event.detail.value);
            setCurrentRateLabel(
              calculateDates(
                rates.filter(
                  (r: { rateId: any }) => r.rateId === event.detail.value
                ),
                t
              )
            );
          }}
          cancelText={t("Cancel")}
          selectedText={currentRateLabel}
          style={{
            fontSize: 16,
          }}
        >
          {rates.map((r) => {
            return (
              <IonSelectOption
                key={`ion-option-${calculateDates(r, t)}`}
                data-testid={`ion-option-${calculateDates(r, t)}`}
                value={r.rateId}
              >
                {calculateDates(r, t)}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      ) : (
        <Select
          label={t("Select_Period")}
          value={currentRateId}
          onChange={setCurrentRateId}
          options={rates.map((r) => {
            return {
              value: r.rateId,
              label: calculateDates(r, t),
            };
          })}
          style={{ width: 300 }}
        />
      )}
    </>
  );
};

export default RateSelect;
