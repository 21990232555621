import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  authContainer: {
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    position: "absolute",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fallbacks: { width: "-moz-available", height: "100%" },
  },

  innerContainer: {
    backgroundColor: theme.primary.boxBackground,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    width: "100%",
    maxWidth: 380,
  },

  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 30,
    paddingRight: 10,
    flexDirection: "row",
  },

  topContainerSnx: {
    paddingTop: 10,
  },

  topLeft: {
    display: "flex",
    alignItems: "center",
  },

  logo: {
    padding: 0,
    paddingBottom: 0,
    backgroundColor: theme.primary.white,
    borderRadius: 7,
    width: 175,
    height: "auto",
  },

  switcherContainer: {
    height: 50,
    marginTop: "auto",
    marginBottom: "auto",
  },

  switcher: {
    float: "right",
  },
}));

export default useStyles;
