import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { isNativeMobile } from "../../../../utils/ionic";
import { IonList, IonItem, IonLabel, IonNote } from "@ionic/react";
import "./styles.css";
import Box from "../../../../core/box";

interface Props {
  savings: number;
}

const CarbonValues = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { savings } = props;
  const carsOffRoad = savings / 600;
  const treesPlanted = savings / 25;

  return (
    <>
      {isNativeMobile() ? (
        <>
          <div className={classes.label}>({t("Estimated")})</div>
          <div className={classes.detailsRow}>
            <div className={classes.label}>
              <div>
                {t("Total_of")} {t("Carbon_Saved")}
              </div>
            </div>
            <div className={classes.value} data-testid="carbon-saved-value">
              {Math.round(savings * 1e2) / 1e2}
              {t("suffix_kg")}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>
              {t("Equivalent")} {t("Cars_off_road")}
            </div>
            <div className={classes.value} data-testid="cars-off-road-value">
              {Math.round(carsOffRoad)}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>
              {t("Equivalent")} {t("Trees_planted")}
            </div>
            <div className={classes.value} data-testid="trees-planted-value">
              {Math.round(treesPlanted)}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.container}>
          <Box>
            <div className={classes.title}>{t("Estimated_total_of")}</div>
            <h3 data-testid="carbon-saved-value">
              {Math.round(savings * 1e2) / 1e2}
              {t("suffix_kg")}
            </h3>
            <div className={classes.subtitle}>{t("Carbon_Saved")}</div>
          </Box>

          <Box>
            <div className={classes.title}>{t("Equivalent_of")}</div>
            <h3 data-testid="cars-off-road-value">{Math.round(carsOffRoad)}</h3>
            <div className={classes.subtitle}>{t("Cars_off_road")}</div>
          </Box>

          <Box>
            <div className={classes.title}>{t("Equivalent_of")}</div>
            <h3 data-testid="trees-planted-value">
              {Math.round(treesPlanted)}
            </h3>
            <div className={classes.subtitle}>{t("Trees_planted")}</div>
          </Box>
        </div>
      )}
    </>
  );
};

export default CarbonValues;
