import { createUseStyles } from "react-jss";
import Colours from "../../../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  charge: {
    display: "grid",
    gridTemplateColumns: "1fr 100px 100px 100px auto auto",
    backgroundColor: theme.primary.backgroundColor,
    padding: 15,
    marginTop: 20,
    marginBottom: 20,
    alignItems: "center",
  },

  label: {
    fontSize: 11,
    fontWeight: "bold",
    color: theme.primary.textColor2,
  },
}));

export default useStyles;
