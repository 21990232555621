import { Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

interface Props {
  time: string | null;
  setTime: Dispatch<SetStateAction<string>>;
  label?: string;
  disabled?: boolean;
}

const TimePicker = (props: Props) => {
  const { t } = useTranslation();
  const format = t("time_format");
  const { time, setTime, label, disabled } = props;
  const theme: any = useTheme();

  const onChange = (e: any) => {
    if (e == null) {
      setTime("");
      return;
    }

    const time = e.format(format);
    setTime(time);
  };

  return (
    <MuiTimePicker
      label={label}
      defaultValue={time ? dayjs(time, format) : null}
      value={time ? dayjs(time, format) : null}
      format={format}
      onChange={onChange}
      disabled={disabled}
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        ".MuiSvgIcon-root": {
          fill: theme.primary.textColor,
        },
        ".MuiInputBase-adornedStart": {
          color: theme.primary.textColor,
        },
        ".MuiInputBase-root": {
          color: theme.primary.textColor,
        },
        ".MuiFormLabel-root": {
          color: theme.primary.textColor,
        },
      }}
    />
  );
};

export default TimePicker;
