import { FormControl, InputLabel, MenuItem } from "@mui/material";
import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "react-jss";

interface Props {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  options: { value: string; label: string }[];
  label?: string;
  style?: any;
}

const Select = (props: Props) => {
  const { label, value, onChange, placeholder, options, style } = props;
  const theme: any = useTheme();

  return (
    <FormControl sx={{ ...style, minWidth: 120 }}>
      <InputLabel style={{ color: theme.primary.textColor }}>
        {label}
      </InputLabel>

      <MuiSelect
        label={label}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        sx={{
          color: theme.primary.textColor,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.primary.textColor3,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.primary.textColor3,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.primary.textColor3,
          },
          ".MuiSvgIcon-root ": {
            fill: theme.primary.textColor,
          },
        }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
