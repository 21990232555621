import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  daysActive: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    gap: 15,
  },

  checkContainer: {
    all: "unset",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default useStyles;
