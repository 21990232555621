import {Route, Routes} from "react-router-dom";
import AuthUpdatePassword from "./auth/update-password";
import Login from "./auth/login";
import EmailLogin from "./auth/email-login";
import DeepLinkUrlListener from "./DeepLinkUrlListener";
import PasswordLogin from "./auth/email-password-login";

const UnauthenticatedRoutes = () => (
  <>
    <DeepLinkUrlListener/>
    <Routes>
      <Route path="/update-password" element={<AuthUpdatePassword/>}/>
      <Route path="/email-login" element={<EmailLogin/>}/>
      <Route path="/password-login" element={<PasswordLogin/>}/>
      <Route path="*" element={<Login/>}/>
    </Routes>
  </>
);

export default UnauthenticatedRoutes;
