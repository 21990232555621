import { useEffect, useState } from "react";
import { create } from "zustand";
import "ka-table/style.css";
import { ITableProps, kaReducer, Table } from "ka-table";
import { DispatchFunc } from "ka-table/types";
import { ChildComponents, Column } from "ka-table/models";
import { ICellTextProps } from "ka-table/props";
import useStyles from "./styles";
import initialTableProps from "./grid-props";

interface Props {
  data: any[];
  columns: Column[];
  rowKeyField: string;
  getChildComponent?: (row: any) => any;
  updatePageIndex?: (pageIndex: number) => void;
  updatePageSize?: (pageSize: number) => void;
  pageIndex?: number;
  pageSize?: number;
  onRowClick?: (row: any) => void;
}

const DataGrid = (props: Props) => {
  const {
    getChildComponent,
    rowKeyField,
    columns,
    data,
    pageIndex,
    pageSize,
    updatePageIndex,
    updatePageSize,
    onRowClick,
  } = props;

  const classes = useStyles();

  const childComponents: ChildComponents = {
    headCell: {
      elementAttributes: () => ({
        className: classes.headCell,
      }),
    },

    tableBody: {
      elementAttributes: () => ({
        className: classes.body,
      }),
    },

    dataRow: {
      elementAttributes: (props) => {
        return {
          className: classes.dataRow,
          onClick: () => (onRowClick ? onRowClick!(props.rowData) : {}),
        };
      },
    },
    cellText: {
      content: (props: ICellTextProps) => {
        if (getChildComponent != null) {
          return getChildComponent(props);
        }
      },

      elementAttributes: () => ({
        className: classes.cellText,
      }),
    },
    pagingSizes: {
      elementAttributes: () => {
        return {
          className: classes.dataRow,
        };
      },
    },

    paging: {
      elementAttributes: () => {
        return {
          className: classes.pagination,
        };
      },
    },
  };

  const taleProps = {
    ...initialTableProps,
    childComponents,
    rowKeyField,
    columns: columns!,
  };

  const [tableProps, changeTableProps] = useState(taleProps);

  useEffect(() => {
    changeTableProps((prevProps) => ({
      ...prevProps,
      paging: {
        ...prevProps.paging,
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    }));
  }, []);

  useEffect(() => {
    changeTableProps((tp) => ({ ...tp, columns }));
  }, [columns]);

  useEffect(() => {
    dispatch({ type: "UpdateData", data });
  }, [data]);

  const dispatch: DispatchFunc = (action) => {
    switch (action.type) {
      case "UpdatePageSize":
        if (updatePageSize) {
          updatePageSize(action.pageSize);
        }
        break;
      case "UpdatePageIndex":
        if (action.pageIndex !== undefined && updatePageIndex) {
          updatePageIndex(action.pageIndex);
        }
        break;
      default:
        break;
    }
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  return (
    <div>
      <Table {...tableProps} dispatch={dispatch} />
    </div>
  );
};

export default DataGrid;
