import { create } from "zustand";
import { TZ_OFFSET } from "../utils/constant";
import client from "../utils/client";

interface SitesStore {
  loading: boolean;
  isSingleSite: boolean;
  isMultiSite: boolean;
  siteId: string;
  sites: any[];
  pageIndex: number;
  pageSize: number;

  load: (user: any) => void;
}

const useSitesStore = create<SitesStore>((set) => ({
  pageSize: 10,
  pageIndex: 0,
  loading: true,
  isSingleSite: false,
  isMultiSite: false,
  siteId: "",
  sites: [],

  load: async (user: any) => {
    set({ loading: true });
    let sites = [];
    const res = await client.get(`/site/getByCurrentUser/${TZ_OFFSET}`);
    sites = res.data.response.map((s: any) => {
      return { ...s, address: s.buildingNumber + " " + s.addressLine1 };
    });

    if (sites.length === 0) {
      set({
        loading: false,
        isSingleSite: false,
        isMultiSite: false,
        siteId: "",
        sites,
      });
    } else {
      const isSingleSite = sites.length === 1;
      set({
        loading: false,
        isSingleSite,
        isMultiSite: sites.length > 1,
        sites,
        siteId: sites[0].siteId,
      });
    }
  },
}));

export default useSitesStore;
