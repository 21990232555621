export const mapRates = (rates: any) => {
  const filteredRates: { daysActive: string; rates: any }[] = [];

  rates.forEach((period: any) => {
    let daysActive = "";
    if (period.monday) daysActive += "M";
    if (period.tuesday) daysActive += "Tu";
    if (period.wednesday) daysActive += "W";
    if (period.thursday) daysActive += "Th";
    if (period.friday) daysActive += "F";
    if (period.saturday) daysActive += "Sa";
    if (period.sunday) daysActive += "Su";

    const currentPeriod = filteredRates.find((f) => f.daysActive == daysActive);

    if (currentPeriod == null) {
      const newPeriod = { daysActive, rates: [period] };
      filteredRates.push(newPeriod);
    } else {
      currentPeriod.rates.push(period);
    }
  });

  return filteredRates;
};

export const getRates = (periods: any) => {
  const iRates = periods.filter((r: any) => r.direction == "Import");
  const eRates = periods.filter((r: any) => r.direction == "Export");

  const importRates = mapRates(iRates);
  const exportRates = mapRates(eRates);

  return { importRates, exportRates };
};
