import useAuthStore from "../hooks/use-auth";
import { useEffect } from "react";
import client from "../utils/client";
import AuthenticatedRoutes from "./authenticated-routes";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";
import { useNavigate } from "../hooks/use-navigate";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../utils/ionic";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { IonContent } from "@ionic/react";
import MobileHeader from "../mobile/header";
import UnauthenticatedRoutes from "./unauthenticated-routes";
import MobileFooter from "../mobile/footer-menu";
import moment from "moment";
import useStyles from "./styles";

const App = () => {
  const { isAuthenticated, onLogin, logout, initialLoading } = useAuthStore();
  const auth = getAuth();
  const { t, i18n } = useTranslation();
  const { goTo } = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    moment.locale(i18n.language);
    if (isNativeMobile()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }, []);

  useEffect(() => {
    useAuthStore.setState({ goTo });

    try {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user
            .getIdToken()
            .then((token) => {
              client.defaults.headers.common = { Authorization: `${token}` };
              useAuthStore.setState({ manualAuth: false });
              onLogin(user, t("Verification_email_sent"), false);
              if (window.authStateCheckInterval) {
                window.clearInterval(window.authStateCheckInterval as any);
                window.authStateCheckInterval = undefined;
              }
              window.authStateCheckInterval = setInterval(() => {
                if (navigator.onLine) {
                  useAuthStore.setState({ manualAuth: false });
                  onLogin(user, t("Verification_email_sent"), false);
                }
              }, 18000);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          logout();
        }
      });
    } catch {
      logout();
    }
  }, [initialLoading, isAuthenticated]);

  if (initialLoading) return <></>;

  if (!isAuthenticated) {
    return (
      <>
        <UnauthenticatedRoutes />
        <div className={classes.background} />
      </>
    );
  }

  return (
    <>
      {isNativeMobile() && <MobileHeader />}
      <IonContent>
        <AuthenticatedRoutes />
        <div className={classes.background} />
      </IonContent>
      {isNativeMobile() && <MobileFooter />}
    </>
  );
};

export default App;
