import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "../button";
import { privateConfirmationStore } from "../../hooks/use-confirmation";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = () => {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    callback,
    title,
    description,
    confirmText,
    cancelText,
  } = privateConfirmationStore();

  const onConfirm = () => {
    onClose();
    callback!();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title || t("Are_sure_cont")}</DialogTitle>
      <DialogContent dividers>{description}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelText || t("Cancel")}</Button>
        <Button onClick={onConfirm} variant={"contained"}>
          {confirmText || t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
