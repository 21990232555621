import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  snextLogo: {
    cursor: "pointer",
    width: 150,
    marginBottom: 15,
  },

  joulenLogo: {
    cursor: "pointer",
    marginLeft: -30,
    marginBottom: -20,
    width: "250px",
    marginTop: -40,
  },
}));

export default useStyles;
