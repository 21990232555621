import useWindowSize from "../../hooks/use-window-size";
import DesktopNavBar from "./desktop";
import MobileNavBar from "./mobile";

const NavBar = () => {
  const { width } = useWindowSize();

  if (width < 1000) {
    return <MobileNavBar />;
  }

  return <DesktopNavBar />;
};

export default NavBar;
