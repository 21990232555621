import { DataType } from "ka-table/enums";

const columns = [
  { key: "siteId", title: "Site_ID", dataType: DataType.Number },
  { key: "address", title: "Address_Line_1", dataType: DataType.String },
  { key: "addressLine2", title: "Address_Line_2", dataType: DataType.String },
  { key: "postCode", title: "Postcode", dataType: DataType.String },
  {
    key: "parentOrganisationName",
    title: "Organisation_Name",
    dataType: DataType.String,
  },
];

const mobileColumns = [
  { key: "siteId", width: 90, title: "Site_ID", dataType: DataType.String },
  { key: "address", title: "Address_Line_1", dataType: DataType.String },
  { key: "view", width: 80, title: "", dataType: DataType.String },
];

export { columns, mobileColumns };
