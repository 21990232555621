import { useEffect, useState } from "react";
import client from "../../../utils/client";
import {
  TZ_OFFSET,
  calculateDates,
  getCurrency,
} from "../../../utils/constant";
import useCurrentSiteStore, { SitePage } from "../hooks/use-sites";
import TariffBox from "./tariff-box";
import TariffDetails from "./details";
import Loader from "../../../core/loader";
import { Stack } from "@mui/material";
import RateSelect from "./rate-select";
import UpdateTariff from "./update-tariff";
import { getRates } from "./getRates";
import TariffCharges from "./charges";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";

interface Props {
  site: any;
}

const SiteTariff = (props: Props) => {
  const { setPage } = useCurrentSiteStore();
  const [tariff, setTariff] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [currentRateId, setCurrentRateId] = useState<any>("");
  const [currentRateLabel, setCurrentRateLabel] = useState<any>("");
  const { siteId } = useParams();

  const load = async () => {
    setLoading(true);
    const res = await client.get(
      `/resolvedTariff/getBySiteId/${siteId}/${TZ_OFFSET}`
    );
    setCurrentRateId(res.data.response.tariffRates[0].rateId);

    setTariff(res.data.response);
    setLoading(false);
  };

  useEffect(() => {
    void load();
    setPage(SitePage.Tariff);
  }, []);

  if (loading) return <Loader />;

  const currentRate = tariff.tariffRates.find(
    (t: any) => t.rateId == currentRateId
  );

  let content = <div>{t("No_rates_found")}</div>;
  if (currentRate != null) {
    const { importRates, exportRates } = getRates(currentRate.ratePeriods);
    content = (
      <div>
        {importRates.map((rate: any, i) => {
          return <TariffBox key={i} rates={rate.rates} />;
        })}
        {exportRates.map((rate: any, i) => {
          return <TariffBox key={i} rates={rate.rates} />;
        })}

        <TariffCharges
          currency={getCurrency(tariff.currency)}
          charges={currentRate.rateCharges}
        />
      </div>
    );
  }

  return (
    <div>
      <TariffDetails tariff={tariff} />
      <h3
        style={{
          paddingTop: "1.5rem",
          borderTop: "1px solid #E3E3F0",
        }}
      >
        {t("Rates")}
      </h3>
      {!isNativeMobile() ? (
        <Stack direction={"row"} justifyContent={"space-between"} marginTop={4}>
          <RateSelect
            rates={tariff.tariffRates}
            currentRateId={currentRateId}
            setCurrentRateId={setCurrentRateId}
          />
          <UpdateTariff
            tariffId={tariff.tariffId}
            load={load}
            site={props.site}
          />
        </Stack>
      ) : (
        <Stack direction={"row"} justifyContent={"center"} marginTop={4}>
          <div
            style={{
              padding: "0px 5px 5px 10px",
              border: "1px solid #E3E3F0",
              borderRadius: "10px",
              width: "90%",
            }}
          >
            <RateSelect
              rates={tariff.tariffRates}
              currentRateId={currentRateId}
              setCurrentRateId={setCurrentRateId}
              currentRateLabel={calculateDates(
                tariff.tariffRates.filter(
                  (r: { rateId: any }) => r.rateId === currentRateId
                )[0],
                t
              )}
              setCurrentRateLabel={setCurrentRateLabel}
            />
          </div>
        </Stack>
      )}
      <div>{content}</div>
    </div>
  );
};

export default SiteTariff;
