import { ReactNode } from "react";
import { create } from "zustand";

interface OpenProps {
  title?: string;
  description?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  callback: () => void;
}

interface ConfirmationStore {
  open: boolean;

  title: string | null;
  description: string | ReactNode | null;
  confirmText: string | null;
  cancelText: string | null;
  callback: { (): void } | null;

  onOpen: (openProps: OpenProps) => void;
  onClose: () => void;
}

const useConfirmationStoreBase = create<ConfirmationStore>((set, get) => ({
  open: false,
  callback: null,
  title: null,
  description: null,
  confirmText: null,
  cancelText: null,

  onOpen: (props) => {
    set({ ...props, open: true });
  },

  onClose: () => {
    set({
      open: false,
      callback: null,
      title: null,
      description: null,
      confirmText: null,
      cancelText: null,
    });
  },
}));

const useConfirmationStore = () => {
  return {
    onOpen: useConfirmationStoreBase.getState().onOpen,
  };
};
export { useConfirmationStoreBase as privateConfirmationStore };
export default useConfirmationStore;
