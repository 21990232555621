import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LineChart from "../../../core/chart/line";
import DayWeekMonthDateSelector from "../../../core/period-selector/day-week-month";
import useAuthStore from "../../../hooks/use-auth";
import client from "../../../utils/client";
import { TZ_OFFSET } from "../../../utils/constant";
import { DateRange, MWDDateRange } from "../../../utils/types";
import useStyles from "./styles";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import SolarPanelDetails from "./details";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import Box from "../../../core/box";
import useSitesStore from "../../../hooks/use-sites";

const ProductionPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { assetId } = useParams();
  const [dateType, setDateType] = useState(MWDDateRange.Day);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().startOf("day").toISOString(),
    endDateTime: moment().endOf("day").toISOString(),
  });
  const { currentUser } = useAuthStore();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [prodResponse, setProdResponse] = useState<any>({});
  const [curProd, setCurProd] = useState<any>({});

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    return getChartOptions({
      scales: {
        x: {
          type: "time",
          time: {
            minUnit,
            tooltipFormat: "DD/MM HH:mm",
          },
        },
        y: {
          ticks: {
            callback: (value: any) => {
              return value + " " + t("suffix_kWh");
            },
            precision: 0,
          },
        },
      },

      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;

              return `${label}: ${formattedValue} ${t("suffix_kWh")}`;
            },
          },
        },
      },
    });
  }, [dateRange]);

  const loadData = async (dr: typeof dateRange) => {
    try {
      setLoading(true);
      const res = await client.post(
        "/kdbGateway/assetMetrics/getPowerGeneration",
        {
          sym: assetId,
          userId: currentUser.userId,
          startDate: moment(dr.startDateTime).format(
            t("date_time_format_seconds")
          ),
          endDate: moment(dr.endDateTime).format(t("date_time_format_seconds")),
          tzOffset: TZ_OFFSET,
        }
      );

      const genResponse = res.data.response;

      const genData = genResponse.data.map((x: any) => ({
        x: new Date(x["time"]),
        y: x["generationW"] / 1000,
      }));

      const d = {
        datasets: [
          {
            label: `${t("Energy_Generated")}`,
            data: genData,
            borderWidth: 1,
            borderColor: "rgba(252,186,3,1.0)",
            backgroundColor: "rgba(252,186,3,0.2)",
          },

          // todo: add range when available
          // {
          //   label: "Max",
          //   borderColor: Colours.blue5 + 40,
          //   backgroundColor: Colours.blue5 + 40,
          //   fill: false,
          //   data: genResponse.data.map((d: any) =>
          //     _.random(d.generationW, d.generationW + 1000)
          //   ),
          // },
          // {
          //   label: "Min",
          //   borderColor: Colours.blue5 + 40,
          //   backgroundColor: Colours.blue5 + 40,
          //   fill: "-1",
          //   data: genResponse.data.map((d: any) =>
          //     _.random(d.generationW - 1000, d.generationW)
          //   ),
          // },
        ],
      };

      const assetSumRes = await client.post(
        "/kdbGateway/assetMetrics/assetsSummaryMetrics",
        { siteId: genResponse.siteId, tzOffset: TZ_OFFSET }
      );

      const { generationSummaryMetrics } = assetSumRes.data.response;
      const curProd = generationSummaryMetrics.find(
        (g: any) => g.sym == assetId
      );

      setCurProd(curProd);
      setProdResponse(genResponse);
      setData(d);
      setLoading(false);
      setInitialLoading(false);
      useSitesStore.setState({ siteId: genResponse.siteId });
    } catch {
      enqueueSnackbar(t("Err_load_gen_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    loadData(dateRange);
  }, [dateRange]);

  if (initialLoading) {
    return <Loader />;
  }

  return (
    <div>
      <SolarPanelDetails
        generationType={prodResponse.generationType}
        currentGeneration={curProd.generationKw.toFixed(2)}
        weeklyGeneration={curProd.weekTotalGenKwh.toFixed(2)}
        capacity={curProd.capacityKw}
      />
      <DayWeekMonthDateSelector
        dateType={dateType}
        setType={setDateType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Energy_Generated")}
      />
      <Box>
        <div className={classes.chart}>
          <LineChart
            loading={loading}
            options={options}
            data={data}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName={"production"}
          />
        </div>
      </Box>
    </div>
  );
};

export default ProductionPage;
