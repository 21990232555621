import { Dispatch, SetStateAction } from "react";
import Input from "../../../../../../core/input";
import Select from "../../../../../../core/select";
import NewTariffActions from "../actions";
import useStyles from "./styles";
import useNewTariffStore from "../new-tariff-store";
import AnimationContainer from "../animation-container";
import { getCurrency } from "../../../../../../utils/constant";
import { useTranslation } from "react-i18next";

const TariffDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    tariffName,
    tariffRef,
    tariffDesc,
    tariffSupplierId,
    tariffMarketAreaId,
    tariffUseDaylightSavings,
    marketAreas,
    suppliers,
    setPage,
  } = useNewTariffStore();

  const update = (key: string, value: string) => {
    useNewTariffStore.setState({ [key]: value });
  };

  const canNext =
    tariffSupplierId != "" &&
    tariffName != "" &&
    tariffRef != "" &&
    tariffMarketAreaId != "" &&
    tariffUseDaylightSavings != "";

  return (
    <div>
      <AnimationContainer>
        <div>{t("Please_enter_tariff_details")}</div>
        <br />
        <div className={classes.grid}>
          <Select
            label={t("Supplier")}
            value={tariffSupplierId}
            options={suppliers.map((s) => {
              return { value: s.supplierId, label: s.supplierName };
            })}
            onChange={(v) => update("tariffSupplierId", v)}
            style={{ width: "100%" }}
            placeholder={t("Please_select")}
          />

          <Input
            label={t("Tariff_Name")}
            value={tariffName}
            onChange={(v) => update("tariffName", v)}
          />

          <Input
            label={t("Tariff_Ref")}
            value={tariffRef}
            onChange={(v) => update("tariffRef", v)}
          />

          <Input
            label={t("Description")}
            value={tariffDesc}
            onChange={(v) => update("tariffDesc", v)}
          />

          <Select
            label={t("Market_Area")}
            value={tariffMarketAreaId}
            options={marketAreas.map((ma) => {
              return { value: ma.marketAreaId, label: ma.marketAreaName };
            })}
            onChange={(v) => {
              const ma = marketAreas.find((m) => m.marketAreaId == v);
              const currency = getCurrency(ma.bidCurrency);
              useNewTariffStore.setState({ currency });
              update("tariffMarketAreaId", v);
            }}
            style={{ width: "100%" }}
            placeholder={t("Please_select")}
          />

          <Select
            label={t("Use_Daylight_Savings")}
            value={tariffUseDaylightSavings}
            options={[
              { value: "yes", label: t("Yes") },
              { value: "no", label: t("No") },
            ]}
            onChange={(v) => update("tariffUseDaylightSavings", v)}
            style={{ width: "100%" }}
            placeholder={t("Please_select")}
          />
        </div>
      </AnimationContainer>
      <NewTariffActions
        canBack={true}
        canNext={canNext}
        onBack={() => setPage("type")}
        onNext={() => setPage("dates")}
      />
    </div>
  );
};

export default TariffDetails;
