import { Dispatch, SetStateAction, useState } from "react";
import Checkbox from "../../../../../../../core/checkbox";
import Select from "../../../../../../../core/select";
import Input from "../../../../../../../core/input";
import useNewTariffStore from "../../new-tariff-store";
import DaysActive from "../../../../../../../core/days-active";
import TimePicker from "../../../../../../../core/time-picker";
import { Stack } from "@mui/material";
import Button from "../../../../../../../core/button";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
  setIsAddMode: Dispatch<SetStateAction<boolean>>;
}

const AddCharge = (props: Props) => {
  const { setIsAddMode } = props;
  const { t } = useTranslation();
  const [newCharge, setNewCharge] = useState(false);
  const [baseChargeId, setBaseChargeId] = useState<any>("");
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [amount, setAmount] = useState<any>("");
  const [pricingUnitId, setPricingUnitId] = useState<any>("");
  const [daysActive, setDaysActive] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });

  const { pricingUnits, baseCharges, currency, tariffRates } =
    useNewTariffStore();

  const onSetCharge = (baseChargeId: any) => {
    setBaseChargeId(baseChargeId);
  };

  const onSetDaysActive = (
    value: boolean,
    field:
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday"
  ) => {
    const newDaysActive = { ...daysActive };
    newDaysActive[field] = value;

    setDaysActive(newDaysActive);
  };

  const onAdd = () => {
    const newRates = [...tariffRates];

    newRates[0].additionalCharges!.push({
      chargeName: name,
      startTime,
      endTime,
      fkPricingUnitId: pricingUnitId!,
      fkBaseChargeId: baseChargeId!,
      monday: daysActive.monday,
      tuesday: daysActive.tuesday,
      wednesday: daysActive.wednesday,
      thursday: daysActive.thursday,
      friday: daysActive.friday,
      saturday: daysActive.saturday,
      sunday: daysActive.sunday,
      amount: amount!,
    });

    useNewTariffStore.setState({ tariffRates: newRates });
    setIsAddMode(false);
  };

  const selectedPricingUnit =
    pricingUnits.find((pu) => pu.pricingUnitId == pricingUnitId) || {};
  const { pricingUnit } = selectedPricingUnit;

  const canAdd =
    (baseChargeId != "" || name != "") &&
    amount > 0 &&
    pricingUnitId != "" &&
    (pricingUnit == t("per_kWh")
      ? moment(startTime, t("time_format"), true).isValid() &&
        moment(endTime, t("time_format"), true).isValid()
      : true);

  return (
    <div>
      <h3>{t("Add_Charge")}</h3>
      <div>
        <Checkbox
          label={t("Create_new_charge")}
          onChange={(value) => {
            setBaseChargeId("");
            setName("");
            setNewCharge(value);
          }}
          checked={newCharge}
        />
      </div>

      <div>
        {newCharge ? (
          <Input
            label={t("Charge_Name")}
            onChange={setName}
            value={name}
            style={{ width: 500 }}
          />
        ) : (
          <Select
            label={t("Charge")}
            value={baseChargeId}
            onChange={(value) => onSetCharge(value)}
            options={baseCharges.map((bc) => {
              return { value: bc.baseChargeId, label: bc.chargeName };
            })}
            style={{ width: 500 }}
          />
        )}
      </div>

      <br />

      <Stack direction={"row"} spacing={3}>
        <Input
          value={amount}
          onChange={setAmount}
          label={t("Amount")}
          type="number"
          prefix={currency}
        />
        <Select
          label={t("Pricing_Unit")}
          value={pricingUnitId}
          onChange={(value) => {
            const selectedPricingUnit =
              pricingUnits.find((pu) => pu.pricingUnitId == value) || {};
            const { pricingUnit } = selectedPricingUnit;

            if (pricingUnit != "per kWh") {
              setStartTime("");
              // onChangeCharge("", "endTime");
            }
            setPricingUnitId(value);
          }}
          options={pricingUnits.map((pu) => {
            return { value: pu.pricingUnitId, label: pu.pricingUnit };
          })}
          style={{ width: 200 }}
        />
      </Stack>

      <br />

      {pricingUnit == t("per_kWh") && (
        <>
          <Stack direction={"row"} spacing={3}>
            <TimePicker
              time={startTime}
              setTime={setStartTime}
              label={t("Start_Time")}
            />
            <TimePicker
              time={endTime}
              setTime={setEndTime}
              label={t("End_Time")}
            />
          </Stack>

          <br />
        </>
      )}

      <DaysActive
        onChange={onSetDaysActive}
        monday={daysActive.monday}
        tuesday={daysActive.tuesday}
        wednesday={daysActive.wednesday}
        thursday={daysActive.thursday}
        friday={daysActive.friday}
        saturday={daysActive.saturday}
        sunday={daysActive.sunday}
      />

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Button onClick={() => setIsAddMode(false)}>{t("Cancel")}</Button>
        <Button
          onClick={onAdd}
          size="small"
          variant={"contained"}
          disabled={!canAdd}
        >
          {t("Add")}
        </Button>
      </Stack>
    </div>
  );
};

export default AddCharge;
