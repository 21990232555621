export enum MQYDateRange {
  Custom,
  Year,
  Quarter,
  Month,
}

export enum MWDDateRange {
  Custom,
  Month,
  Week,
  Day,
}

export interface DateRange {
  startDateTime: string;
  endDateTime: string;
}
