import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  label: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.primary.textColor2,
  },

  value: {
    fontWeight: 600,
    fontSize: 17,
  },

  detailsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    rowGap: "20px",
  },
}));

export default useStyles;
