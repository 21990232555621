import { createUseStyles } from "react-jss";
import Colours from "../../../../../../utils/colours";

const useStyles = createUseStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr fr",
    gap: 20,
    marginBottom: 20,
  },
});

export default useStyles;
