import { ReactNode } from "react";
import useStyles from "./styles";
import LanguageSwitcher from "../../../core/lang-switch";
import { useTranslation } from "react-i18next";
import { Domain, getDomain } from "../../../utils/get-domain";

interface Props {
  children: ReactNode;
}

const AuthContainer = (props: Props) => {
  const { t } = useTranslation();
  const domain = getDomain();

  const altText =
    domain == Domain.SNXT
      ? "SonneNEXT Logo"
      : "The Electric Storage Company Logo";

  const logo =
    domain == Domain.SNXT
      ? "/images/Logo_SonneNext.webp"
      : "/images/PARIS INSIGHTS - STACKED.svg";

  const classes = useStyles();
  const { children } = props;

  let classNames = [classes.topContainer];
  if (domain == Domain.SNXT) {
    classNames.push(classes.topContainerSnx);
  }

  return (
    <div className={classes.authContainer}>
      <div className={classes.innerContainer}>
        <div className={classNames.join(" ")}>
          <div className={classes.topLeft}>
            <img alt={altText} src={logo} className={classes.logo} />
          </div>
          <div className={classes.switcherContainer}>
            <LanguageSwitcher className={classes.switcher} />
          </div>
        </div>
        <h2>{t("Welcome")}</h2>
        {children}
      </div>
    </div>
  );
};

export default AuthContainer;
