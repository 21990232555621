import { useTranslation } from "react-i18next";
import { mapRates } from "../getRates";
import TariffBox from "../tariff-box";
import useStyles from "./styles";

interface Props {
  charges: any[];
  currency: string;
}

const TariffCharges = (props: Props) => {
  const { charges, currency } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const kwhCharges = charges.filter((c) => c.pricingUnitName == t("per_kWh"));
  const standardCharges = charges.filter(
    (c) => c.pricingUnitName != t("per_kWh")
  );
  const filteredCharges = mapRates(kwhCharges);

  return (
    <div className={classes.solid}>
      <div className={classes.details}>
        <h2 className={classes.title}>{t("Charges")}</h2>
        {charges.length == 0 && (
          <div className={classes.subtitle}>{t("No_charges_to_display")}</div>
        )}
        <div className={classes.grid}>
          {standardCharges.map((c, index) => {
            return (
              <div style={{ display: "contents" }} key={index}>
                <div className={classes.subtitle}>{c.baseChargeName}:</div>
                <div className={classes.subtitle}>
                  {currency}
                  {c.unitPrice} {c.pricingUnitName}
                </div>
              </div>
            );
          })}
        </div>
        {filteredCharges.map((c, i) => {
          return (
            <TariffBox
              key={i}
              nameField="baseChargeName"
              priceField="unitPrice"
              title={t("Charge")}
              colour="#ca8300"
              currency={currency}
              rates={c.rates}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TariffCharges;
