import useNewTariffStore from "../new-tariff-store";
import SimpleTariffDates from "./simple";
import CustomPeriodSelector from "./custom";

const TariffDates = () => {
  const { type } = useNewTariffStore();

  if (type == "custom") return <CustomPeriodSelector />;

  return <SimpleTariffDates />;
};

export default TariffDates;
