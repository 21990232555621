import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  periodDates: {
    textAlign: "center",
    fontWeight: 600,
  },
});

export default useStyles;
