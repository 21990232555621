import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface Props {
  onChange: (
    value: boolean,
    field:
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday"
  ) => void;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const DaysActive = (props: Props) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  const onChange = (e: any) => {
    props.onChange(e.target.checked, e.target.value);
  };

  return (
    <div>
      <FormGroup onChange={onChange}>
        <Stack direction={"row"}>
          <FormControlLabel
            value="monday"
            control={<Checkbox checked={monday || false} />}
            label={t("day_abbrev_mon")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="tuesday"
            control={<Checkbox checked={tuesday || false} />}
            label={t("day_abbrev_tue")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="wednesday"
            control={<Checkbox checked={wednesday || false} />}
            label={t("day_abbrev_wed")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="thursday"
            control={<Checkbox checked={thursday || false} />}
            label={t("day_abbrev_thu")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="friday"
            control={<Checkbox checked={friday || false} />}
            label={t("day_abbrev_fri")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="saturday"
            control={<Checkbox checked={saturday || false} />}
            label={t("day_abbrev_sat")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
          <FormControlLabel
            value="sunday"
            control={<Checkbox checked={sunday || false} />}
            label={t("day_abbrev_sun")}
            labelPlacement="top"
            sx={{ margin: 0, padding: 0 }}
          />
        </Stack>
      </FormGroup>
    </div>
  );
};

export default DaysActive;
