import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  logo: {
    width: 300,
    cursor: "pointer",
  },
}));

export default useStyles;
