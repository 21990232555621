import { createUseStyles } from "react-jss";
import Colours from "../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    rowGap: 5,
    columnGap: 15,
    fontSize: 14,
    fontWeight: 600,
    width: "fit-content",
  },

  label: {
    color: theme.grey.grey5,
  },

  solid: {
    borderTop: "1px solid #E3E3F0",
    marginTop: "1rem",
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.primary.textColor,
    whiteSpace: "nowrap",
    marginBottom: 5,
  },

  subtitle: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.primary.textColor2,
    opacity: 0.6,
    alignSelf: "center",
    padding: "5px 0px 5px 0px",
  },

  details: {
    marginTop: "1rem",
    fontWeight: 500,
  },
}));

export default useStyles;
