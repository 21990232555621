import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LineChart from "../../../core/chart/line";
import DayWeekMonthDateSelector from "../../../core/period-selector/day-week-month";
import useAuthStore from "../../../hooks/use-auth";
import { useNavigate } from "../../../hooks/use-navigate";
import client from "../../../utils/client";
import Colours from "../../../utils/colours";
import { TZ_OFFSET } from "../../../utils/constant";
import { DateRange, MWDDateRange } from "../../../utils/types";
import BatteryIcon from "./battery";
import useStyles from "./styles";
import { enqueueSnackbar } from "notistack";
import chartOptions from "../../../core/chart/line/options";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import BatteryDetails from "./battery-details";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import Box from "../../../core/box";

const BatteryPage = () => {
  const classes = useStyles();
  const { goTo } = useNavigate();
  const { assetId } = useParams();
  const { t } = useTranslation();
  const [dateType, setDateType] = useState(MWDDateRange.Day);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().startOf("day").toISOString(),
    endDateTime: moment().endOf("day").toISOString(),
  });
  const { currentUser } = useAuthStore();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [batteryRes, setBatteryRes] = useState<any>({});
  const [stateOfCharge, setStateOfCharge] = useState(0);

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    const lineOverrides = !isNativeMobile()
      ? {
          elements: {
            line: {
              fill: false,
            },
          },
        }
      : {};

    const scalesOverrdes = {
      scales: {
        x: {
          type: "time",
          time: {
            minUnit,
            tooltipFormat: "DD/MM HH:mm",
          },
        },
        x1: {
          display: false,
          type: "time",
        },
        y: {
          title: {
            display: true,
            text: t("Current_power_in_out"),
          },
          ticks: {
            callback: (value: any) => {
              return value + " " + t("suffix_kW");
            },
            precision: 0,
          },
        },
        y1: {
          title: {
            display: true,
            text: t("State_of_charge"),
          },
          type: "linear",
          position: "right",
          ticks: {
            callback: (value: any) => {
              return value + "%";
            },
          },
        },
      },
    };

    const pluginOverrides = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;

              let suffix = " " + t("suffix_kW");
              if (label === t("State_of_charge")) suffix = "%";

              return label + ": " + formattedValue + suffix;
            },
          },
        },
      },
    };

    return getChartOptions({
      ...lineOverrides,
      ...scalesOverrdes,
      ...pluginOverrides,
    });
  }, [dateRange]);

  const loadData = async (dr: typeof dateRange) => {
    setLoading(true);
    try {
      const res = await client.post("/kdbGateway/assetMetrics/getBatteryData", {
        sym: assetId,
        userId: currentUser.userId,
        startDate: moment(dr.startDateTime).format(
          t("date_time_format_seconds")
        ),
        endDate: moment(dr.endDateTime).format(t("date_time_format_seconds")),
        tzOffset: TZ_OFFSET,
      });

      const bRes = res.data.response;

      const curPower = bRes.data.map((x: any) => ({
        x: x["time"],
        y: x["curPower"] / 1000,
      }));

      const socData = bRes.data.map((x: any) => ({
        x: x["time"],
        y: x["stateOfCharge"],
      }));

      const d = {
        datasets: [
          {
            label: t("Current_power_in_out"),
            data: curPower,
            borderColor: Colours.blue5,
            backgroundColor: Colours.light_blue5,
            xAxisID: "x",
            yAxisID: "y",
            borderWidth: 1,
          },
          {
            label: t("State_of_charge"),
            data: socData,
            borderColor: "rgba(0,184,148,1.0)",
            backgroundColor: "rgba(0,184,148,0.2)",
            xAxisID: "x1",
            yAxisID: "y1",
            borderWidth: 1,
          },
        ],
      };

      setBatteryRes(bRes);
      setData(d);
      setLoading(false);
      setInitialLoading(false);
    } catch {
      enqueueSnackbar(t("Error_loading_battery_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    loadData(dateRange);
  }, [dateRange]);

  useEffect(() => {
    const load = async () => {
      const res = await client.post("/kdbGateway/battery/getBatterySoc", {
        sym: assetId,
      });
      setStateOfCharge(
        Math.round((res.data.response + Number.EPSILON) * 100) / 100
      );
    };

    load();
  }, []);

  if (initialLoading) {
    return <Loader />;
  }

  return (
    <>
      <BatteryDetails
        charge={stateOfCharge}
        capacity={batteryRes.capacity}
        serial={batteryRes.serial}
        type={batteryRes.model}
      />
      <DayWeekMonthDateSelector
        dateType={dateType}
        setType={setDateType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Power_And_Charge")}
      />
      <Box>
        <div className={classes.chart}>
          <LineChart
            loading={loading}
            data={data}
            options={options}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName={"battery"}
          />
        </div>
      </Box>
    </>
  );
};

export default BatteryPage;
