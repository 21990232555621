import React from "react";
import ThemeProvider from "./core/theme-provider";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./pages";
import { BrowserRouter } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "chartjs-plugin-zoom";
import Hammer from "chartjs-plugin-zoom";
import { NavigateProvider } from "./hooks/use-navigate";
import "react-tooltip/dist/react-tooltip.css";
import SbProvider from "./core/snackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IonApp, setupIonicReact } from "@ionic/react";
import i18n, { LanguageDetectorAsyncModule } from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./pages/locales/en/translation.json";
import translationDE from "./pages/locales/de/translation.json";
import "moment/locale/de";
import { Device } from "@capacitor/device";
import { isNativeMobile } from "./utils/ionic";
import updateSiteDetails from "./core/site-details";
import "moment/locale/de";

import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "./root.css";

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

const MobileLanguageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (callback: (lng: string) => void) => {
    Device.getLanguageCode().then((languageCode) => {
      callback(languageCode.value);
    });
  },
};

if (isNativeMobile()) {
  i18n.use(MobileLanguageDetector);
} else {
  i18n.use(LanguageDetector);
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
});

i18n.on("languageChanged", function (lng) {
  moment.locale(lng);
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Hammer,
  ArcElement,
  TimeScale
);

setupIonicReact();

updateSiteDetails();
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <NavigateProvider>
      <SbProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <I18nextProvider i18n={i18n}>
            <ThemeProvider>
              <IonApp>
                <App />
              </IonApp>
            </ThemeProvider>
          </I18nextProvider>
        </LocalizationProvider>
      </SbProvider>
    </NavigateProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
