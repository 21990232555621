import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../../../../utils/client";
import { TZ_OFFSET } from "../../../../utils/constant";
import Loader from "../../../../core/loader";
import BackButton from "../../../../core/back";
import TariffDetails from "./details";
import TariffRates from "./rates";
import RatePeriods from "./rate-periods";
import TariffRateCharges from "./charges";
import useAuthStore from "../../../../hooks/use-auth";

const TariffPage = () => {
  const { tariffId } = useParams();
  const [loading, setLoading] = useState(true);
  const [tariff, setTariff] = useState<any>({});
  const [selectedRateId, setSelectedRateId] = useState(-1);
  const [baseCharges, setBaseCharges] = useState([]);
  const [pricingUnits, setPricingUnits] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { currentUser } = useAuthStore();

  const load = async () => {
    const tariffRes = await client.get(
      `/resolvedTariff/getByTariffId/${tariffId}/${TZ_OFFSET}`
    );

    setTariff(tariffRes.data.response);
    if (tariffRes.data.response.tariffRates.length > 0) {
      setSelectedRateId(tariffRes.data.response.tariffRates[0].rateId);
    }

    const baseChargeRes = await client.get(
      `/tariffBaseCharge/getBaseChargesByUserId/${currentUser.userId}/${TZ_OFFSET}`
    );

    const pricingUnitRes = await client.get(`/chargePricingUnit/getAll`);

    const cusRes = await client.get(
      `/customer/getByUserId/${currentUser.userId}/${TZ_OFFSET}`
    );

    setBaseCharges(baseChargeRes.data.baseChargeDtos);
    setPricingUnits(pricingUnitRes.data.tariffChargePricingUnitDtos);
    setCustomers(cusRes.data.response);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <br />
      <BackButton defaultRoute={"/settings/tariffs"} />
      <br />

      <TariffDetails tariff={tariff} />

      <TariffRates
        tariff={tariff}
        selectedRateId={selectedRateId}
        setSelectedRateId={setSelectedRateId}
        load={load}
      />
      <br />
      <RatePeriods
        tariff={tariff}
        selectedRateId={selectedRateId}
        load={load}
      />
      <br />
      <TariffRateCharges
        tariff={tariff}
        selectedRateId={selectedRateId}
        load={load}
        pricingUnits={pricingUnits}
        baseCharges={baseCharges}
        customers={customers}
      />
    </div>
  );
};

export default TariffPage;
