import { CSSProperties } from "react";
import { TextField } from "@mui/material";
import { useTheme } from "react-jss";

interface Props {
  label?: string;
  value: string | number | null;
  onChange: (value: any) => void;
  placeholder?: string;
  type?: "input" | "password" | "number" | "email";
  autoComplete?: "on" | "off";
  error?: boolean;
  errorText?: string;
  onKeyDown?: (event: { key: string }) => void;
  onClick?: () => void;
  prefix?: string;
  suffix?: string;
  style?: CSSProperties;
  id?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  autoCapitalize?: string;
}

const Input = (props: Props) => {
  const {
    label,
    type,
    value,
    placeholder,
    onChange,
    onKeyDown,
    autoComplete = "off",
    prefix,
    suffix,
    error,
    errorText = "",
    style,
    id,
    fullWidth = false,
    multiline = false,
    rows = 1,
    autoCapitalize,
  } = props;
  const theme: any = useTheme();

  return (
    <TextField
      label={label}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(ev) => onKeyDown?.(ev)}
      placeholder={placeholder}
      autoComplete={autoComplete}
      InputProps={{
        startAdornment: prefix,
        endAdornment: suffix,
      }}
      inputProps={{ style: { color: theme.primary.textColor }, autoCapitalize }}
      InputLabelProps={{
        style: { color: theme.primary.textColor },
      }}
      type={type}
      style={style}
      id={id}
      error={error}
      helperText={errorText}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.primary.textColor3,
        },
        ".MuiSvgIcon-root": {
          fill: theme.primary.textColor,
        },
        ".MuiInputBase-adornedStart": {
          color: theme.primary.textColor,
        },
      }}
    />
  );
};

export default Input;
