import moment from "moment";
import Button from "../../../button";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface Props {
  chartRef: any;
  canExport: boolean;
  exportName: string;
}

const Export = (props: Props) => {
  const { current } = props.chartRef;
  const { exportName, canExport } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (!canExport) return null;

  const onExport = () => {
    const dataName = moment().format("YYYYMMDDTHHmm-");
    const { x } = current.scales;
    const { datasets } = current.data;

    const dataSetsToExport = current.legend.legendItems.filter(
      (l: any) => !l.hidden
    );

    const dataToExport: any[] = [];

    dataSetsToExport.forEach((dataSet: any) => {
      const ds = datasets.find((d: any) => d.label === dataSet.text);

      let data = ds.data.filter(
        (d: any) =>
          new Date(d.x).getTime() >= x.min && new Date(d.x).getTime() <= x.max
      );

      if (dataToExport.length === 0) {
        const labels = data.map((d: any) => moment(d.x).toISOString());
        dataToExport.push(["series", ...labels]);
      }

      if (data.length > 0 && data[0].y != null) {
        data = data.map((d: any) => d.y);
      }

      data.unshift(ds.label);
      dataToExport.push(data);
    });

    const csv =
      "data:text/csv;charset=utf-8," +
      dataToExport.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", dataName + exportName + ".csv");
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div className={classes.container}>
      <Button name={t("Export_CSV")} onClick={onExport} />
    </div>
  );
};

export default Export;
