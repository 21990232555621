import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    fontWeight: 600,
    marginTop: 30,
  },

  button: {
    all: "unset",
    backgroundColor: theme.primary.primary1,
    border: "1px solid " + theme.primary.primary5,
    color: theme.primary.primary5,
    borderRadius: 100,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 700,
    marginTop: 30,
  },

  detailContainer: {
    marginTop: 10,
  },

  label: {
    color: theme.grey.grey5,
  },

  switcher: {
    marginBottom: 5,
  },

  notes: {
    fontSize: 16,
    alignSelf: "center",
  },

  subTitle: {
    fontSize: "20px !important",
  },

  title: {
    fontSize: "22px !important",
  },

  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "16px 16px 16px 16px",
  },

  topLeft: {
    display: "flex",
  },

  logOut: {
    color: "#D6371F",
  },
}));

export default useStyles;
