import { createUseStyles } from "react-jss";
import GlobeIcon from "../icons/globe.svg";

const useStyles = createUseStyles({
  selector: {
    backgroundImage: `url('${GlobeIcon}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "15px auto",
    backgroundPosition: "10px center",
  },
});

export default useStyles;
