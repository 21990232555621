import moment from "moment";

const formatData = (body: any, labelArray: Array<string>, batUnits: any) => {
  let houseConsumption = body.hourlyNetFlow.map((x: any) => ({
    x: new Date(x["time"]),
    y: x["houseConsumption"] / 1000,
  }));

  let clusterProd = body.hourlyNetFlow.map((x: any) => ({
    x: new Date(x["time"]),
    y: x["clusterProd"] / 1000,
  }));

  let netGrid = body.hourlyNetFlow.map((x: any) => ({
    x: new Date(x["time"]),
    y: x["netGrid"] / 1000,
  }));

  let totalCapacity = 0;
  batUnits.forEach((metric: any)  => {
    if (metric.capacityKwh != null) {
    totalCapacity += metric.capacityKwh;
    }
  });

  let storedCharge = [];
  if (totalCapacity !== 0) {
    storedCharge = body.storedCharge.map((x: any) => ({
      x: new Date(x["time"]),
      y: (x["avgCharge"] / 1000) / totalCapacity * 100,
    }));
  }

  let pBattery = body.storedCharge.map((x: any) => ({
    x: new Date(x["time"]),
    y: x["pbattery"] / 1000,
  }));

  const d = {
    datasets: [
      {
        label: labelArray[0],
        data: houseConsumption,
        borderColor: "#1F77B4",
        backgroundColor: "#1F77B4",
        borderWidth: 1,
        fill: {
          target: "origin",
          above: "#1F77B435",
          below: "#1F77B435",
        },
      },

      {
        label: labelArray[1],
        data: clusterProd,
        borderColor: "#2CA02C",
        backgroundColor: "#2CA02C",
        borderWidth: 1,
        fill: {
          target: "origin",
          above: "#2CA02C" + "35",
          below: "#2CA02C" + "35",
        },
      },

      {
        hidden: true,
        label: labelArray[2],
        data: netGrid,
        borderColor: "#D62728",
        backgroundColor: "#D62728",
        borderWidth: 1,
        fill: {
          target: "origin",
          above: "#D62728" + "35",
          below: "#D62728" + "35",
        },
      },

      {
        hidden: true,
        label: labelArray[3],
        data: storedCharge,
        borderColor: "#FF7F0E",
        backgroundColor: "#FF7F0E",
        yAxisID: "y1",
        borderWidth: 1,
        fill: {
          target: "origin",
          above: "#FF7F0E" + "35",
          below: "#FF7F0E" + "35",
        },
      },

      {
        hidden: true,
        label: labelArray[4],
        data: pBattery,
        borderColor: "#9467BD",
        backgroundColor: "#9467BD",
        borderWidth: 1,
        fill: {
          target: "origin",
          above: "#9467BD" + "35",
          below: "#9467BD" + "35",
        },
      },
    ],
  };

  return d;
};

const performanceOptions = (minUnit: string) => ({
  animation: false, // disables all animations

  scales: {
    x: {
      type: 'time',
      time: {
          minUnit,
          tooltipFormat: 'DD/MM HH:mm'
      }
    },
    y: {
      ticks: {
        callback: (value: any) => {
          return value + " kW";
        },
        precision: 0
      },
    },
    y1: {
      hidden: true,
      type: 'linear',
      position: 'right',
      ticks: {
        callback: (value:any) => {
          return value + '%';
        }
      }
    },
  },

  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const { label } = tooltipItem.dataset;
          const { formattedValue } = tooltipItem;

          let suffix = "";

          switch (label) {
            case "Site Consumption":
            case "Site Production":
            case "Net Grid":
            case "Power To Battery":
              suffix = " kW";
              break;
            case "Stored Charge":
              suffix = "%";
              break;
          }

          return label + ": " + formattedValue + suffix;
        },
      },
    },
  },
});

export { formatData, performanceOptions };
