import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  subtitle: {
    color: theme.grey.grey5,
    fontWeight: 600,
    marginBottom: 30,
    fontSize: 20,
  },

  backToLogin: {
    all: "unset",
    paddingTop: 15,
    paddingBottom: 15,
    cursor: "pointer",
    color: theme.primary.primary5,
    fontWeight: 600,
    fontSize: 15,
    display: "flex",
    alignContent: "center",

    "&:hover": {
      opacity: 0.7,
      textDecoration: "underline",
    },
  },

  backText: {
    marginLeft: 7,
  },

  bottomContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
  },

  newPassword: {
    display: "flex",
    alignItems: "center",
  },

  tooltip: {
    marginTop: -15,
    paddingLeft: 5,
  },
}));

export default useStyles;
