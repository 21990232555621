import { Divider, Stack } from "@mui/material";
import { RatePeriodProps } from "../../types";
import useStyles from "./styles";
import useNewTariffStore from "../../new-tariff-store";
import DaysActiveDisplay from "../../../../../../../core/days-active-display";
import Button from "../../../../../../../core/button";
import { useTranslation } from "react-i18next";

interface Props {
  ratePeriods: RatePeriodProps[];
  periodName?: string;
}

const TariffOverviewPeriod = (props: Props) => {
  const { ratePeriods } = props;
  const classes = useStyles();
  const { currency } = useNewTariffStore();

  return (
    <div className={classes.container}>
      {ratePeriods.map((rp) => {
        return (
          <>
            <div>{rp.periodName}</div>
            <div>{rp.direction}</div>
            <div>
              {currency}
              {rp.unitPrice}
            </div>
            {rp.startTime == rp.endTime ? (
              <div>All Day</div>
            ) : (
              <Stack direction={"row"}>
                <div>{rp.startTime}</div> - <div>{rp.endTime}</div>
              </Stack>
            )}
            <DaysActiveDisplay
              monday={rp.monday}
              tuesday={rp.tuesday}
              wednesday={rp.wednesday}
              thursday={rp.thursday}
              friday={rp.friday}
              saturday={rp.saturday}
              sunday={rp.sunday}
            />
          </>
        );
      })}
    </div>
  );
};

export default TariffOverviewPeriod;
