import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  detailsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
  },

  label: {
    color: theme.primary.textColor2,
    display: "flex",
    gap: 5,
  },

  value: {
    color: theme.primary.textColor,
  },
}));

export default useStyles;
