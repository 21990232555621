import { DataType } from "ka-table/enums";

const columns = [
  { key: "tariffId", title: "Tariff_ID", dataType: DataType.Number },
  { key: "tariffName", title: "Tariff_Name", dataType: DataType.String },
  { key: "tariffDesc", title: "Tariff_Description", dataType: DataType.String },
];

const mobileColumns = [
  { key: "tariffId", width: 90, title: "ID", dataType: DataType.String },
  { key: "tariffName", title: "Tariff_Name", dataType: DataType.String },
  { key: "view", width: 80, title: "", dataType: DataType.String },
];

export { columns, mobileColumns };
