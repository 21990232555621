import { ReactNode } from "react";
import useStyles from "./styles";
import { SnackbarProvider, closeSnackbar } from "notistack";

interface Props {
  children: ReactNode;
}

const SbProvider = (props: Props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      action={(snackbarId) => (
        <button
          onClick={() => closeSnackbar(snackbarId)}
          className={classes.closeButton}
        >
          x
        </button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SbProvider;
