import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  asset: {
    height: "100%",
    background: theme.primary.boxBackground,
    cursor: "pointer",
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    borderRadius: 15,

    "&:hover": {
      opacity: 0.7,
    },
  },

  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    paddingTop: 20,

    flexDirection: "row",
  },

  topLeft: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: 18,
    whiteSpace: "nowrap",
    color: theme.primary.textColor,
  },

  titleContainer: {
    display: "contents",
  },

  subtitle: {
    weight: 400,
    fontSize: 17.5,
    paddingInlineStart: 8,
  },

  mobileSubtitle: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: 12,
    color: theme.primary.textColor,
    opacity: 0.6,
  },

  body: {
    padding: 15,
    display: "flex",
  },

  details: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridAutoFlow: "row",
    alignItems: "end",
    gridGap: 10,
    width: "-webkit-fill-available",
    height: "fit-content",
  },

  metric: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    inlineSize: "min-content",
  },

  metricName: {
    color: theme.primary.textColor2,
    opacity: 0.6,
    fontSize: 14,
    fontWeight: 400,
  },

  metricValueContainer: {
    display: "flex",
    paddingBottom: 10,
  },

  metricValue: {
    marginLeft: 5,
    fontSize: 27,
    fontWeight: 500,
    color: theme.primary.textColor,
  },

  metricUnit: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 400,
    alignSelf: "center",
    marginTop: "0.5rem",
    color: theme.primary.textColor,
  },

  ionButtonMobile: {
    width: 45,
    height: 45,
    "--border-radius": "50%",
  },

  more: {
    display: "flex",
    alignItems: "center",
    color: theme.primary.primary5,
  },

  ionButtonText: {
    fontWeight: 400,
    textTransform: "capitalize",
    marginRight: 1,
    fontSize: 14,
  },

  ArrowLongRightMobile: {
    marginTop: 2,
    color: theme.primary.primary5,
    width: 20,
    height: 20,
  },

  ArrowLongRight: {
    stroke: theme.primary.primary5,
    marginTop: 3,
    marginLeft: 4,
  },

  lastUpdated: {
    position: "absolute",
    bottom: 10,
    right: 10,
    fontStyle: "italic",
    color: theme.primary.textColor2,
  },
}));

export default useStyles;
