import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  subtitle: {
    color: theme.primary.textColor2,
    fontWeight: 600,
    marginBottom: 30,
    fontSize: 20,
  },

  break: {
    height: 30,
  },

  error: {
    fontWeight: 500,
    fontSize: 14,
    color: "red",
  },

  signInEmailLink: {
    all: "unset",
    paddingTop: 30,
    paddingBottom: 0,
    cursor: "pointer",
    color: theme.primary.primary5,
    fontWeight: 600,
    fontSize: 15,
    display: "flex",
    alignContent: "center",
    "&:hover": {
      opacity: 0.7,
      textDecoration: "underline",
    },
  },

  title: {
    color: theme.primary.primary5,
    fontSize: 24,
    fontWeight: "bold",
  },
}));

export default useStyles;
