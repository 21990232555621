import { ReactNode } from "react";
import { Tooltip } from "react-tooltip";
import useStyles from "./styles";

interface Props {
  id: string;
  children: ReactNode;
}

const InfoTooltip = (props: Props) => {
  const { id, children } = props;
  const classes = useStyles();

  return (
    <>
      <div data-tooltip-id={id} className={classes.i}>
        i
      </div>
      <Tooltip id={id} className={classes.toolTip}>
        <div>{children}</div>
      </Tooltip>
    </>
  );
};

export default InfoTooltip;
