import { createUseStyles } from "react-jss";
import Colours from "../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  spinnerContainer: {
    animation: "$spin 1s ease-in-out infinite",
    borderRadius: "50%",
    border: "4px solid #fff",
    borderColor: `${theme.primary.primary5} transparent ${theme.primary.primary5} transparent`,
    width: "20px",
    height: "20px",
    display: "inline-block",
    left: "50%",
    position: "absolute",
    textAlign: "center",
    marginLeft: " -0.75rem",
    top: "50%",
  },
  "@keyframes spin": {
    to: {
      "-webkit-transform": "rotate(360deg)",
    },
  },
}));

export default useStyles;
