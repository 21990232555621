import { useTranslation } from "react-i18next";
import NewTariffActions from "../actions";
import AnimationContainer from "../animation-container";
import useNewTariffStore from "../new-tariff-store";
import { TariffRateProps, TariffType } from "../types";
import useStyles from "./styles";

const TariffTypeStep = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPage, type } = useNewTariffStore();

  const onClick = (type: TariffType) => {
    let tariffRates: TariffRateProps[] = [];

    switch (type) {
      case "flatRate":
        tariffRates = [
          {
            startDate: "",
            endDate: "",
            additionalCharges: [],
            ratePeriods: [
              {
                periodName: "",
                unitPrice: 0,
                direction: "import",
                startTime: "00:00",
                endTime: "00:00",
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
              },
            ],
          },
        ];

        break;

      case "eco7":
        tariffRates = [
          {
            startDate: "",
            endDate: "",
            additionalCharges: [],
            ratePeriods: [
              {
                periodName: "",
                unitPrice: 0,
                direction: "import",
                startTime: "",
                endTime: "",
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
              },

              {
                periodName: "",
                unitPrice: 0,
                direction: "import",
                startTime: "",
                endTime: "",
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
              },
            ],
          },
        ];

        break;

      case "variableRate":
        break;
    }

    useNewTariffStore.setState({ page: "details", type, tariffRates });
  };

  return (
    <div>
      <AnimationContainer>
        <div>{t("Please_select_type_of_template")}</div>

        <TariffSelect
          name={t("Flat_Rate")}
          description={t("One_rate")}
          onClick={() => onClick("flatRate")}
        />

        <TariffSelect
          name={t("Economy_7")}
          description={t("Tariff_split_day_night")}
          onClick={() => onClick("eco7")}
        />

        {/* <TariffSelect
          name={"Variable Rate"}
          description={"The price you pay is dictated by the market rate."}
          onClick={() => onClick("variableRate")}
        /> */}

        <TariffSelect
          name={"Custom"}
          description={
            "Create your own tariff from scratch. Use this if your tariff is more complicated. "
          }
          onClick={() => onClick("custom")}
        />
      </AnimationContainer>
      <NewTariffActions
        canBack={false}
        canNext={type != ""}
        onNext={() => setPage("details")}
      />
    </div>
  );
};

interface Props {
  name: string;
  description: string;
  onClick: () => void;
}

const TariffSelect = (props: Props) => {
  const classes = useStyles();
  const { name, description, onClick } = props;

  return (
    <div>
      <button className={classes.button} onClick={onClick}>
        <h5>{name}</h5>
        <div>{description}</div>
      </button>
    </div>
  );
};

export default TariffTypeStep;
