import { Stack } from "@mui/material";
import AnimationContainer from "../../animation-container";
import NewTariffActions from "../../actions";
import useNewTariffStore from "../../new-tariff-store";
import Button from "../../../../../../../core/button";
import { DatePicker } from "@mui/x-date-pickers";
import useStyles from "./styles";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const CustomPeriodSelector = () => {
  const { setPage, tariffRates } = useNewTariffStore();
  const classes = useStyles();

  const canNext =
    tariffRates.length > 0 &&
    tariffRates.every((tr) => {
      const isStartDateValid = moment(
        tr.startDate,
        "DD/MM/YYYY",
        true
      ).isValid();
      const isEndDateValid =
        tr.endDate == null ||
        tr.endDate === "" ||
        moment(tr.endDate, "DD/MM/YYYY", true).isValid();

      return isStartDateValid && isEndDateValid;
    });

  const onAdd = () => {
    const newRates: any = [...tariffRates];
    newRates.push({
      startDate: null,
      endDate: null,
      ratePeriods: [],
      additionalCharges: [],
    });
    useNewTariffStore.setState({ tariffRates: newRates });
  };

  const onChangeDate = (
    date: Dayjs | null,
    index: number,
    dateType: "startDate" | "endDate"
  ) => {
    const newRates = [...tariffRates];
    newRates[index][dateType] = date!.format("DD/MM/YYYY");
    useNewTariffStore.setState({ tariffRates: newRates });
  };

  const onDelete = (index: number) => {
    const newRates = [...tariffRates];
    newRates.splice(index, 1);
    useNewTariffStore.setState({ tariffRates: newRates });
  };

  return (
    <div>
      <AnimationContainer>
        <div>Set up all the required periods for this custom tariff.</div>
        <div className={classes.grid}>
          {tariffRates.map((t, i) => {
            return (
              <>
                <DatePicker
                  label="Start Date"
                  value={dayjs(t.startDate, "DD/MM/YYYY")}
                  onChange={(d) => onChangeDate(d, i, "startDate")}
                  format="DD/MM/YYYY"
                />
                <DatePicker
                  label="End Date"
                  value={!t.endDate ? null : dayjs(t.endDate, "DD/MM/YYYY")}
                  onChange={(d) => onChangeDate(d, i, "endDate")}
                  format="DD/MM/YYYY"
                />
                <Button onClick={() => onDelete(i)} colour={"error"}>
                  Delete
                </Button>
              </>
            );
          })}
        </div>

        <Stack direction="row" justifyContent={"end"}>
          <Button size="small" onClick={onAdd}>
            Add
          </Button>
        </Stack>
      </AnimationContainer>
      <br />
      <NewTariffActions
        canBack={true}
        canNext={canNext}
        onBack={() => setPage("details")}
        onNext={() => setPage("custom")}
      />
    </div>
  );
};

export default CustomPeriodSelector;
