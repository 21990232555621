import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  navItem: {
    all: "unset",
    display: "flex",
    padding: 10,
    cursor: "pointer",
    color: theme.primary.textColor,
    opacity: 0.5,
    alignItems: "center",

    "&:hover": {
      opacity: 1,
    },
  },

  navItemCurrent: {
    opacity: 1,
  },

  icon: {
    marginRight: 7,
    height: 35,
    width: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  iconCurrent: {
    marginRight: 7,
    backgroundColor: theme.nav.iconSelectedBackgroundMobile,
    color: theme.nav.iconSelectedTextMobile,
    borderRadius: 100,
  },
}));

export default useStyles;
