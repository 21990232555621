import { create } from "zustand";
import {
  AdditionalChargeProps,
  TariffPage,
  TariffRateProps,
  TariffType,
} from "./types";
import client from "../../../../../utils/client";
import { TZ_OFFSET } from "../../../../../utils/constant";
import useAuthStore from "../../../../../hooks/use-auth";

interface NewTariffStore {
  open: boolean;
  page: TariffPage;
  type: TariffType;

  tariffRef: string;
  tariffName: string;
  tariffDesc: string;
  tariffMarketAreaId: string;
  tariffSupplierId: string;
  tariffUseDaylightSavings: string;
  tariffType: string;
  tariffRates: TariffRateProps[];
  currency: string;

  marketAreas: any[];
  suppliers: any[];
  baseCharges: any[];
  pricingUnits: any[];

  customRateIndex: number;
  pageIndex: number;
  pageSize: number;

  setPage: (page: TariffPage) => void;
  onOpen: () => void;
  onClose: () => void;
}

const useNewTariffStore = create<NewTariffStore>((set) => ({
  open: false,
  type: "",
  page: "type",
  tariffRef: "",
  tariffName: "",
  tariffDesc: "",
  tariffMarketAreaId: "",
  tariffSupplierId: "",
  tariffUseDaylightSavings: "",
  tariffType: "",
  tariffRates: [],
  currency: "£",

  marketAreas: [],
  suppliers: [],
  baseCharges: [],
  pricingUnits: [],

  customRateIndex: 0,
  pageSize: 10,
  pageIndex: 0,

  onOpen: async () => {
    set({
      open: true,
    });

    const auth = useAuthStore.getState();
    const { userId } = auth.currentUser;
    const supplierRes = await client.get(
      `/suppliers/getByUserId/${userId}/${TZ_OFFSET}`
    );

    const marketAreaRes = await client.get(`/marketAreas/getAll/${TZ_OFFSET}`);

    const baseChargesRes = await client.get(
      `/tariffBaseCharge/getBaseChargesByUserId/${userId}/${TZ_OFFSET}`
    );

    const pricingUnitRes = await client.get(`/chargePricingUnit/getAll`);

    set({
      marketAreas: marketAreaRes.data.response,
      suppliers: supplierRes.data.response,
      baseCharges: baseChargesRes.data.baseChargeDtos,
      pricingUnits: pricingUnitRes.data.tariffChargePricingUnitDtos,
    });
  },

  setPage: (page) => {
    set({ page });
  },

  onClose: () => {
    set({
      open: false,
      type: "",
      page: "type",
      tariffRef: "",
      tariffName: "",
      tariffDesc: "",
      tariffMarketAreaId: "",
      tariffSupplierId: "",
      tariffUseDaylightSavings: "",
      tariffType: "",
      tariffRates: [],
      marketAreas: [],
      suppliers: [],
      baseCharges: [],
      pricingUnits: [],
    });
  },
}));

export default useNewTariffStore;
