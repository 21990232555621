import { useEffect } from "react";
import Notification from "../../core/notification";
import { PageType, useNavigate } from "../../hooks/use-navigate";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const NotificationsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { goTo, setPage } = useNavigate();

  useEffect(() => {
    setPage(PageType.Notifications);
  }, []);

  return (
    <div>
      <h2>{t("Notifications")}</h2>
      <div className={classes.noNotification}>{t("No_notifications")}</div>
    </div>
  );

  return (
    <div>
      <h2>{t("Notifications")}</h2>
      <h5>{t("Current")}</h5>
      <Notification
        id={"123"}
        date={"Mar 25 - 16.21"}
        description={t("Battery_has_failed_to_charge")}
        actions={[
          {
            name: t("View_Battery"),
            onClick: () => goTo("/asset/battery/3757"),
          },
        ]}
        canComplete={true}
      />

      <Notification
        id={"123"}
        date={"Mar 24 - 14.30"}
        description={t("Production_dipped")}
        actions={[
          { name: t("View_Site"), onClick: () => goTo("/sites/45376") },
        ]}
        canComplete={true}
      />

      <h5>{t("Historic")}</h5>
      <Notification
        id={"123"}
        date={"Mar 25 - 16.21"}
        description={t("Battery_has_failed_to_charge")}
        actions={[
          {
            name: t("View_Battery"),
            onClick: () => goTo("/asset/battery/3757"),
          },
        ]}
        canComplete={false}
      />

      <Notification
        id={"123"}
        date={"Mar 23 - 14.30"}
        description={t("Production_dipped")}
        actions={[
          { name: t("View_Site"), onClick: () => goTo("/sites/45376") },
        ]}
        canComplete={false}
      />

      <Notification
        id={"123"}
        date={"Mar 22 - 14.30"}
        description={t("Production_dipped")}
        actions={[
          { name: t("View_Site"), onClick: () => goTo("/sites/45376") },
        ]}
        canComplete={false}
      />

      <Notification
        id={"123"}
        date={"Mar 21 - 08.12"}
        description={t("Consumption_spike")}
        actions={[
          {
            name: t("View_Consumption"),
            onClick: () => goTo("/asset/consumption/57"),
          },
        ]}
        canComplete={false}
      />

      <Notification
        id={"123"}
        date={"Mar 12 - 14.30"}
        description={t("Production_dipped")}
        actions={[
          { name: t("View_Site"), onClick: () => goTo("/sites/45376") },
        ]}
        canComplete={false}
      />
    </div>
  );
};

export default NotificationsPage;
