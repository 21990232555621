import { useTranslation } from "react-i18next";
import HelpContact from "./contact";
import HelpFaqs from "./faqs";

const HelpPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t("Help")}</h1>
      <div>{t("Help_text")}</div>
      <br />
      <HelpFaqs />
      <br />
      <HelpContact />
    </div>
  );
};

export default HelpPage;
