import { useState } from "react";
import useStyles from "./styles";
import DatePicker from "react-datepicker";
import { DateRange } from "../../../../utils/types";
import Modal from "../../../modal";
import Button from "../../../button";
import moment from "moment";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeDialog: () => void;
  dateRange: DateRange;
  setCustomType: () => void;
  onDateChange: (dateRange: DateRange) => void;
}

const CustomDialog = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, closeDialog, dateRange, onDateChange, setCustomType } = props;
  const [localDateRange, setLocalDateRange] = useState(dateRange);
  const { startDateTime, endDateTime } = localDateRange;

  const handleDateChange = (
    key: "startDateTime" | "endDateTime",
    value: Date
  ) => {
    const dr = { ...localDateRange, [key]: value.toISOString() };
    setLocalDateRange(dr);
  };

  const onClose = () => {
    setLocalDateRange(dateRange);
    closeDialog();
  };

  const onSubmit = () => {
    const sdt = moment(localDateRange.startDateTime)
      .startOf("day")
      .toISOString();
    const edt = moment(localDateRange.endDateTime).endOf("day").toISOString();

    const dr = { startDateTime: sdt, endDateTime: edt };
    setLocalDateRange(dr);

    onDateChange(dr);
    setCustomType();
    closeDialog();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <h3>{t("Select_date_range")}</h3>
        <div className={classes.break}/>
        <div className={classes.dates}>
          <div>
            <h5>{t("From")}</h5>
            <DatePicker
              locale={t("locale")}
              minDate={undefined}
              maxDate={new Date(endDateTime)}
              selected={new Date(startDateTime)}
              dateFormat={t("date_format_lc")}
              onChange={(date: Date) => handleDateChange("startDateTime", date)}
              className={classes.date}
            />
          </div>
          <div>
            <h5>{t("To")}</h5>
            <DatePicker
              locale={t("locale")}
              minDate={new Date(startDateTime)}
              maxDate={new Date()}
              selected={new Date(endDateTime)}
              dateFormat={t("date_format_lc")}
              onChange={(date: Date) => handleDateChange("endDateTime", date)}
              className={classes.date}
            />
          </div>
        </div>
        <div className={classes.break}/>
        <div className={classes.buttonContainer}>
          <Stack direction={"row"} spacing={1}>
            <Button name={t("Cancel")} onClick={onClose}/>
            <Button name={t("Apply")} onClick={onSubmit}/>
          </Stack>
        </div>
      </div>
    </Modal>
  );
};

export default CustomDialog;
