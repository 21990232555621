import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  notification: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.primary.primary1,
    maxWidth: 1000,
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
    borderRadius: 105,
  },

  date: {
    fontWeight: 600,
    fontSize: 14,
    paddingRight: 20,
  },

  details: {
    flexGrow: 1,
  },
}));

export default useStyles;
