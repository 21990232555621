import { useState } from "react";
import Button from "../../../../../../core/button";
import NewTariffActions from "../actions";
import useNewTariffStore from "../new-tariff-store";
import { TariffPage } from "../types";
import AddCharge from "./add";
import useStyles from "./styles";
import TariffCharge from "./charge";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const TariffCharges = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setPage, type, tariffRates } = useNewTariffStore();
  const [isAddMode, setIsAddMode] = useState(false);

  const { additionalCharges } = tariffRates[0];

  const onBack = () => {
    let backPage: TariffPage | null = null;

    switch (type) {
      case "flatRate":
        backPage = "flatRate";
        break;

      case "eco7":
        backPage = "eco7";
        break;

      case "custom":
        backPage = "custom";
        break;

      case "variableRate":
        backPage = "variableRate";
        break;
    }

    setPage(backPage!);
  };

  if (isAddMode) {
    return <AddCharge setIsAddMode={setIsAddMode} />;
  }

  return (
    <div>
      <div>{t("Add_passthrough_charges")}</div>

      {additionalCharges!.length == 0 ? (
        <div className={classes.noCharges}>{t("No_charges")}</div>
      ) : (
        additionalCharges!.map((charge, i) => (
          <TariffCharge charge={charge} chargeIndex={i} />
        ))
      )}
      <Stack direction={"row"} justifyContent={"end"}>
        <Button onClick={() => setIsAddMode(true)} variant={"outlined"}>
          {t("Add_Charge")}
        </Button>
      </Stack>
      <NewTariffActions
        canBack={true}
        canNext={true}
        onBack={onBack}
        onNext={() => setPage("overview")}
      />
    </div>
  );
};

export default TariffCharges;
