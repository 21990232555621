import React from "react";
import { IonCol, IonIcon, IonLabel, IonSegmentButton } from "@ionic/react";
import { useTheme } from "react-jss";

interface FooterItemProps {
  isActive: boolean;
  icon: string;
  label: string;
  testId: string;
  onClick: () => void;
}

const FooterItem = (props: FooterItemProps) => {
  const theme: any = useTheme();

  return (
    <IonCol>
      <IonSegmentButton
        value="default"
        onClick={() => props.onClick()}
        className={props.isActive ? "active-tab" : undefined}
        data-testid={props.testId}
        style={{
          color: props.isActive
            ? theme.primary.primary5
            : theme.primary.textColor2,
        }}
      >
        <IonIcon icon={props.icon} size="small"></IonIcon>
        <IonLabel>{props.label}</IonLabel>
      </IonSegmentButton>
    </IonCol>
  );
};

export default FooterItem;
