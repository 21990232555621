import { t } from "i18next";
import Input from "../../../../../../core/input";
import useNewTariffStore from "../new-tariff-store";
import NewTariffActions from "../actions";
import TimePicker from "../../../../../../core/time-picker";
import { Stack } from "@mui/material";

const Eco7 = () => {
  const { page, setPage, tariffRates, currency } = useNewTariffStore();

  const dayRate = tariffRates[0].ratePeriods[0];
  const nightRate = tariffRates[0].ratePeriods[1];

  const onChange = (
    value: any,
    field: "unitPrice" | "periodName" | "startTime" | "endTime",
    ratePeriodIndex: number
  ) => {
    const newTariffRates = [...tariffRates] as any;
    newTariffRates[0].ratePeriods[ratePeriodIndex][field] = value;
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  const canNext = true;

  return (
    <div>
      <h5>Day rate</h5>
      <Stack direction={"row"} justifyContent={"space-between"} marginTop={1}>
        <Input
          label={t("Name")}
          value={dayRate.periodName}
          onChange={(name) => onChange(name, "periodName", 0)}
        />
        <Input
          label={t("Unit_Cost")}
          value={dayRate.unitPrice}
          onChange={(name) => onChange(name, "unitPrice", 0)}
          prefix={currency}
        />
        <TimePicker
          label="Start Time"
          time={dayRate.startTime}
          setTime={(time) => onChange(time, "startTime", 0)}
        />

        <TimePicker
          label="End Time"
          time={dayRate.endTime}
          setTime={(time) => onChange(time, "endTime", 0)}
        />
      </Stack>

      <br />

      <h5>Night rate</h5>

      <Stack direction={"row"} justifyContent={"space-between"} marginTop={1}>
        <Input
          label={t("Name")}
          value={nightRate.periodName}
          onChange={(name) => onChange(name, "periodName", 1)}
        />
        <Input
          label={t("Unit_Cost")}
          value={nightRate.unitPrice}
          onChange={(name) => onChange(name, "unitPrice", 1)}
          prefix={currency}
        />
        <TimePicker
          label="Start Time"
          time={nightRate.startTime}
          setTime={(time) => onChange(time, "startTime", 1)}
        />

        <TimePicker
          label="End Time"
          time={nightRate.endTime}
          setTime={(time) => onChange(time, "endTime", 1)}
        />
      </Stack>

      <NewTariffActions
        canBack={true}
        canNext={canNext}
        onBack={() => setPage("dates")}
        onNext={() => setPage("charges")}
      />
    </div>
  );
};

export default Eco7;
