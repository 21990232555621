import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LineChart from "../../../core/chart/line";
import DayWeekMonthDateSelector from "../../../core/period-selector/day-week-month";
import useAuthStore from "../../../hooks/use-auth";
import client from "../../../utils/client";
import Colours from "../../../utils/colours";
import { TZ_OFFSET } from "../../../utils/constant";
import { DateRange, MWDDateRange } from "../../../utils/types";
import useStyles from "./styles";
import { enqueueSnackbar } from "notistack";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import ConsumptionDetails from "./consumption-details";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import Box from "../../../core/box";
import useSitesStore from "../../../hooks/use-sites";

const ProductionPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { assetId } = useParams();
  const [dateType, setDateType] = useState(MWDDateRange.Day);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().startOf("day").toISOString(),
    endDateTime: moment().endOf("day").toISOString(),
  });
  const { currentUser } = useAuthStore();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [curCons, setCurCons] = useState<any>({});

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    return getChartOptions({
      scales: {
        x: {
          type: "time",
          time: {
            minUnit,
            tooltipFormat: "DD/MM HH:mm",
          },
        },
        y: {
          ticks: {
            callback: (value: any) => {
              return value + " " + t("suffix_kWh");
            },
            precision: 0,
          },
        },
      },

      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const { label } = tooltipItem.dataset;
              const { formattedValue } = tooltipItem;
              return `${label}: ${formattedValue} ${t("suffix_kWh")}`;
            },
          },
        },
      },
    });
  }, [dateRange]);

  const loadData = async (dr: typeof dateRange) => {
    try {
      setLoading(true);
      const res = await client.post("/kdbGateway/assetMetrics/getConsumption", {
        sym: assetId,
        userId: currentUser.userId,
        startDate: moment(dr.startDateTime).format(
          t("date_time_format_seconds")
        ),
        endDate: moment(dr.endDateTime).format(t("date_time_format_seconds")),
        tzOffset: TZ_OFFSET,
      });

      const consRes = res.data.response;

      const conData = consRes.data.map((x: any) => ({
        x: x["time"],
        y: x["consumptionW"] / 1000,
      }));

      const d = {
        datasets: [
          {
            label: t("Total_Consumed"),
            data: conData,
            borderColor: Colours.blue5,
            backgroundColor: Colours.light_blue5,
            borderWidth: 1,
          },
        ],
      };
      const assetSumRes = await client.post(
        "/kdbGateway/assetMetrics/assetsSummaryMetrics",
        { siteId: consRes.siteId, tzOffset: TZ_OFFSET }
      );

      const { consumptionSummaryMetrics } = assetSumRes.data.response;
      const curCons = consumptionSummaryMetrics[0];

      useSitesStore.setState({ siteId: consRes.siteId });
      setCurCons(curCons);
      setData(d);
      setLoading(false);
      setInitialLoading(false);
    } catch {
      enqueueSnackbar(t("Error_load_cp_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    loadData(dateRange);
  }, [dateRange]);

  if (initialLoading) {
    return <Loader />;
  }

  return (
    <div>
      <ConsumptionDetails
        currentConsumption={curCons.currentConsumptionKw.toFixed(2)}
        weeklyConsumption={curCons.weekTotalConsumptionKwh.toFixed(2)}
      />
      <DayWeekMonthDateSelector
        dateType={dateType}
        setType={setDateType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Energy_Consumed")}
      />
      <Box>
        <div className={classes.chart}>
          <LineChart
            options={options}
            loading={loading}
            data={data}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName={"consumption"}
          />
        </div>
      </Box>
    </div>
  );
};

export default ProductionPage;
