import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    opacity: 0,
    animation: "$fade .75s ease-in both",
  },

  "@keyframes fade": {
    from: {
      opacity: 0,
      transform: "translate3d(0, -1%, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  },
});

export default useStyles;
