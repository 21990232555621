import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    all: "unset",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: theme.primary.primary5,
    fontWeight: 700,
    fontSize: 15,
    paddingRight: 10
  },

  text: {
    marginLeft: 5,
  },
}));

export default useStyles;
