import moment from "moment";
import NewTariffActions from "../../actions";
import useNewTariffStore from "../../new-tariff-store";

const CustomRateActions = () => {
  const { customRateIndex, setPage, tariffRates, pricingUnits } =
    useNewTariffStore();
  const currentRate = tariffRates[customRateIndex];
  const { additionalCharges, ratePeriods, startDate, endDate } = currentRate;

  const chargesValid = additionalCharges!.every((charge) => {
    const {
      fkBaseChargeId,
      chargeName,
      amount,
      fkPricingUnitId,
      startTime,
      endTime,
    } = charge;

    const selectedPricingUnit =
      pricingUnits.find((pu) => pu.pricingUnitId == fkPricingUnitId) || {};
    const { pricingUnit } = selectedPricingUnit;

    return (
      (fkBaseChargeId != null || chargeName != null || chargeName) &&
      amount > 0 &&
      fkPricingUnitId != undefined &&
      (pricingUnit == "per kWh"
        ? moment(startTime, "HH:mm", true).isValid() &&
          moment(endTime, "HH:mm", true).isValid()
        : true)
    );
  });

  const ratePeriodsValid = ratePeriods.every((rp) => {
    return (
      rp.periodName != "" &&
      rp.unitPrice > 0 &&
      rp.direction != null &&
      moment(rp.startTime, "HH:mm", true).isValid() &&
      moment(rp.endTime, "HH:mm", true).isValid()
    );
  });

  const canNext = ratePeriodsValid && chargesValid && ratePeriods.length > 0;

  const onBack = () => {
    if (customRateIndex == 0) {
      setPage("dates");
    } else {
      let newRateIndex = customRateIndex - 1;
      useNewTariffStore.setState({ customRateIndex: newRateIndex });
    }
  };

  const onNext = () => {
    if (customRateIndex == tariffRates.length - 1) {
      setPage("overview");
    } else {
      let newRateIndex = customRateIndex + 1;
      useNewTariffStore.setState({ customRateIndex: newRateIndex });
    }
  };

  return (
    <NewTariffActions
      canBack={true}
      canNext={canNext}
      onBack={onBack}
      onNext={onNext}
    />
  );
};

export default CustomRateActions;
