import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  navBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
  },

  logo: {
    padding: 0,
    paddingBottom: 0,
    cursor: "pointer",
    borderRadius: 7,
    width: 100,
    height: "auto",
  },

  menu: {
    all: "unset",
    cursor: "pointer",
    marginTop: 3,
  },

  close: {
    all: "unset",
    bottom: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    padding: 10,
    cursor: "pointer",
    fontWeight: 600,
    color: theme.primary.primary5,
  },

  icon: {
    marginRight: 7,
    marginTop: 5,
  },

  switcher: {
    margin: 15,
  },

  hamburger: {
    color: theme.primary.primary4,
  },

  slideDialog: {
    backgroundColor: theme.primary.boxBackground,
  },

  title: {
    color: theme.primary.textColor,
  },
}));

export default useStyles;
