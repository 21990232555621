import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto min-content",
    columnGap: 20,
    rowGap: 10,
    marginTop: 20,
  },
});

export default useStyles;
