export enum Domain {
  TESC,
  SNXT,
}

export const getDomain = () => {
  switch (window.location.hostname) {
    case "parisinsights.tesc.io":
    case "parisinsights.dev.tesc.app":
    case "parisinsights.uat.tesc.app":
      return Domain.TESC;

    case "snxcloud.de":
    case "dev.snxcloud.de":
    case "uat.snxcloud.de":
      return Domain.SNXT;

    default:
      return Domain.TESC;
  }
};
