import { createUseStyles } from "react-jss";
import Colours from "../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    padding: 15,
  },

  dates: {
    display: "flex",
    justifyContent: "space-between",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },

  date: {
    padding: 13,
    backgroundColor: theme.primary.primary1,
    border: "1px solid " + theme.grey.grey5,
    borderRadius: 5,
  },

  break: {
    borderTop: "1px solid " + theme.grey.grey3,
    marginTop: 30,
    marginBottom: 30,
  },
}));

export default useStyles;
