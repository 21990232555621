import { useNavigate } from "../../../../hooks/use-navigate";
import { Domain, getDomain } from "../../../../utils/get-domain";
import useStyles from "./styles";

const DesktopLogo = () => {
  const classes = useStyles();
  const domain = getDomain();
  const { goTo } = useNavigate();

  switch (domain) {
    case Domain.SNXT:
      return (
        <img
          src={"/images/Logo_SonneNext.webp"}
          className={classes.snextLogo}
          onClick={() => goTo("/site/overview")}
          data-testid="navMenuLogo"
          alt={"SonneNEXT Logo"}
        />
      );

    default:
      return (
        <img
          src={"/images/plus.svg"}
          className={classes.joulenLogo}
          onClick={() => goTo("/site/overview")}
          data-testid="navMenuLogo"
          alt={"Joulen Logo"}
        />
      );
  }
};

export default DesktopLogo;
