import { createTheme } from "@mui/material";

const tescTheme = {
  nav: {
    backgroundColor: "#00e85d",
    borderRight: "#00e85d",
    hoverTextColour: "#20212e",
    defaultText: "#6a6d73",
    currentTextColour: "#20212e",

    iconSelectedBackground: "#10111c",
    iconSelectedText: "#00e85d",
    iconSelectedBackgroundMobile: "#00e85d",
    iconSelectedTextMobile: "#10111c",
  },
  primary: {
    backgroundColor: "#10111c",
    boxBackground: "#20212e",
    textColor: "white",
    textColor2: "#9e9da1",
    textColor3: "#6a6d73",
    buttonBackgroundColor: "#00e85d",
    buttonTextColor: "#20212e",

    tariffColor1: "#00e85d",
    tariffColor2: "#009149",
    tariffColor3: "#fc2be7",
    tariffColor4: "#cf0ebf",
    tariffColor5: "#fca800",
    tariffColor6: "#ba7c00",

    // old
    primary1: "#e3eeff",
    primary2: "#7FA0FF",
    primary3: "#c2fccc",
    primary4: "#56f583",
    primary5: "#00e85d",
    primary6: "#009999",
  },
  secondary: {
    main: "#e3eeff",
  },
  grey: {
    grey1: "#ebf0f5",
    grey2: "#c7c7c7",
    grey3: "#a4a4a4",
    grey5: "#56649a",
    grey6: "#848A9C",
    grey7: "#898989",
  },
};

const tescMuiTheme = createTheme({
  palette: {
    primary: {
      main: "#00e85d",
    },

    action: {
      disabledBackground: "#898989",
      disabled: "#20212e",
    },
  },
});

export { tescTheme, tescMuiTheme };
