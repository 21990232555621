import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  box: {
    backgroundColor: theme.primary.boxBackground,
    padding: "20px",
    borderRadius: 15,
    overflow: "hidden",
  },
}));

export default useStyles;
