import { createUseStyles } from "react-jss";
import Colours from "../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },

  dp: {
    width: "20px",
    padding: 7,
    borderRadius: 4,
    border: "1px solid grey",

    "&::placeholder": {
      fontSize: 11,
    },

    "&:focus": {
      outline: "1px solid " + theme.primary.primary5,
      border: "1px solid " + theme.primary.primary5,
    },
  },

  divider: {
    fontSize: 15,
    color: theme.grey.grey2,
    paddingLeft: 7,
    paddingRight: 7,
    fontWeight: 300,
  },
}));

export default useStyles;
