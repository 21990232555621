import NewTariffActions from "../../actions";
import moment from "moment";
import useNewTariffStore from "../../new-tariff-store";
import useAuthStore from "../../../../../../../hooks/use-auth";
import client from "../../../../../../../utils/client";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const TariffOverviewActions = () => {
  const {
    tariffName,
    tariffDesc,
    tariffSupplierId,
    setPage,
    tariffRef,
    tariffUseDaylightSavings,
    tariffMarketAreaId,
    tariffRates,
    onClose,
    type,
  } = useNewTariffStore();
  const auth = useAuthStore.getState();
  const { t } = useTranslation();
  const { parentOrganisationId, fkCustomerId } = auth.currentUser;

  const onSubmit = async () => {
    const req = {
      tariffRef,
      tariffName,
      tariffDesc,
      baseTZ: 0,
      daylightSavings: tariffUseDaylightSavings == "yes",
      fkMarketAreaId: tariffMarketAreaId,
      fkSupplierId: tariffSupplierId,
      fkOrganisationId: parentOrganisationId,
      tariffRates: tariffRates.map((rate) => {
        return {
          startDate: moment(rate.startDate, t("date_format")).format(
            t("date_time_format_seconds2")
          ),
          endDate:
            rate.endDate == null || rate.endDate == ""
              ? null
              : moment(rate.endDate, t("date_format")).format(
                  t("date_time_format_seconds2")
                ),

          ratePeriods: rate.ratePeriods.map((rp) => {
            return {
              ...rp,
              unitPriceKwh: rp.unitPrice,
              startTime: moment(rp.startTime, "HH:mm").format("HH:mm:ss"),
              endTime: moment(rp.endTime, "HH:mm").format("HH:mm:ss"),
            };
          }),
          rateCharges: rate.additionalCharges!.map((charge) => {
            const startTime =
              charge.startTime == "" ? "00:00" : charge.startTime;
            const endTime = charge.endTime == "" ? "00:00" : charge.endTime;

            return {
              ...charge,
              unitPrice: charge.amount,
              startTime: moment(startTime, t("time_format")).format(
                t("time_format_s")
              ),
              endTime: moment(endTime, t("time_format")).format(
                t("time_format_s")
              ),
            };
          }),
        };
      }),
    };

    try {
      await client.post("/resolvedTariff/add", req);

      enqueueSnackbar(t("Tariff_successfully_added"), {
        variant: "success",
        persist: false,
      });

      onClose();
    } catch {
      enqueueSnackbar(t("Error_adding_tariff"), {
        variant: "error",
        persist: false,
      });
    }
  };

  return (
    <NewTariffActions
      canBack
      canNext
      onBack={() => setPage(type == "custom" ? "custom" : "charges")}
      onNext={onSubmit}
      isSubmit
    />
  );
};

export default TariffOverviewActions;
