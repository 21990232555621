import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  tariffContainer: {
    display: "grid",
    gridTemplateColumns: "150px 150px 220px",
    backgroundColor: theme.primary.primary1,
    width: "min-content",
    padding: 5,
    marginTop: 10,
  },

  actions: {
    justifySelf: "end",
    alignSelf: "center",
  },
}));

export default useStyles;
