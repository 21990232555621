const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  pointRadius: 0,
  pointHoverRadius: 7,

  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        pointStyle: 'circle',
        usePointStyle: true
      }
    },
    title: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  ticks: {
    autoSkip: true,
  },
  elements: {
    line: {
      tension: 0,
      fill: 'start'
    },
  }
} as any;

export default chartOptions;
