import {create} from "zustand";
import client from "../utils/client";
import {TZ_OFFSET} from "../utils/constant";
import {firebaseAuth} from "../utils/firebase";
import {enqueueSnackbar} from "notistack";
import {User} from "firebase/auth";

interface AuthStore {
  initialLoading: boolean;
  authLoading: boolean;
  isAuthenticated: boolean;
  currentUser: any;
  authError: string;
  verificationId: null | string;
  resolver: any;
  manualAuth: boolean;

  checkAuthTimeout: () => void;
  logout: () => void;
  login: (user: any) => void;
  onLogin: (
    fbUser: User,
    verificationSent: string,
    runTimeout?: boolean
  ) => void;
  setError: (error: string) => void;
  goTo: (route: string) => void;
}

const useAuthStore = create<AuthStore>((set, get) => ({
  initialLoading: true,
  authLoading: false,
  isAuthenticated: false,
  currentUser: null,
  authError: "",
  verificationId: null,
  resolver: null,
  manualAuth: false,

  login: (user: any) =>
    set({
      isAuthenticated: true,
      currentUser: user,
      initialLoading: false,
      authLoading: false,
    }),

  checkAuthTimeout: () => {
    if (!window.checkAuthTimeout) {
      window.checkAuthTimeout = setTimeout(() => {
        get().logout();
      }, 3600 * 1000);
    }
  },

  logout: async () => {
    window.clearInterval(window.authStateCheckInterval as any);
    window.authStateCheckInterval = undefined;
    window.checkAuthTimeout = undefined;
    await firebaseAuth.signOut();
    set({
      isAuthenticated: false,
      currentUser: null,
      initialLoading: false,
      authLoading: false,
      verificationId: null,
    });
  },

  onLogin: async (
    fbUser: User,
    verificationSent: string,
    runTimeout?: boolean
  ) => {
    set({authLoading: true});
    try {
      const res = await client.post(
        "/appUsers/getByEmail/" + TZ_OFFSET,
        fbUser.email,
        {
          headers: {"Content-Type": "text/plain"},
        }
      );

      const user = res.data.response;
      const isEmailVerified = fbUser.emailVerified;

      if (user == "User does not exist") {
        set({
          authError: "User_not_found",
          authLoading: false,
          currentUser: null,
          initialLoading: false,
        });
      } else if (!isEmailVerified) {
        const req = {
          continueUrl: process.env.REACT_APP_EMAIL_VERIFICATION_CONTINUEURL,
          email: fbUser.email,
          firstName: user.firstName ? user.firstName : fbUser.email,
        };
        get().logout();
        const lang = navigator.language.split("-");
        await client.post("/users/sendVerificationEmail", req, {
          headers: {"Accept-Language": lang},
        });
        enqueueSnackbar(verificationSent, {
          variant: "info",
          persist: false,
        });
        set({authError: "Problem_signing_in"});
      } else {
        const rolesRes = await client.get(
          "/appRoles/getByUserId/" + user.userId
        );
        const roles = rolesRes.data.response;
        user.roles = roles;
        const roleNames = roles.filter((r: any) => r.roleName == "admin");
        user.isAdmin = roleNames.length > 0 || user.system === "admin";
        // todo: do we need to update sonnen battery
        await client.put("/users/updateLastLogin/" + user.userId);

        if (runTimeout) {
          get().checkAuthTimeout();
        }
        get().login(user);
      }
    } catch (error) {
      if (!get().initialLoading) {
        set({authError: "Problem_signing_in"});
      }
      get().logout();
    }
  },

  setError: (error) => set({authError: error}),

  goTo: () => null,
}));

export default useAuthStore;
