import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  settingsNav: {
    display: "flex",
    marginTop: 10,
  },

  navItem: {
    all: "unset",
    cursor: "pointer",
    display: "flex",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 15,
    fontWeight: 600,
    color: theme.grey.grey5,
  },

  selected: {
    color: theme.primary.buttonTextColor,
    backgroundColor: theme.primary.buttonBackgroundColor,
    borderRadius: 7,
  },

  icon: {
    marginRight: 5,
  },
}));

export default useStyles;
