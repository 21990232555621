import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "../../../hooks/use-navigate";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import { Domain, getDomain } from "../../../utils/get-domain";

const SettingsNav = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const domain = getDomain();
  const showTariff = domain != Domain.SNXT;

  return (
    <>
      {!isNativeMobile() && (
        <div className={classes.settingsNav}>
          <NavItem name={t("Account")} route={"/account"} />
          {showTariff && <NavItem name={t("Tariffs")} route={"/tariffs"} />}
          {/* <NavItem name={t("Tariff_Charges")} route={"/tariff-charges"} /> */}
          {/* <NavItem name={t("Dashboard")} route={"/dashboard"} />
          <NavItem name={t("Organisation")} route={"/organisation"} /> */}
        </div>
      )}
    </>
  );
};

interface NavProps {
  name: string;
  route: string;
}

const NavItem = (props: NavProps) => {
  const classes = useStyles();
  const { name, route } = props;
  const { pathname } = useLocation();
  const { goTo } = useNavigate();
  const isSelected = pathname.includes(route);
  const buttonStyle = [classes.navItem];
  if (isSelected) {
    buttonStyle.push(classes.selected);
  }
  return (
    <NavLink className={buttonStyle.join(" ")} to={`/settings${route}`}>
      {name}
    </NavLink>
  );
};

export default SettingsNav;
