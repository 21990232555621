import { NavLink, useLocation } from "react-router-dom";
import { HelpIcon, MessageIcon, SettingsIcon } from "../icons";
import TopBarAction from "./action-button";
import TopBarLogo from "./logo";
import useStyles from "./styles";
import useSitesStore from "../../hooks/use-sites";
import useWindowSize from "../../hooks/use-window-size";
import { isNativeMobile } from "../../utils/ionic";

const TopBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const { sites, siteId } = useSitesStore();
  const { width } = useWindowSize();

  if (isNativeMobile()) {
    return <div />;
  }

  const hideSite =
    ["/sites", "/sites/", "/help", "/notifications"].includes(pathname) ||
    /^\/settings(\/.*)?$/.test(pathname);

  let siteName = "";

  if (siteId != "" && siteId != null) {
    const site = sites.find((s) => s.siteId == siteId);
    const useAddress = site.siteName == null || site.siteName.trim() == "";
    siteName = useAddress
      ? site.buildingNumber + " " + site.addressLine1
      : site.siteName;
  }

  if (width < 1000) {
    return (
      <div className={classes.subheader}>
        {!hideSite && (
          <>
            <div>
              {siteName} (#{siteId})
            </div>
            {sites.length > 1 && (
              <NavLink className={classes.change} to={"/sites"}>
                (Change)
              </NavLink>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div>
        <TopBarLogo />

        {!hideSite && (
          <div className={classes.subheader}>
            <div>
              {siteName} (#{siteId})
            </div>
            {sites.length > 1 && (
              <NavLink className={classes.change} to={"/sites"}>
                (Change)
              </NavLink>
            )}
          </div>
        )}
      </div>
      <div className={classes.actions}>
        <TopBarAction
          icon={<HelpIcon width={20} height={20} />}
          route={"/help"}
        />
        <TopBarAction
          icon={<MessageIcon width={20} height={20} />}
          route={"/notifications"}
        />
        <TopBarAction
          icon={<SettingsIcon width={20} height={20} />}
          route={"/settings"}
        />
      </div>
    </div>
  );
};

export default TopBar;
