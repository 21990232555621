import { ICellTextProps } from "ka-table/props";
import DataGrid from "../../../../core/grid";
import useWindowSize from "../../../../hooks/use-window-size";
import { columns, mobileColumns } from "./grid-def";
import useStyles from "./styles";
import { useNavigate } from "../../../../hooks/use-navigate";
import { useEffect, useState } from "react";
import client from "../../../../utils/client";
import { TZ_OFFSET } from "../../../../utils/constant";
import Loader from "../../../../core/loader";
import Button from "../../../../core/button";
import useAuthStore from "../../../../hooks/use-auth";
import NewTariffModal from "./new-tariff";
import { Stack } from "@mui/material";
import useNewTariffStore from "./new-tariff/new-tariff-store";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const CustomerTariffs = () => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const cols =
    width < 700
      ? mobileColumns.map((col) => {
          return {
            ...col,
            title: t(`${col.title}`),
          };
        })
      : columns.map((col) => {
          return {
            ...col,
            title: t(`${col.title}`),
          };
        });
  const [tariffs, setTariffs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuthStore();
  const onOpen = useNewTariffStore((s) => s.onOpen);
  const { pageIndex, pageSize } = useNewTariffStore();
  const { goTo } = useNavigate();

  const updatePageIndex = (pageIndex: number) => {
    useNewTariffStore.setState({ pageIndex });
  };
  const updatePageSize = (pageSize: number) => {
    useNewTariffStore.setState({ pageSize });
  };

  useEffect(() => {
    const load = async () => {
      //todo: make this custom tariffs
      const res = await client.get(
        `/tariffs/getByUserId/${currentUser.userId}/${TZ_OFFSET}`
      );

      setTariffs(res.data.response);
      setLoading(false);
    };
    load();
  }, []);

  if (loading) return <Loader />;

  const getChildComponent = (props: ICellTextProps) => {
    if (props.column.key === "tariffId") {
      return (
        <NavLink
          className={classes.id}
          to={`/settings/tariffs/${props.rowData.tariffId}/overview`}
        >
          #{props.rowData.tariffId}
        </NavLink>
      );
    }
  };

  return (
    <div>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          name={t("Add_Custom_Tariff")}
          onClick={onOpen}
          variant={"outlined"}
          startIcon={<AddIcon />}
        />
      </Stack>
      <DataGrid
        data={tariffs}
        rowKeyField={"tariffId"}
        columns={cols}
        getChildComponent={getChildComponent}
        updatePageIndex={updatePageIndex}
        updatePageSize={updatePageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onRowClick={(row) => {
          goTo(`/settings/tariffs/${row.tariffId}/overview`);
        }}
      />
      <NewTariffModal />
    </div>
  );
};

export default CustomerTariffs;
