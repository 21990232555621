const Colours = {
  grey1: "#ebf0f5",
  grey2: "#c7c7c7",
  grey3: "#a4a4a4",
  grey5: "#56649a",
  grey6: '#848A9C',
  grey7: '#898989',

  // original blue
  blue1: "#e3eeff",
  blue2: '#7FA0FF',
  blue3: "#75B6AC",
  blue4: "#006666",
  blue5: "#1559e5",
  blue6: "#009999",

  light_blue5: "rgba(21,89,229,0.2)",

  //Green
  green1: "#88BF52",


  // green marketing pack
  // blue1: "#ecffdc",
  // blue5: "#7DC241",

  // alt green
  // blue1: "#d3ffd3",
  // blue5: "#006400",

  //bright blue
  // blue1: "#e0f8ff",
  // blue5: "#4DB5D1",

  //deep blue
  // blue1: "#e0f8ff",
  // blue5: "#1185A7",
};

export default Colours;
