import {
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "../../../hooks/use-navigate";
import { useTranslation } from "react-i18next";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import client from "../../../utils/client";
import useAuthStore from "../../../hooks/use-auth";
import { Keyboard } from "@capacitor/keyboard";
import { isNativeMobile } from "../../../utils/ionic";
import useStyles from "./styles";
import AuthContainer from "../container";
import Input from "../../../core/input";
import Button from "../../../core/button";

const PasswordLogin = () => {
  const classes = useStyles();
  const { goTo } = useNavigate();
  const { t } = useTranslation();
  const auth = getAuth();
  const { onLogin } = useAuthStore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showFooter, setShowFooter] = useState(true);

  function handleKeyDown(event: { key: string }) {
    if (event.key === "Enter") {
      void onSubmit();
    }
  }

  const onSubmit = async () => {
    try {
      const ath = await signInWithEmailAndPassword(auth, email, password);
      const token = await ath.user?.getIdToken();

      client.defaults.headers.common = {
        Authorization: `${token}`,
      };
      useAuthStore.setState({ manualAuth: true });
      onLogin(ath.user as any, t("Verification_email_sent"));
    } catch (error: any) {
      setErrorMessage(t("Problem_signing_in_with_password"));
    }
  };

  if (isNativeMobile()) {
    Keyboard.addListener("keyboardWillShow", () => {
      setShowFooter(false);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setShowFooter(true);
    });

    return (
      <IonPage>
        <div className="login">
          <IonGrid>
            <IonRow>
              <div className={classes.title}>{t("Welcome_Back")}</div>
            </IonRow>
            <IonRow>
              <IonNote>{t("Email")}</IonNote>
              <Input
                onChange={setEmail}
                value={email}
                autoComplete={"on"}
                style={{ width: "100%" }}
                id="loginEmail"
              />
            </IonRow>
            <IonRow>
              <IonNote>{t("Password")}</IonNote>
              <Input
                value={password}
                onChange={setPassword}
                onKeyDown={handleKeyDown}
                type="password"
                autoComplete={"on"}
                style={{ width: "100%" }}
                id="loginPassword"
              />
            </IonRow>
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              style={{ width: "100%" }}
            >
              {t("Continue")}
            </Button>

            <IonNote color="danger">{errorMessage}</IonNote>
          </IonGrid>
        </div>
        {showFooter && (
          <IonFooter className="ion-no-border">
            <IonSegmentButton
              value="default"
              onClick={() => goTo("/login")}
              className="email-login-link disable-hover"
            >
              <IonLabel color="medium">
                {t("Sign_In_Email_Login_Link")}
              </IonLabel>
            </IonSegmentButton>
          </IonFooter>
        )}
      </IonPage>
    );
  } else {
    return (
      <AuthContainer>
        <form>
          <div className={classes.subtitle}>{t("Login_to_account")}</div>
          <h4>{t("Email")}</h4>
          <Input
            onChange={setEmail}
            value={email}
            autoComplete={"on"}
            style={{ width: "100%" }}
            id="loginEmail"
          />
          <div className={classes.break} />
          <h4>{t("Password")}</h4>
          <Input
            value={password}
            onChange={setPassword}
            onKeyDown={handleKeyDown}
            type="password"
            autoComplete={"on"}
            style={{ width: "100%" }}
            id="loginPassword"
          />
          <div className={classes.error}>{errorMessage}</div>
          <div className={classes.break} />
          <Button
            name={t("Log_in")}
            onClick={onSubmit}
            variant={"contained"}
            id={"loginBtn"}
          />
        </form>
        {showFooter && (
          <div>
            <button
              className={classes.signInEmailLink}
              onClick={() => goTo("/login")}
            >
              <div>{t("Sign_In_Email_Login_Link")}</div>
            </button>
          </div>
        )}
      </AuthContainer>
    );
  }
};

export default PasswordLogin;
