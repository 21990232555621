import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "../../../../../../core/button";
import { useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import client from "../../../../../../utils/client";
import { TZ_OFFSET } from "../../../../../../utils/constant";
import { enqueueSnackbar } from "notistack";
import Input from "../../../../../../core/input";
import Select from "../../../../../../core/select";
import TimePicker from "../../../../../../core/time-picker";
import DaysActive from "../../../../../../core/days-active";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../core/modal";

interface Props {
  open: boolean;
  rateId: number;
  onClose: () => void;
  load: () => void;
  currency: string;
}

const initialState = {
  periodName: "",
  unitPriceKwh: 0,
  direction: "",
  startTime: "",
  endTime: "",
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

const AddRatePeriodDialog = (props: Props) => {
  const { open, rateId, load, currency } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [newRatePeriod, setNewRatePeriod] = useState({ ...initialState });

  const onSubmit = async () => {
    try {
      const { startTime, endTime } = newRatePeriod;

      const req = {
        ...newRatePeriod,
        fkRateId: rateId,
        startTime,
        endTime,
      };

      await client.post(`/tariffRatePeriods/add/${TZ_OFFSET}`, req);

      onClose();
      load();
      enqueueSnackbar(t("Rate_Added"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_adding_rate"), {
        variant: "error",
        persist: false,
      });
    }
  };

  const onClose = () => {
    props.onClose();
    setNewRatePeriod({ ...initialState });
  };

  const onChange = (value: any, field: keyof typeof initialState) => {
    const newDetails = { ...newRatePeriod } as any;
    newDetails[field] = value;

    setNewRatePeriod(newDetails);
  };

  const isValidData =
    newRatePeriod.periodName != "" &&
    newRatePeriod.unitPriceKwh > 0 &&
    newRatePeriod.direction != "" &&
    moment(newRatePeriod.startTime, t("time_format_s"), true).isValid() &&
    moment(newRatePeriod.endTime, t("time_format_s"), true).isValid();

  return (
    <Modal open={open} onClose={onClose}>
      <DialogTitle>{t("Add_New_Rate")}</DialogTitle>
      <DialogContent>
        <div>{t("Enter_details_for_rate")}</div>
        <br />
        <div className={classes.editPeriodGrid}>
          <Input
            label={t("Name")}
            value={newRatePeriod.periodName}
            onChange={(value) => onChange(value, "periodName")}
          />

          <Input
            label={t("Unit_Price")}
            value={newRatePeriod.unitPriceKwh}
            onChange={(value) => onChange(value, "unitPriceKwh")}
            prefix={currency}
          />

          <TimePicker
            label={t("Start_Time")}
            time={newRatePeriod.startTime}
            setTime={(time) => onChange(time + ":00", "startTime")}
          />

          <TimePicker
            label={t("End_Time")}
            time={newRatePeriod.endTime}
            setTime={(time) => onChange(time + ":00", "endTime")}
          />

          <Select
            label={t("Direction")}
            value={newRatePeriod.direction}
            onChange={(direction) => onChange(direction, "direction")}
            options={[
              { label: t("Import"), value: "Import" },
              { label: t("Export"), value: "Export" },
            ]}
            style={{ width: "100%" }}
          />

          <DaysActive
            onChange={onChange}
            monday={newRatePeriod.monday}
            tuesday={newRatePeriod.tuesday}
            wednesday={newRatePeriod.wednesday}
            thursday={newRatePeriod.thursday}
            friday={newRatePeriod.friday}
            saturday={newRatePeriod.saturday}
            sunday={newRatePeriod.sunday}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={onSubmit}
          variant={"contained"}
          disabled={!isValidData}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default AddRatePeriodDialog;
