import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface Props {
  siteData: any;
}

const SiteAddress = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    siteName,
    buildingNumber,
    addressLine1,
    addressLine2,
    postCode,
    town,
  } = props.siteData;
  const siteNameIsNotPopulated =
    siteName?.trim() === "" ||
    !siteName ||
    siteName == `${buildingNumber} ${addressLine1}`;
  const noDataForSiteName =
    siteNameIsNotPopulated && (!buildingNumber || !addressLine1);

  return (
    <div className={classes.solid}>
      <div className={classes.details}>
        <h2
          data-testid={`site-name-title`}
          className={classes.title}
          hidden={noDataForSiteName}
        >
          {siteNameIsNotPopulated
            ? `${buildingNumber} ${addressLine1},`
            : siteName}
        </h2>
        {siteNameIsNotPopulated ? (
          <div
            data-testid={`site-address-first-line`}
            className={classes.subtitle}
            hidden={!addressLine2}
          >
            {addressLine2},
          </div>
        ) : (
          <div
            data-testid={`site-address-first-line`}
            className={classes.subtitle}
            hidden={!buildingNumber && !addressLine1 && !addressLine2}
          >
            {buildingNumber} {addressLine1}, {addressLine2},
          </div>
        )}
        <div
          data-testid={`site-address-second-line`}
          className={classes.subtitle}
          hidden={!town && !postCode}
        >
          {town}, {postCode}
        </div>
      </div>
    </div>
  );
};

export default SiteAddress;
