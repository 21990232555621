import { createUseStyles } from "react-jss";
import Colours from "../../utils/colours";
import { ThemeContext } from "@emotion/react";

const useStyles = createUseStyles((theme: any) => ({
  i: {
    fontSize: 15,
    color: theme.grey.grey5,
    border: "2px solid " + theme.grey.grey5,
    height: 16,
    width: 16,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },

  toolTip: {
    backgroundColor: theme.primary.primary5,
  },
}));

export default useStyles;
