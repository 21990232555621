import moment from "moment";

//Timezone offset for API calls
export const TZ_OFFSET = Math.floor(new Date().getTimezoneOffset() / 60) * -1;

export const getCurrency = (bidCurrency: string) => {
  switch (bidCurrency) {
    case "EUR":
      return "€";
  }

  return "£";
};

export const calculateDates = (rate: any, translation: any) => {
  const sd = moment(rate.startDate).format(translation("date_format"));
  const ed =
    rate.endDate != null
      ? moment(rate.endDate).format(translation("date_format"))
      : translation("Present");
  return sd + " - " + ed;
};
