import { useTranslation } from "react-i18next";
import useStyles from "./styles";

interface Props {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

const TariffDays = (props: Props) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    props;
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.dateContainer}>
      <div className={monday ? classes.active : classes.inactive}>
        {t("day_abbrev_mon")}
      </div>
      <div className={tuesday ? classes.active : classes.inactive}>
        {t("day_abbrev_tue")}
      </div>
      <div className={wednesday ? classes.active : classes.inactive}>
        {t("day_abbrev_wed")}
      </div>
      <div className={thursday ? classes.active : classes.inactive}>
        {t("day_abbrev_thu")}
      </div>
      <div className={friday ? classes.active : classes.inactive}>
        {t("day_abbrev_fri")}
      </div>
      <div className={saturday ? classes.active : classes.inactive}>
        {t("day_abbrev_sat")}
      </div>
      <div className={sunday ? classes.active : classes.inactive}>
        {t("day_abbrev_sun")}
      </div>
    </div>
  );
};

export default TariffDays;
