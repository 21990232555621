import { ReactNode } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

const HelpFaqs = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 style={{ paddingBottom: 10 }}>{t("FAQs")}</h2>
      <Faq
        question={t("What_is_PARIS")}
        answer={[
          { type: "str", value: t("What_is_PARIS_1") },
          { type: "str", value: t("What_is_PARIS_2") },
          { type: "str", value: t("What_is_PARIS_3") },
        ]}
      />

      <Faq
        question={t("What_is_PARIS_Insights")}
        answer={[
          { type: "str", value: t("What_is_PARIS_Insights_1") },
          {
            type: "list",
            value: [
              t("What_is_PARIS_Insights_2a"),
              t("What_is_PARIS_Insights_2b"),
              t("What_is_PARIS_Insights_2c"),
              t("What_is_PARIS_Insights_2d"),
              t("What_is_PARIS_Insights_2e"),
              t("What_is_PARIS_Insights_2f"),
              t("What_is_PARIS_Insights_2g"),
              t("What_is_PARIS_Insights_2h"),
            ],
          },
        ]}
      />
    </div>
  );
};

export default HelpFaqs;

interface FaqProps {
  answer: Array<
    | { type: "str"; value: string | ReactNode }
    | { type: "list"; value: Array<string> }
  >;
  question: ReactNode;
}

const Faq = (props: FaqProps) => {
  const theme: any = useTheme();

  return (
    <Accordion
      sx={{
        backgroundColor: theme.primary.boxBackground,
        color: theme.primary.textColor2,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{props.question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {props.answer.map((answer) => {
            if (answer.type === "str") {
              return (
                <Typography style={{ paddingBottom: 20 }}>
                  {answer.value}
                </Typography>
              );
            } else if (answer.type === "list") {
              return (
                <ul>
                  {answer.value.map((val) => (
                    <li>
                      <Typography>{val}</Typography>
                    </li>
                  ))}
                </ul>
              );
            }
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
