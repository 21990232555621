import { useEffect, useState } from "react";
import Button from "../../../../core/button";
import useStyles from "./styles";
import Modal from "../../../../core/modal";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Select from "../../../../core/select";
import client from "../../../../utils/client";
import { TZ_OFFSET } from "../../../../utils/constant";
import useAuthStore from "../../../../hooks/use-auth";
import { useNavigate } from "../../../../hooks/use-navigate";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Props {
  tariffId: number;
  load: () => void;
  site: any;
}

const UpdateTariff = (props: Props) => {
  const { tariffId, load, site } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [localTariffId, setLocalTariffId] = useState(tariffId);
  const { currentUser } = useAuthStore();
  const [allTariffs, setAllTariffs] = useState<any[]>([]);
  const { t } = useTranslation();

  const onSubmit = async () => {
    const req = {
      ...site,
      parentTariffId: localTariffId,
    };

    await client.put(`/site/update/${TZ_OFFSET}`, req);
    setOpen(false);
    load();
  };

  useEffect(() => {
    const load = async () => {
      const res = await client.get(
        `/tariffs/getByUserId/${currentUser.userId}/${TZ_OFFSET}`
      );
      setAllTariffs(res.data.response);
    };

    void load();
  }, []);

  useEffect(() => {
    setLocalTariffId(tariffId);
  }, [open]);

  return (
    <div>
      <Button onClick={() => setOpen(true)}>{t("Update_Tariff")}</Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={classes.content}>
          <h4>{t("Update_Sites_Tariff")}</h4>
          <br />
          <br />
          <div>{t("Would_you_like_edit_tariff")}</div>
          <br />
          <div>
            <NavLink
              to={`/settings/tariffs/${tariffId}/overview`}
              className={classes.button}
            >
              {t("Edit_This_Tariff")}
            </NavLink>
          </div>
          <br />
          <br />
          <div>{t("Or_if_switching_to_new_tariff")}</div>
          <br />
          <div>
            <NavLink to={`/settings/tariffs`} className={classes.button}>
              {t("Go_To_Settings_Menu")}
            </NavLink>
          </div>
          <br />
          <br />
          <div>{t("Or_set_to_existing_tariff")}</div>
          <br />
          <Select
            label={t("This_Sites_Tariff")}
            value={localTariffId}
            onChange={setLocalTariffId}
            options={allTariffs.map((t) => {
              return {
                label:
                  (t.supplierName ? t.supplierName + " - " : "") + t.tariffName,
                value: t.tariffId,
              };
            })}
            style={{ width: 350 }}
          />
        </div>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("Close")}</Button>
          <Button onClick={onSubmit} variant={"contained"}>
            {t("Submit")}
          </Button>
        </DialogActions>
      </Modal>
    </div>
  );
};

export default UpdateTariff;
