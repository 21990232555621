import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  notification: {
    marginTop: 15,
    backgroundColor: theme.primary.primary1,
    maxWidth: 1000,
    padding: 10,
    borderRadius: 105,
    paddingLeft: 25,
    paddingRight: 25,
  },

  date: {
    fontWeight: 700,
    fontSize: 13,
    paddingRight: 5,
  },

  details: {
    fontSize: 14,
  },

  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 3,
  },
}));

export default useStyles;
