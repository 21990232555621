import { createUseStyles } from "react-jss";
import Colours from "../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  solid: {
    borderTop: "1px solid #E3E3F0",
    marginTop: "1rem",
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.primary.textColor,
    whiteSpace: "nowrap",
    marginBottom: 5,
  },

  subtitle: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.primary.textColor2,
  },

  details: {
    marginBottom: "1rem",
    marginTop: "1rem",
    fontWeight: 500,

    "@media(max-width:1000px)": {
      marginBottom: 10,
    },
  },
}));

export default useStyles;
