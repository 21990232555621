import useStyles from "./styles";
import { PageType, useNavigate } from "../../../../hooks/use-navigate";
import { ReactNode } from "react";
import useAuthStore from "../../../../hooks/use-auth";
import { NavLink, useLocation } from "react-router-dom";

interface IProps {
  name: string;
  route: string;
  icon: ReactNode;
  page?: PageType;
  closeNav: () => void;
  isLogout?: boolean;
}

const NavItem = (props: IProps) => {
  const { name, route, icon, closeNav, isLogout } = props;
  const classes = useStyles();
  const { goTo, page } = useNavigate();
  const { logout } = useAuthStore();
  const location = useLocation();
  const { pathname } = location;

  const isActive =
    (route == "/sites" ? pathname == route : pathname.startsWith(route)) ||
    (name === "Sites" && pathname.startsWith("/asset"));

  const containerClassNames = [classes.navItem];
  const iconClassNames = [classes.icon];

  if (isActive) {
    containerClassNames.push(classes.navItemCurrent);
    iconClassNames.push(classes.iconCurrent);
  }

  const onClick = () => {
    if (isLogout) {
      closeNav();
      logout();
    } else {
      goTo(route);
      closeNav();
    }
  };
  return (
    <NavLink to={route} className={containerClassNames.join(" ")}>
      <div className={iconClassNames.join(" ")}>{icon}</div>
      {name}
    </NavLink>
  );
};

export default NavItem;
