import React from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";
import { useTheme } from "react-jss";

interface Props {
  checked: boolean;
  onChange: (e: boolean) => void;
  label?: string;
  labelPlacement?: "top" | "start" | "end";
}

const Checkbox = (props: Props) => {
  const { checked, onChange, label, labelPlacement = "end" } = props;
  const theme: any = useTheme();

  const StyledCheckbox = styled(MuiCheckbox)(() => ({
    "&.MuiCheckbox-root": {
      color: theme.primary.textColor,
    },
    "&.Mui-checked": {
      color: theme.primary.buttonBackgroundColor,
    },
  }));

  return (
    <FormControlLabel
      onChange={(e: any) => onChange(e.target.checked)}
      control={<StyledCheckbox checked={checked} />}
      label={label}
      labelPlacement={labelPlacement}
      sx={{ margin: 0, padding: 0 }}
    />
  );
};

export default Checkbox;
