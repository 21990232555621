import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import Button from "../../../../../core/button";
import moment from "moment";
import AddRateDialog from "./add-dialog";
import useStyles from "./styles";
import useConfirmationStore from "../../../../../hooks/use-confirmation";
import client from "../../../../../utils/client";
import { enqueueSnackbar } from "notistack";
import EditRateDialog from "./edit-dialog";
import { useTranslation } from "react-i18next";
import Box from "../../../../../core/box";

interface Props {
  tariff: any;
  selectedRateId: number;
  setSelectedRateId: Dispatch<SetStateAction<number>>;
  load: () => void;
}

const TariffRates = (props: Props) => {
  const { tariff, selectedRateId, setSelectedRateId, load } = props;
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { onOpen } = useConfirmationStore();
  const [selectedEditRate, setSelectedEditRate] = useState<any>(null);

  const onDelete = (rateId: number) => {
    onOpen({
      title: t("Confirm_delete_rate"),
      description: t("permanent_action_warning"),
      callback: async () => {
        try {
          await client.put(`/tariffRates/delete/${rateId}`);
          load();
          enqueueSnackbar(t("Rate_successfully_deleted"), {
            variant: "success",
            persist: false,
          });
        } catch {
          enqueueSnackbar(t("Error_deleting_rate"), {
            variant: "error",
            persist: false,
          });
        }
      },
    });
  };

  return (
    <Box>
      <h3>{t("Rates")}</h3>

      {tariff.tariffRates.length == 0 && (
        <div className={classes.noRates}>
          {t("No_tariff_rate_periods_to_show")}
        </div>
      )}

      <List component="nav" aria-label="main mailbox folders">
        {tariff.tariffRates.map((rate: any) => {
          return (
            <ListItemButton
              selected={selectedRateId === rate.rateId}
              onClick={() => setSelectedRateId(rate.rateId)}
              key={rate.rateId}
            >
              <Stack
                direction="row"
                justifyContent={"space-between"}
                width={"100%"}
              >
                <div>
                  <ListItemText
                    primary={moment(rate.startDate).format(t("date_format"))}
                  />
                </div>

                <div>
                  <ListItemText
                    primary={
                      rate.endDate == null || rate.endDate == ""
                        ? t("No_End_Date")
                        : moment(rate.endDate).format(t("date_format"))
                    }
                  />
                </div>

                <Stack direction={"row"} spacing={1}>
                  <Button
                    onClick={() => onDelete(rate.rateId)}
                    colour={"error"}
                    size="small"
                    variant={"outlined"}
                  >
                    {t("Delete")}
                  </Button>

                  <Button
                    onClick={() => {
                      setEditOpen(true);
                      setSelectedEditRate(rate);
                    }}
                    size="small"
                    variant={"outlined"}
                  >
                    {t("Edit")}
                  </Button>
                </Stack>
              </Stack>
            </ListItemButton>
          );
        })}

        <br />

        <Stack direction={"row"} justifyContent={"end"}>
          <Button onClick={() => setAddOpen(true)} variant="contained">
            {t("Add_rate_period")}
          </Button>
        </Stack>
      </List>

      <AddRateDialog
        load={load}
        open={addOpen}
        onClose={() => setAddOpen(false)}
      />

      <EditRateDialog
        load={load}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        selectedRate={selectedEditRate}
      />
    </Box>
  );
};

export default TariffRates;
