import Modal from "../../../../../core/modal";
import useNewTariffStore from "./new-tariff-store";
import TariffType from "./type";
import FlatRate from "./flat-rate";
import TariffDates from "./dates";
import useStyles from "./styles";
import TariffDetails from "./details";
import TariffCharges from "./charges";
import { Step, StepLabel, Stepper } from "@mui/material";
import Eco7 from "./eco7";
import NewTariffOverview from "./overview";
import CustomRate from "./custom";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

const NewTariffModal = () => {
  const { t } = useTranslation();
  const { open, type, page } = useNewTariffStore();
  const classes = useStyles();
  const theme: any = useTheme();

  const steps = [
    t("Type"),
    t("Details"),
    t("Dates"),
    t("Rates"),
    t("Charges"),
    t("Overview"),
  ];

  if (type == "custom") steps.splice(4, 1);

  let step = 0;

  switch (page) {
    case "type":
      step = 0;
      break;
    case "details":
      step = 1;
      break;
    case "dates":
      step = 2;
      break;
    case "flatRate":
    case "eco7":
    case "custom":
      step = 3;
      break;
    case "charges":
      step = 4;
      break;
    case "overview":
      step = 5;
      break;
  }

  return (
    <Modal open={open} onClose={() => {}}>
      <Stepper activeStep={step} alternativeLabel sx={{ marginTop: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  "&.MuiStepIcon-root": {
                    color: "grey", // Change icon color
                  },
                  "&.Mui-completed": {
                    color: theme.primary.primary5, // Change completed step color
                  },
                  "&.Mui-active": {
                    color: theme.primary.primary3, // Change active step color
                  },
                },
              }}
            >
              <div style={{ color: theme.primary.textColor2 }}>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.newTariff}>
        {page == "type" && <TariffType />}
        {page == "details" && <TariffDetails />}
        {page == "dates" && <TariffDates />}
        {page == "flatRate" && <FlatRate />}
        {["eco7"].includes(page) && <Eco7 />}
        {page == "custom" && <CustomRate />}
        {page == "charges" && <TariffCharges />}
        {page == "overview" && <NewTariffOverview />}
      </div>
    </Modal>
  );
};

export default NewTariffModal;
