import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 15,

    "@media(max-width:900px)": {
      gridTemplateColumns: "1fr",
    },
  },

  rotate: {
    display: "inline-block",
    transform: "rotate(270deg)",
    height: 25,
    width: 25,
  },

  icon: {
    color: theme.primary.primary5,
    height: 25,
    width: 25,
  },
}));

export default useStyles;
