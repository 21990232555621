import React, { ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

enum PageType {
  Dashboard,
  Sites,
  Reports,
  Notifications,
  Settings,
  Help,
}

const NavigateContext = React.createContext<{
  goBack: (backupRoute?: string) => void;
  goTo: (route: string) => void;
  goToReplace: (route: string) => void;
  page: PageType;
  setPage: (page: PageType) => void;
}>({
  goBack: (route) => null,
  goTo: (route) => null,
  goToReplace: (route) => null,
  page: PageType.Dashboard,
  setPage: (page) => null,
});

interface Props {
  children: ReactNode;
}

const NavigateProvider = (props: Props) => {
  const [history, setHistory] = useState<string[]>([window.location.pathname]);
  const [page, setPage] = useState(PageType.Dashboard);
  const navigate = useNavigate();

  const goBack = (backupRoute: string = "/") => {
    const newHistory = [...history];
    newHistory.pop();
    const lastRoute = newHistory[newHistory.length - 1];

    setHistory(newHistory);
    navigate(lastRoute || backupRoute);
  };

  const goTo = (route: string) => {
    const newHistory = [...history];
    newHistory.push(route);
    setHistory(newHistory);
    navigate(route);
  };

  const goToReplace = (route: string) => {
    const newHistory = [...history];
    newHistory.pop();
    newHistory.push(route);

    setHistory(newHistory);
    navigate(route);
  };

  return (
    <NavigateContext.Provider
      value={{ goBack, goTo, goToReplace, page, setPage }}
    >
      {props.children}
    </NavigateContext.Provider>
  );
};

const useNav = () => {
  const navigate = useContext(NavigateContext);

  return navigate;
};

export { NavigateProvider, useNav as useNavigate, PageType };
