import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Button from "../../../../../../core/button";
import { useState } from "react";
import client from "../../../../../../utils/client";
import { enqueueSnackbar } from "notistack";
import Input from "../../../../../../core/input";
import Select from "../../../../../../core/select";
import TimePicker from "../../../../../../core/time-picker";
import DaysActive from "../../../../../../core/days-active";
import Checkbox from "../../../../../../core/checkbox";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useAuthStore from "../../../../../../hooks/use-auth";
import Modal from "../../../../../../core/modal";
import Autocomplete from "../../../../../../core/autocomplete";

interface Props {
  open: boolean;
  rateId: number;
  onClose: () => void;
  load: () => void;
  baseCharges: any[];
  currency: string;
  pricingUnits: any[];
  customers: any[];
}

const initialState = {
  chargeName: "",
  baseChargeId: "",
  pricingUnitId: "",
  startTime: "",
  endTime: "",
  unitPrice: 0,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
  fkCustomerId: null,
};

const AddChargeDialog = (props: Props) => {
  const { open, rateId, load, baseCharges, currency, pricingUnits, customers } =
    props;
  const auth = useAuthStore.getState();
  const { t } = useTranslation();
  const [charge, setCharge] = useState({ ...initialState });
  const [isNewCharge, setIsNewCharge] = useState(false);

  const onSubmit = async () => {
    try {
      const req = {
        ...charge,
        fkRateId: rateId,
        fkPricingUnitId: charge.pricingUnitId,
        fkBaseChargeId: charge.baseChargeId,
      };

      const res = await client.post(`/tariffRateCharge/add`, req);
      if (typeof res.data.response == "string") throw {};
      onClose();
      load();
      enqueueSnackbar(t("Charge_Added"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_adding_new_charge"), {
        variant: "error",
        persist: false,
      });
    }
  };

  const onClose = () => {
    props.onClose();
    setCharge({ ...initialState });
  };

  const onChange = (value: any, field: keyof typeof initialState) => {
    const newCharge = { ...charge } as any;
    newCharge[field] = value;

    setCharge(newCharge);
  };

  const isValidData =
    (charge.baseChargeId !== "" || charge.chargeName != "") &&
    moment(charge.startTime, t("time_format_s"), true).isValid() &&
    moment(charge.endTime, t("time_format_s"), true).isValid() &&
    charge.unitPrice > 0 &&
    charge.pricingUnitId != "";

  return (
    <Modal open={open} onClose={onClose}>
      <DialogTitle>{t("Add_New_Charge")}</DialogTitle>
      <DialogContent>
        <div>{t("Enter_details_for_charge")}</div>
        <br />
        <div>
          <Checkbox
            label={t("Create_new_charge")}
            onChange={(value) => {
              const newCharge = { ...charge };
              newCharge.baseChargeId = "";
              newCharge.chargeName = "";
              newCharge.fkCustomerId = null;

              setCharge(newCharge);
              setIsNewCharge(value);
            }}
            checked={isNewCharge}
          />
        </div>

        <div>
          {isNewCharge ? (
            <>
              <div>
                <Input
                  label={t("Charge_Name")}
                  onChange={(value) => onChange(value, "chargeName")}
                  value={charge.chargeName}
                  fullWidth={true}
                />
              </div>
              <br />
              <div>
                <Autocomplete
                  label={t("Customer")}
                  options={customers.map((c) => {
                    return { label: c.customerName, id: c.customerId };
                  })}
                  onChange={(value) => onChange(value!.id, "fkCustomerId")}
                />
              </div>
              <br />
              <hr />
            </>
          ) : (
            <Select
              label={t("Charge")}
              value={charge.baseChargeId}
              onChange={(value) => onChange(value, "baseChargeId")}
              options={baseCharges.map((bc) => {
                return { value: bc.baseChargeId, label: bc.chargeName };
              })}
              style={{ width: "100%" }}
            />
          )}
        </div>

        <br />

        <Stack direction={"row"} spacing={3}>
          <TimePicker
            time={charge.startTime}
            setTime={(value) => onChange(value + ":00", "startTime")}
            label={t("Start_Time")}
          />
          <TimePicker
            time={charge.endTime}
            setTime={(value) => onChange(value + ":00", "endTime")}
            label={t("End_Time")}
          />
        </Stack>

        <br />

        <DaysActive
          onChange={onChange}
          monday={charge.monday}
          tuesday={charge.tuesday}
          wednesday={charge.wednesday}
          thursday={charge.thursday}
          friday={charge.friday}
          saturday={charge.saturday}
          sunday={charge.sunday}
        />

        <Stack direction={"row"} spacing={3} marginTop={3}>
          <Input
            value={charge.unitPrice}
            onChange={(value) => onChange(value, "unitPrice")}
            label={t("Unit_Price")}
            type="number"
            prefix={currency}
          />
          <Select
            label={t("Pricing_Unit")}
            value={charge.pricingUnitId}
            onChange={(value) => onChange(value, "pricingUnitId")}
            options={pricingUnits.map((pu) => {
              return { value: pu.pricingUnitId, label: pu.pricingUnit };
            })}
            style={{ width: 200 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={onSubmit}
          variant={"contained"}
          disabled={!isValidData}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default AddChargeDialog;
