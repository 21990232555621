import { createUseStyles } from "react-jss";

import Colours from "../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  content: {
    padding: 25,
  },

  button: {
    all: "unset",
    fontFamily: "Arial",
    backgroundColor: theme.primary.buttonBackgroundColor,
    color: theme.primary.buttonTextColor,
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    borderRadius: 5,
    marginBottom: 40,
    marginTop: 10,
    lineHeight: 1.75,
    letterSpacing: 0.5,
    textTransform: "uppercase",
    minWidth: 64,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

export default useStyles;
