import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonRow,
  IonToolbar
} from "@ionic/react";
import "./styles.css"
import { DateRange } from "../../utils/types";
import { useNavigate } from "../../hooks/use-navigate";

interface Props {
  open: boolean;
  closeDialog: () => void;
  dateRange: DateRange;
  setCustomType: () => void;
  onDateChange: (dateRange: DateRange) => void;
}

const MobileDatePickerModal = ({ open, closeDialog, dateRange, onDateChange, setCustomType }: Props) => {
  const { t } = useTranslation();
  const { goToReplace } = useNavigate();
  const [startDate, setStartDate] = useState(dateRange.startDateTime);
  const [endDate, setEndDate] = useState(dateRange.endDateTime);

  const onSubmit = () => {
    const startOfSelectionPeriod = moment(startDate)
      .startOf("day")
      .toISOString();
    const endOfSelectionPeriod = moment(endDate).endOf("day").toISOString();

    const dateRange = { startDateTime: startOfSelectionPeriod, endDateTime: endOfSelectionPeriod };

    onDateChange(dateRange);
    setCustomType();
    closeDialog();
  };

  return (
    <IonModal
      isOpen={open}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      onDidDismiss={closeDialog}
      className={"date-picker-modal"}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={closeDialog} data-testid={"ionic-cancel-button"}>{t("Cancel")}</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={onSubmit}
                       data-testid={"ionic-confirm-button"}>{t("Confirm")}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent data-testid={"mobile-custom-date-range-picker"}>
        <IonAccordionGroup>
          <IonRow>{t("Select_date_range")}</IonRow>
          <IonAccordion value="first">
            <IonItem slot="header" color="light">
              <IonLabel slot="start">{t("Start_Date")}</IonLabel>
              <IonNote slot="end">{new Date(startDate).toDateString()}</IonNote>
            </IonItem>
            <div className="ion-padding" slot="content">
              <IonDatetime
                max={new Date(endDate).toISOString()}
                value={new Date(startDate).toISOString()}
                presentation={"date"}
                size={"cover"}
                onIonChange={(e) => setStartDate(e.detail.value as string)}
              />
            </div>
          </IonAccordion>
          <IonAccordion value="second" data-testid={"end-date-accordion"}>
            <IonItem slot="header" color="light">
              <IonLabel slot="start">{t("End_Date")}</IonLabel>
              <IonNote slot="end">{new Date(endDate).toDateString()}</IonNote>
            </IonItem>
            <div className="ion-padding" slot="content">
              <IonDatetime
                min={new Date(startDate).toISOString()}
                value={new Date(endDate).toISOString()}
                presentation={"date"}
                size={"cover"}
                onIonChange={(e) => setEndDate(e.detail.value as string)}
                data-testid={"end-date-calendar"}
              />
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </IonContent>
    </IonModal>
  );
};

export default MobileDatePickerModal;
