import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import Button from "../../../../../../core/button";
import DatePicker from "../../../../../../core/date-picker";
import { useState } from "react";
import useStyles from "./styles";
import moment from "moment";
import client from "../../../../../../utils/client";
import { TZ_OFFSET } from "../../../../../../utils/constant";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../core/modal";

interface Props {
  open: boolean;
  onClose: () => void;
  load: () => void;
}

const AddRateDialog = (props: Props) => {
  const { open, load } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const classes = useStyles();
  const { tariffId } = useParams();
  const { t } = useTranslation();

  const onSubmit = async () => {
    try {
      const req = {
        startDate: moment(startDate, t("date_format"), true).format(
          t("date_time_format_seconds3")
        ),
        endDate:
          endDate == ""
            ? null
            : moment(endDate, t("date_format"), true).format(
                t("date_time_format_seconds3")
              ),

        fkTariffId: tariffId,
      };
      await client.post(`/tariffRates/add/${TZ_OFFSET}`, req);
      onClose();
      load();
      enqueueSnackbar(t("New_Rate_Added"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_adding_rate"), {
        variant: "error",
        persist: false,
      });
    }
  };

  const onClose = () => {
    props.onClose();
    setStartDate("");
    setEndDate("");
  };

  const isValidData =
    moment(startDate, t("date_format"), true).isValid() &&
    (endDate == "" || moment(endDate, t("date_format"), true).isValid());

  return (
    <Modal open={open} onClose={onClose}>
      <DialogTitle>{t("Add_New_Rate_Period")}</DialogTitle>
      <DialogContent>
        <div>{t("Enter_tariff_start_end")}</div>
        <Stack direction={"row"} justifyContent={"space-between"} marginTop={2}>
          <div>
            <div className={classes.label}>{t("Start_Date")}</div>
            <DatePicker date={startDate} setDate={setStartDate} />
          </div>

          <div>
            <div className={classes.label}>{t("End_Date")}</div>
            <DatePicker date={endDate} setDate={setEndDate} />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={onSubmit}
          variant={"contained"}
          disabled={!isValidData}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default AddRateDialog;
