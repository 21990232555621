import { SetStateAction, useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import { TZ_OFFSET } from "../../../utils/constant";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatData, performanceOptions } from "./utils";
import client from "../../../utils/client";
import useCurrentSiteStore, { SitePage } from "../hooks/use-sites";
import LineChart from "../../../core/chart/line";
import { DateRange, MWDDateRange } from "../../../utils/types";
import DayWeekMonthDateSelector from "../../../core/period-selector/day-week-month";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import { getChartOptions, getMinUnitsForDateRange } from "../../../utils/chart";
import Box from "../../../core/box";

const HistoricTrendsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = useState(true);
  const [dateType, setDateType] = useState(MWDDateRange.Month);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment(new Date()).subtract(1, "day").toISOString(),
    endDateTime: moment(new Date()).toISOString(),
  });
  const { siteId } = useParams();
  const { setPage } = useCurrentSiteStore();

  const options = useMemo(() => {
    const { startDateTime, endDateTime } = dateRange;
    const minUnit = getMinUnitsForDateRange(startDateTime, endDateTime);

    return getChartOptions(performanceOptions(minUnit));
  }, [dateRange]);

  const loadData = async (dr: typeof dateRange) => {
    setLoading(true);
    const req = {
      startDate: moment(dr.startDateTime)
        .startOf("day")
        .format(t("date_time_format_seconds")),
      endDate: moment(dr.endDateTime).format(t("date_time_format_seconds")),
      siteId: siteId,
      tzOffset: TZ_OFFSET,
    };

    try {
      const res = await client.post("/siteInformation/getSiteOverview", req);
      let body = res.data.response;
      const batRes = await client.post(
        "/kdbGateway/assetMetrics/assetsSummaryMetrics",
        req
      );
      let batUnits = batRes.data.response.batterySummaryMetrics;

      if (body === null || typeof body === "string") {
        body = [];
        setData({});
      }

      const d = formatData(
        body,
        [
          t("Site_Consumption"),
          t("Site_Production"),
          t("Net_Grid"),
          t("Stored_Charge"),
          t("Power_To_Battery"),
        ],
        batUnits
      );

      setData(d);
      setLoading(false);
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    loadData(dateRange);
    setPage(SitePage.Performance);
  }, [dateRange]);

  return (
    <div>
      <DayWeekMonthDateSelector
        dateType={dateType}
        setType={setDateType}
        setDateRange={setDateRange}
        dateRange={dateRange}
        graphTitle={t("Performance")}
      />
      <Box>
        <div className={classes.chart}>
          <LineChart
            loading={loading}
            data={data}
            options={options}
            canZoom={!isNativeMobile()}
            canExport={!isNativeMobile()}
            exportName="performance"
          />
        </div>
      </Box>
    </div>
  );
};

export default HistoricTrendsPage;
