import { createUseStyles } from "react-jss";
import Colours from "../../../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  label: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.grey.grey5,
  },

  value: {
    fontWeight: 600,
    fontSize: 17,
  },

  chargeGrid: {
    display: "grid",
    gridTemplateColumns: "auto auto auto min-content",
  },
}));

export default useStyles;
