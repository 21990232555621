import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  label: {
    fontWeight: 500,
    marginBottom: 5,
  },
});

export default useStyles;
