import {
  DashboardIcon,
  ElectricIcon,
  HelpIcon,
  LineIcon,
  LogoutIcon,
  MenuIcon,
  MessageIcon,
  MeterIcon,
  MoneyIcon,
  OverviewIcon,
  TreeIcon,
  XIcon,
} from "../../icons";
import NavItem from "./nav-item";
import useStyles from "./styles";
import SlidingPane from "react-sliding-pane";
import { useState } from "react";
import { PageType, useNavigate } from "../../../hooks/use-navigate";
import useSitesStore from "../../../hooks/use-sites";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../lang-switch";
import { Domain, getDomain } from "../../../utils/get-domain";

const MobileNavBar = () => {
  const classes = useStyles();
  const { goTo } = useNavigate();
  const [navOpen, setNavOpen] = useState(false);
  const siteId = useSitesStore((store) => store.siteId);
  const isMultiSite = useSitesStore((store) => store.isMultiSite);
  const { t } = useTranslation();
  const domain = getDomain();
  const showTariff = domain != Domain.SNXT;

  const logo =
    domain == Domain.SNXT
      ? "/images/Logo_SonneNext.webp"
      : "/images/PARIS INSIGHTS - STACKED.svg";

  const altText =
    domain == Domain.SNXT
      ? "SonneNEXT Logo"
      : "The Electric Storage Company Logo";

  return (
    <div className={classes.navBar}>
      <img
        src={logo}
        className={classes.logo}
        onClick={() => goTo("/site/overview")}
        alt={altText}
      />

      <button className={classes.menu} onClick={() => setNavOpen(true)}>
        <MenuIcon width={40} height={40} className={classes.hamburger} />
      </button>
      <SlidingPane
        hideHeader={true}
        isOpen={navOpen}
        onRequestClose={() => setNavOpen(false)}
        className={classes.slideDialog}
      >
        <h2 className={classes.title}>{t("Site_Navigation")}</h2>

        <LanguageSwitcher className={classes.switcher} />

        <br />
        {isMultiSite && (
          <>
            <NavItem
              name={t("All_Sites")}
              route={"/sites"}
              icon={<DashboardIcon width={20} height={20} />}
              page={PageType.Sites}
              closeNav={() => setNavOpen(false)}
            />
            <br />
          </>
        )}

        <NavItem
          name={t("Assets")}
          icon={<OverviewIcon width={20} height={20} />}
          route={`/sites/${siteId}/assets`}
          closeNav={() => setNavOpen(false)}
        />

        <NavItem
          name={t("Performance")}
          icon={<LineIcon width={20} height={20} />}
          route={`/sites/${siteId}/performance`}
          closeNav={() => setNavOpen(false)}
        />
        <NavItem
          name={t("Finance")}
          icon={<MoneyIcon width={20} height={20} />}
          route={`/sites/${siteId}/finance`}
          closeNav={() => setNavOpen(false)}
        />
        <NavItem
          name={t("Carbon")}
          icon={<TreeIcon width={20} height={20} />}
          route={`/sites/${siteId}/carbon`}
          closeNav={() => setNavOpen(false)}
        />

        {showTariff && (
          <NavItem
            name={t("Tariff")}
            icon={<MeterIcon width={20} height={20} />}
            route={`/sites/${siteId}/tariff`}
            closeNav={() => setNavOpen(false)}
          />
        )}

        <br />
        <NavItem
          name={t("Notifications")}
          route={"/notifications"}
          icon={<MessageIcon width={20} height={20} />}
          page={PageType.Notifications}
          closeNav={() => setNavOpen(false)}
        />

        <NavItem
          name={t("Help")}
          route={"/help"}
          icon={<HelpIcon width={20} height={20} />}
          closeNav={() => setNavOpen(false)}
          page={PageType.Help}
        />

        <br />

        <NavItem
          name={t("Logout")}
          route={"/logout"}
          icon={<LogoutIcon width={20} height={20} />}
          closeNav={() => setNavOpen(false)}
          isLogout={true}
        />

        <button className={classes.close} onClick={() => setNavOpen(false)}>
          <div className={classes.icon}>
            <XIcon />
          </div>
          Close
        </button>
      </SlidingPane>
    </div>
  );
};

export default MobileNavBar;
