import { Navigate, Route, Routes } from "react-router-dom";
import useStyles from "./styles";
import TariffRatePeriod from "./tariff/rates";
import TariffPage from "./tariff";
import CustomerTariffs from "./all-tariffs";

const TariffRoutes = () => {
  const classes = useStyles();

  return (
    <Routes>
      <Route path="/" element={<CustomerTariffs />} />
      <Route path="/:tariffId/overview" element={<TariffPage />} />
      {/* <Route path="/rate-period/:rateId" element={<TariffRatePeriod />} /> */}
      {/* <Route path="/dashboard" element={<SettingsDashboard />} />
        <Route path="/organisation" element={<SettingsOrganisation />} /> */}
      <Route path="*" element={<Navigate replace to="/settings/tariffs" />} />
    </Routes>
  );
};

export default TariffRoutes;
