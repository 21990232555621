import { Fragment, ReactNode } from "react";
import { useNavigate } from "../../../../hooks/use-navigate";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { ArrowRightLongIcon } from "../../../../core/icons";
import moment from "moment";

interface Props {
  title: string;
  mobileSubtitle?: string;
  subtitle?: string;
  icon: ReactNode;
  metrics: {
    name: string;
    value: string;
    unit?: string;
    showOnMobile: boolean;
    mobileName?: string;
  }[];
  id: string;
  route: string;
  lastUpdated?: string;
}

const AssetContainer = (props: Props) => {
  const {
    id,
    route,
    title,
    subtitle,
    mobileSubtitle,
    icon,
    metrics,
    lastUpdated,
  } = props;
  const classes = useStyles();
  const { goTo } = useNavigate();
  const { t } = useTranslation();

  return (
    <IonCard
      className={classes.asset}
      onClick={() => goTo(`/asset/${route}/${id}`)}
    >
      <IonCardHeader className={classes.topContainer}>
        <div className={classes.topLeft}>
          {icon}
          <div
            className={
              !!subtitle && !mobileSubtitle ? classes.titleContainer : ""
            }
          >
            <IonCardTitle className={classes.title}>{title}</IonCardTitle>
            <div className={`ion-hide-sm-down ${classes.subtitle}`}>
              {subtitle}
            </div>
            <div className={`ion-hide-sm-up ${classes.mobileSubtitle}`}>
              {mobileSubtitle}
            </div>
          </div>
        </div>
        <div className={classes.more}>
          <div className={`ion-hide-sm-down ${classes.ionButtonText}`}>
            {t("More")}
          </div>
          <div className={`ion-hide-sm-down ${classes.ArrowLongRight}`}>
            <ArrowRightLongIcon
              height={11}
              width={11}
              transform="inherit"
              strokeWidth="35px"
            />
          </div>
        </div>

        <div className={`ion-hide-sm-up ${classes.ArrowLongRightMobile}`}>
          <ArrowRightLongIcon
            transform="inherit"
            stroke="#050505"
            strokeWidth="30px"
          />
        </div>
      </IonCardHeader>
      <IonCardContent className={classes.details}>
        {metrics.map((metric, i) => (
          <Fragment key={metric.name + i}>
            <div className={!metric.showOnMobile ? "ion-hide-sm-down" : ""}>
              <div
                className={
                  !!metric.mobileName || !metric.showOnMobile
                    ? `ion-hide-sm-down ${classes.metricName}`
                    : classes.metricName
                }
              >
                {metric.name}{" "}
              </div>
              <div className={`ion-hide-sm-up ${classes.metricName}`}>
                {metric.mobileName}{" "}
              </div>
              <div className={classes.metricValueContainer}>
                <div
                  className={
                    !metric.showOnMobile
                      ? `ion-hide-sm-down ${classes.metricValue}`
                      : classes.metricValue
                  }
                >
                  {metric.value}
                </div>
                <div
                  className={
                    !metric.showOnMobile
                      ? `ion-hide-sm-down ${classes.metricUnit}`
                      : classes.metricUnit
                  }
                >
                  {metric.unit}
                </div>
              </div>
            </div>
          </Fragment>
        ))}
      </IonCardContent>
      <div className={classes.lastUpdated}>
        {t("Last_Updated")}:{" "}
        {lastUpdated ? moment(lastUpdated).format("HH:mm") : "N/A"}
      </div>
    </IonCard>
  );
};

export default AssetContainer;
