import { ITableProps } from "ka-table";
import { PagingPosition, SortingMode } from "ka-table/enums";

const initialTableProps: ITableProps = {
  columns: [],
  data: [],
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
    pageSizes: [10, 25, 30, 50],
    position: PagingPosition.Bottom,
  },
  sortingMode: SortingMode.Single,
  rowKeyField: "id",
};

export default initialTableProps;
