import { useNavigate } from "../../../hooks/use-navigate";
import { Domain, getDomain } from "../../../utils/get-domain";
import useStyles from "./styles";

const TopBarLogo = () => {
  const classes = useStyles();
  const domain = getDomain();
  const { goTo } = useNavigate();

  switch (domain) {
    case Domain.SNXT:
      return (
        <img
          src={"/images/AiLeen long.svg"}
          className={classes.logo}
          onClick={() => goTo("/sites")}
          data-testid="navMenuLogo"
          alt={"SonneNEXT Logo"}
        />
      );

    default:
      return (
        <img
          src={"/images/PARIS INSIGHTS - LONG.svg"}
          className={classes.logo}
          onClick={() => goTo("/sites")}
          data-testid="navMenuLogo"
          alt={"Joulen Logo"}
        />
      );
  }
};

export default TopBarLogo;
