import { ReactNode } from "react";
import useStyles from "./styles";

interface Props {
  children?: ReactNode;
}

const AnimationContainer = (props: Props) => {
  const classes = useStyles();
  const { children } = props;
  return <div className={classes.container}>{children}</div>;
};

export default AnimationContainer;
