import { useEffect, useState } from "react";
import useStyles from "./styles";
import useAuthStore from "../../../hooks/use-auth";
import client from "../../../utils/client";
import { TZ_OFFSET } from "../../../utils/constant";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../core/lang-switch";
import { IonNote } from "@ionic/react";
import { isNativeMobile } from "../../../utils/ionic";
import Divider from "../../../core/divider";
import Button from "../../../core/button";
import useSitesStore from "../../../hooks/use-sites";

const SettingsAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const { currentUser } = useAuthStore();
  const { logout } = useAuthStore();
  const { siteId } = useSitesStore();

  useEffect(() => {
    const load = async () => {
      const res = await client.get(
        `/appUsers/getByUserId/${currentUser.userId}/${TZ_OFFSET}`
      );
      setUser(res.data.response);
      setLoading(false);
    };

    void load();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div id="settings-details">
        <br />
        <div>
          <h2 className={classes.title} id="name">
            {user.firstName} {user.lastName}
          </h2>
          <p className={classes.subTitle} id="email">
            {user.email}
          </p>
        </div>

        <Divider />
        <div className={classes.topContainer} id="UserId">
          {t("User_ID")}
          <IonNote className={classes.notes} color="medium">
            {user.userId}
          </IonNote>
        </div>

        {siteId != null && siteId != "" && (
          <div className={classes.topContainer} id="UserId">
            {t("Site_ID")}
            <IonNote className={classes.notes} color="medium">
              {siteId}
            </IonNote>
          </div>
        )}
        <div
          className={`item md item-lines-none item-fill-none item-label ${classes.topContainer}`}
          id="phoneNumber"
        >
          {t("Phone")}
          <IonNote className={classes.notes} color="medium">
            {user.mobileNumber}
          </IonNote>
        </div>
        <div className={classes.topContainer} id="language">
          {t("Language")}
          <LanguageSwitcher className="" />
        </div>
        <Divider />

        {isNativeMobile() && (
          <Button onClick={logout} colour="error">
            {" "}
            {t("Logout")}
          </Button>
        )}
      </div>
    </>
  );
};

export default SettingsAccount;
