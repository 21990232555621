import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  grid: {
    padding: 20,
    display: "grid",
    gridTemplateColumns: "auto repeat(48, 1fr)",
    gridAutoRows: "45px",
    rowGap: 30,
    fontSize: 12,
    fontWeight: 600,
  },

  details: {
    display: "flex",
    alignItems: "center",
    color: theme.grey.grey7,
    paddingRight: 5,
    maxWidth: 200,
    textAlign: "center",
    fontSize: 13,
  },

  tariff: {
    borderRadius: 10,
    marginTop: 30,
    backgroundColor: theme.primary.boxBackground,
  },

  title: {
    color: theme.primary.primary5,
    fontWeight: 600,
    fontSize: 14,
  },

  mobileTitle: {
    color: theme.grey.grey7,
    fontSize: 16,
  },

  topContainer: {
    padding: 15,
    borderBottom: "2px solid " + theme.grey.grey1,
    display: "flex",
    justifyContent: "space-between",
  },

  bottomContainer: {
    padding: 15,
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
}));

export default useStyles;
