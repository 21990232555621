import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    marginBottom: 50,
  },

  chart: {
    paddingBottom: 25,
  },
});

export default useStyles;
