import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  header: {
    backgroundColor: theme.primary.buttonBackgroundColor,
    color: theme.primary.buttonTextColor,
  },
}));

export default useStyles;
