import { Stack } from "@mui/material";
import useStyles from "./styles";
import moment from "moment";
import DaysActiveDisplay from "../../../../../core/days-active-display";
import { TZ_OFFSET, getCurrency } from "../../../../../utils/constant";
import Button from "../../../../../core/button";
import { Fragment, useState } from "react";
import AddChargeDialog from "./add-dialog";
import EditChargeDialog from "./edit-dialog";
import useConfirmationStore from "../../../../../hooks/use-confirmation";
import client from "../../../../../utils/client";
import { enqueueSnackbar } from "notistack";
import useAuthStore from "../../../../../hooks/use-auth";
import { useTranslation } from "react-i18next";
import Box from "../../../../../core/box";

interface Props {
  tariff: any;
  selectedRateId: number;
  load: () => void;
  baseCharges: any[];
  pricingUnits: any[];
  customers: any[];
}

const TariffRateCharges = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tariff, selectedRateId, load, baseCharges, pricingUnits, customers } =
    props;
  const { onOpen } = useConfirmationStore();
  const { currentUser } = useAuthStore();

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedCharge, setCharge] = useState<any>({});

  const selectedRate =
    tariff.tariffRates.find((r: any) => r.rateId == selectedRateId) || {};

  const { rateCharges = [] } = selectedRate;

  const onDelete = (rateChargeId: number) => {
    onOpen({
      title: t("Confirm_delete"),
      description: t("permanent_action_warning"),
      callback: async () => {
        try {
          const res = await client.delete(
            `/tariffRateCharge/delete/${currentUser.userId}/${rateChargeId}/${TZ_OFFSET}`
          );
          if (typeof res.data.response == "string") throw {};

          load();
          enqueueSnackbar(t("Charge_Successfully_Deleted"), {
            variant: "success",
            persist: false,
          });
        } catch {
          enqueueSnackbar(t("Error_deleting_charge"), {
            variant: "error",
            persist: false,
          });
        }
      },
    });
  };

  return (
    <Box>
      <h4>{t("Additional_Charges")}</h4>

      {rateCharges == 0 ? (
        <div className={classes.noRateCharges}>
          {t("No_charges_to_show_for_period")}
        </div>
      ) : (
        <div className={classes.chargeGrid}>
          {rateCharges.map((charge: any) => (
            <Fragment key={charge.rateChargeId}>
              <div>{charge.baseChargeName}</div>
              <div>{charge.description}</div>
              <div>
                {getCurrency(tariff.currency)}
                {charge.unitPrice} {charge.pricingUnit}
              </div>
              {charge.startTime == charge.endTime ? (
                <div>{t("All_Day")}</div>
              ) : (
                <Stack direction={"row"}>
                  <div>
                    {moment(charge.startTime, t("time_format_s")).format(
                      t("time_format")
                    )}
                  </div>
                  <div> - </div>
                  <div>
                    {moment(charge.endTime, t("time_format_s")).format(
                      t("time_format")
                    )}
                  </div>
                </Stack>
              )}

              <DaysActiveDisplay
                monday={charge.monday}
                tuesday={charge.tuesday}
                wednesday={charge.wednesday}
                thursday={charge.thursday}
                friday={charge.friday}
                saturday={charge.saturday}
                sunday={charge.sunday}
              />

              <Stack direction={"row"}>
                <Button
                  onClick={() => onDelete(charge.rateChargeId)}
                  colour={"error"}
                >
                  {t("Delete")}
                </Button>
                <Button
                  onClick={() => {
                    setCharge(charge);
                    setEditOpen(true);
                  }}
                >
                  {t("Edit")}
                </Button>
              </Stack>
            </Fragment>
          ))}
        </div>
      )}
      <Stack direction="row" justifyContent={"end"} marginTop={1}>
        <Button
          onClick={() => setAddOpen(true)}
          variant={"contained"}
          size="small"
        >
          {t("Add_Charge")}
        </Button>
      </Stack>

      <AddChargeDialog
        open={addOpen}
        rateId={selectedRateId}
        onClose={() => setAddOpen(false)}
        load={load}
        baseCharges={baseCharges}
        currency={getCurrency(tariff.currency)}
        pricingUnits={pricingUnits}
        customers={customers}
      />

      <EditChargeDialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        load={load}
        baseCharges={baseCharges}
        currency={getCurrency(tariff.currency)}
        pricingUnits={pricingUnits}
        selectedCharge={selectedCharge}
      />
    </Box>
  );
};

export default TariffRateCharges;
