import {
  Autocomplete as MuiAutoComplete,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useTheme } from "react-jss";

interface Props {
  onChange: (value: any) => void;
  options: { label: string; id: any }[];
  label: string;
}

const Autocomplete = (props: Props) => {
  const { onChange, options, label } = props;
  const theme: any = useTheme();

  const textFieldStyles: SxProps = {
    "& .MuiInputBase-root": {
      color: theme.primary.textColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.primary.textColor,
      },
      "&:hover fieldset": {
        borderColor: theme.primary.textColor,
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.primary.textColor,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.primary.textColor,
    },
  };

  return (
    <MuiAutoComplete
      disablePortal
      options={options}
      renderInput={(params: TextFieldProps) => (
        <TextField label={label} {...params} fullWidth sx={textFieldStyles} />
      )}
      onChange={(e, value) => onChange(value)}
    />
  );
};

export default Autocomplete;
