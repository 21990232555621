import { useTranslation } from "react-i18next";
import ToolTip from "../../../../mobile/tooltip";
import TooltipIcon from "../../../../core/icons/tooltip.svg";
import { isNativeMobile } from "../../../../utils/ionic";
import BackButton from "../../../../core/back";
import useStyles from "./styles";
import Divider from "../../../../core/divider";

interface Props {
  currentConsumption: number;
  weeklyConsumption: number;
}

const ConsumptionDetails = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentConsumption, weeklyConsumption } = props;

  return (
    <div>
      {!isNativeMobile() && (
        <>
          <BackButton defaultRoute={"/"} />
          <Divider />
          <h2>{t("Consumption_Point")}</h2>
        </>
      )}

      <div className={classes.detailsRow}>
        <div className={classes.label}>
          {t("Current_consumption")}{" "}
          <ToolTip
            tooltipText={t("Energy_consumed_in_the_last_30_minutes")}
            icon={TooltipIcon}
            id={"tooltip-consumption"}
          />
        </div>
        <div
          className={classes.value}
          data-testid="consumption-details-current-consumption"
        >
          {currentConsumption} {t("suffix_kWh")}
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.label}>{t("Consumption_Last_7_Day")}</div>
        <div
          className={classes.value}
          data-testid="consumption-details-weekly-consumption"
        >
          {weeklyConsumption} {t("suffix_kWh")}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ConsumptionDetails;
