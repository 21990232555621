import accounting from "accounting";
import ToolTip from "../../../../mobile/tooltip";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../utils/constant";
import {
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import TooltipIcon from "../../../../core/icons/tooltip.svg";
import { Stack } from "@mui/material";
import InfoTooltip from "../../../../core/tool-tip";
import { isNativeMobile } from "../../../../utils/ionic";
import "./styles.css";
import Box from "../../../../core/box";

interface Props {
  wouldHaveSpent: number;
  actualSpending: number;
  savings: number;
  levelisedCost: number;
  totalRev: number;
  currency: string;
}

const FinanceTotals = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { wouldHaveSpent, actualSpending, levelisedCost, totalRev, currency } =
    props;
  const savings = wouldHaveSpent - actualSpending;
  const currencySymbol = getCurrency(currency);

  return (
    <>
      {isNativeMobile() ? (
        <>
          <div className={classes.label}>({t("Estimated")})</div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>{t("Total_Spending")}</div>
            <div className={classes.value}>
              {accounting.formatMoney(actualSpending, currencySymbol)}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>{t("Total_Savings")}</div>
            <div className={classes.value}>
              {accounting.formatMoney(savings, currencySymbol)}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>{t("Would_have_spent")}</div>
            <div className={classes.value}>
              {accounting.formatMoney(wouldHaveSpent, currencySymbol)}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>{t("Export_Revenue")}</div>
            <div className={classes.value}>
              {accounting.formatMoney(totalRev, currencySymbol)}
            </div>
          </div>

          <div className={classes.detailsRow}>
            <div className={classes.label}>
              <IonLabel>{t("Levelised_Cost")} </IonLabel>
              <ToolTip
                tooltipText={t("Actual_amount_paying")}
                icon={TooltipIcon}
                id={"tooltipMobile"}
              />
            </div>
            <div className={classes.value}>
              {accounting.formatMoney(levelisedCost * 1000, currencySymbol)}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.container}>
          <Box>
            <Stack direction="row" spacing={1}>
              <div className={classes.title}>{t("Estimated_total_of")}</div>
              <ToolTip
                tooltipText={t("Estimated_total_amount")}
                icon={TooltipIcon}
                id={"would-have-spent"}
              />
            </Stack>
            <h3>{accounting.formatMoney(wouldHaveSpent, currencySymbol)}</h3>
            <div className={classes.subtitle}>{t("Would_have_spent")}</div>
          </Box>

          <Box>
            <Stack direction="row" spacing={1}>
              <div className={classes.title}>{t("Estimated_total_of")}</div>
              <ToolTip
                tooltipText={t("Estimated_actual_amount")}
                icon={TooltipIcon}
                id={"actual-spending"}
              />
            </Stack>
            <h3>{accounting.formatMoney(actualSpending, currencySymbol)}</h3>
            <div className={classes.subtitle}>{t("Actual_spending")}</div>
          </Box>

          <Box>
            <Stack direction="row" spacing={1}>
              <div className={classes.title}>{t("Estimated_total_of")}</div>
              <ToolTip
                tooltipText={t("Estimated_total_actual_savings")}
                icon={TooltipIcon}
                id={"actual-saving"}
              />
            </Stack>
            <h3>{accounting.formatMoney(savings, currencySymbol)}</h3>
            <div className={classes.subtitle}>{t("Actual_savings")}</div>
          </Box>

          <Box>
            <div className={classes.title}>{t("Estimated_total_of")}</div>
            <h3>{accounting.formatMoney(totalRev, currencySymbol)}</h3>
            <div className={classes.subtitle}>{t("Export_Revenue")}</div>
          </Box>

          <Box>
            <Stack direction="row" spacing={1}>
              <div className={classes.title}>{t("Levelised_Cost")}</div>

              <ToolTip
                tooltipText={t("Actual_amount_paying")}
                icon={TooltipIcon}
                id={"levelised-cost"}
              />
            </Stack>
            <h3>
              {accounting.formatMoney(levelisedCost * 1000, currencySymbol)}
            </h3>
          </Box>
        </div>
      )}
    </>
  );
};

export default FinanceTotals;
