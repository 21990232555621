import { useTranslation } from "react-i18next";
import { BackIcon } from "../icons";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

interface Props {
  defaultRoute: string;
}

const BackButton = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      window.history.back();
    } else {
      navigate(props.defaultRoute);
    }
  };

  return (
    <button onClick={handleBack} className={classes.container}>
      <BackIcon height={27} width={27} />
      <span className={classes.text}>{t("Back")}</span>
    </button>
  );
};

export default BackButton;
