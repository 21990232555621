import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { PageType, useNavigate } from "../../hooks/use-navigate";
import useSitesStore from "../../hooks/use-sites";
import SiteAssets from "./assets";
import SiteCarbon from "./carbon";
import SiteFinance from "./finance";
import SitePerformance from "./performance";
import SiteTariff from "./tariff";
import client from "../../utils/client";
import { TZ_OFFSET } from "../../utils/constant";
import { enqueueSnackbar } from "notistack";
import Loader from "../../core/loader";
import { useTranslation } from "react-i18next";
import { Domain, getDomain } from "../../utils/get-domain";

const SitesPage = () => {
  const { siteId } = useParams();
  const { setPage } = useNavigate();
  const { t } = useTranslation();
  const isSingleSite = useSitesStore((store) => store.isSingleSite);
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useState<any>({});
  const domain = getDomain();
  const showTariff = domain != Domain.SNXT;

  useEffect(() => {
    setPage(isSingleSite ? PageType.Dashboard : PageType.Sites);

    const load = async () => {
      setLoading(true);
      try {
        const res = await client.put(
          `/site/getBySiteId/${siteId}/${TZ_OFFSET}`
        );
        setSiteData(res.data.response);
        setLoading(false);
        useSitesStore.setState({ siteId });
      } catch {
        enqueueSnackbar(t("Error_loading_sites"), {
          variant: "error",
          persist: false,
        });
      }
    };

    void load();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Routes>
        <Route path="/assets" element={<SiteAssets siteData={siteData} />} />
        <Route path="/performance" element={<SitePerformance />} />
        <Route path="/finance" element={<SiteFinance site={siteData} />} />
        <Route path="/carbon" element={<SiteCarbon />} />
        {showTariff && (
          <Route path="/tariff" element={<SiteTariff site={siteData} />} />
        )}
        <Route
          path="*"
          element={<Navigate replace to={"/sites/" + siteId + "/assets"} />}
        />
      </Routes>
    </div>
  );
};

export default SitesPage;
