import { createUseStyles } from "react-jss";
import Colours from "../../../../../../utils/colours";

const useStyles = createUseStyles((theme: any) => ({
  button: {
    all: "unset",
    borderRadius: 10,
    border: "1px solid " + theme.grey.grey2,
    padding: 10,
    width: "-webkit-fill-available",
    marginBottom: 10,
    marginTop: 5,

    "&:hover": {
      border: "1px solid " + theme.primary.primary5,
      color: theme.primary.primary5,
      cursor: "pointer",
    },
  },
}));

export default useStyles;
