import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import Button from "../../../../../../core/button";
import { useEffect, useState } from "react";
import client from "../../../../../../utils/client";
import { enqueueSnackbar } from "notistack";
import Input from "../../../../../../core/input";
import Select from "../../../../../../core/select";
import TimePicker from "../../../../../../core/time-picker";
import DaysActive from "../../../../../../core/days-active";
import { TZ_OFFSET } from "../../../../../../utils/constant";
import useAuthStore from "../../../../../../hooks/use-auth";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../core/modal";

interface Props {
  open: boolean;
  onClose: () => void;
  load: () => void;
  baseCharges: any[];
  currency: string;
  pricingUnits: any[];
  selectedCharge: any;
}

const EditChargeDialog = (props: Props) => {
  const { open, load, baseCharges, currency, pricingUnits, selectedCharge } =
    props;
  const { t } = useTranslation();
  const [charge, setCharge] = useState<any>({});
  const { currentUser } = useAuthStore();

  useEffect(() => {
    if (open) {
      setCharge({ ...selectedCharge });
    }
  }, [selectedCharge, open]);

  const onSubmit = async () => {
    try {
      const req = {
        ...charge,
      };

      const res = await client.put(
        `/tariffRateCharge/update/${currentUser.userId}/${TZ_OFFSET}`,
        req
      );
      if (typeof res.data.response == "string") throw {};
      onClose();
      load();
      enqueueSnackbar(t("Charge_Added"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_adding_new_charge"), {
        variant: "error",
        persist: false,
      });
    }
  };

  const onClose = () => {
    props.onClose();
    setCharge({});
  };

  const onChange = (value: any, field: any) => {
    const newCharge = { ...charge } as any;
    newCharge[field] = value;

    setCharge(newCharge);
  };

  const isValidData =
    (charge.baseChargeId !== "" || charge.chargeName != "") &&
    moment(charge.startTime, t("time_format_s"), true).isValid() &&
    moment(charge.endTime, t("time_format_s"), true).isValid() &&
    charge.unitPrice > 0 &&
    charge.pricingUnitId != "";

  return (
    <Modal open={open} onClose={onClose}>
      <DialogTitle>{t("Add_New_Charge")}</DialogTitle>
      <DialogContent>
        <div>{t("Enter_details_for_charge")}</div>
        <br />

        <Select
          label={t("Charge")}
          value={charge.fkBaseChargeId}
          onChange={(value) => onChange(value, "fkBaseChargeId")}
          options={baseCharges.map((bc) => {
            return { value: bc.baseChargeId, label: bc.chargeName };
          })}
          style={{ width: "100%" }}
        />

        <br />

        <Stack direction={"row"} spacing={3} marginTop={3}>
          <TimePicker
            time={charge.startTime}
            setTime={(value) => onChange(value + ":00", "startTime")}
            label={t("Start_Time")}
          />
          <TimePicker
            time={charge.endTime}
            setTime={(value) => onChange(value + ":00", "endTime")}
            label={t("End_Time")}
          />
        </Stack>

        <br />

        <DaysActive
          onChange={onChange}
          monday={charge.monday}
          tuesday={charge.tuesday}
          wednesday={charge.wednesday}
          thursday={charge.thursday}
          friday={charge.friday}
          saturday={charge.saturday}
          sunday={charge.sunday}
        />

        <Stack direction={"row"} spacing={3} marginTop={3}>
          <Input
            value={charge.unitPrice}
            onChange={(value) => onChange(value, "unitPrice")}
            label={t("Unit_Price")}
            type="number"
            prefix={currency}
          />
          <Select
            label={t("Pricing_Unit")}
            value={charge.fkPricingUnitId}
            onChange={(value) => onChange(value, "pricingUnitId")}
            options={pricingUnits.map((pu) => {
              return { value: pu.pricingUnitId, label: pu.pricingUnit };
            })}
            style={{ width: 200 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={onSubmit}
          variant={"contained"}
          disabled={!isValidData}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default EditChargeDialog;
