import moment from "moment";
import { Fragment } from "react";
import Colours from "../../../../utils/colours";
import TariffDays from "./days";
import useStyles from "./styles";
import TariffTimes from "./times";
import { isNativeMobile } from "../../../../utils/ionic";
import Box from "../../../../core/box";
import { useTheme } from "react-jss";

interface Props {
  rates: any[];
  nameField?: string;
  priceField?: string;
  currency?: string;
  title?: string;
  colour?: string;
}

const TariffBox = (props: Props) => {
  const {
    rates,
    nameField = "periodName",
    priceField = "unitPriceKwh",
    currency = "£",
    title,
  } = props;
  const classes = useStyles();
  const theme: any = useTheme();

  const colorList = [
    theme.primary.tariffColor1,
    theme.primary.tariffColor2,
    theme.primary.tariffColor3,
    theme.primary.tariffColor4,
    theme.primary.tariffColor5,
    theme.primary.tariffColor6,
  ];

  return (
    <div className={classes.tariff}>
      <div className={classes.topContainer}>
        {!isNativeMobile() ? (
          <>
            <div className={classes.title}>{title || rates[0].direction}</div>
            <div>
              <TariffDays
                monday={rates[0].monday}
                tuesday={rates[0].tuesday}
                wednesday={rates[0].wednesday}
                thursday={rates[0].thursday}
                friday={rates[0].friday}
                saturday={rates[0].saturday}
                sunday={rates[0].sunday}
              />
            </div>
          </>
        ) : (
          <div>
            <div className={classes.mobileTitle}>
              {title || rates[0].direction}
            </div>
          </div>
        )}
      </div>

      <div className={classes.grid}>
        <TariffTimes amountOfRates={rates.length} />

        {rates.map((rate: any, i) => {
          const st = moment.duration(rate.startTime).asHours() * 2;
          let et = moment.duration(rate.endTime).asHours() * 2;
          et = et == 0 ? 48 : et;

          const twoBoxes = et <= st;

          return (
            <Fragment key={i}>
              {twoBoxes ? (
                <>
                  <div
                    style={{
                      gridRow: i + 2,
                      gridColumn: 2 + "/" + (et + 2),
                      backgroundColor: colorList[i],
                    }}
                  />
                  <div
                    style={{
                      gridRow: i + 2,
                      gridColumn: st + 2 + "/ 50",
                      backgroundColor: colorList[i],
                    }}
                  />
                </>
              ) : (
                <div
                  style={{
                    gridRow: i + 2,
                    gridColumn: st + 2 + "/" + (et + 2),
                    backgroundColor: colorList[i],
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </div>
      <div className={classes.bottomContainer}>
        {rates.map((rate: any, i) => {
          return (
            <div key={i} className={classes.details}>
              <div>
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: 5,
                    marginRight: 5,
                    marginBottom: "-2",
                    borderRadius: "50%",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    backgroundColor: colorList[i],
                    height: 20,
                    width: 20,
                  }}
                ></div>
              </div>
              <div
                style={{
                  paddingLeft: 10,
                }}
              >
                <div>{rate[nameField]}</div>
                <div>
                  {currency}
                  {rate[priceField]} kWh
                </div>
                <div>
                  {rate.startTime.substring(0, 5)} -{" "}
                  {rate.endTime.substring(0, 5)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TariffBox;
