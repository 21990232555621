import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageType, useNavigate } from "../../hooks/use-navigate";
import SettingsAccount from "./account";
import SettingsDashboard from "./dashboard";
import SettingsNav from "./nav";
import SettingsOrganisation from "./organisation";
import useStyles from "./styles";
import TariffSettings from "./tariff";
import { useTranslation } from "react-i18next";
import { Domain, getDomain } from "../../utils/get-domain";

const SettingsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPage } = useNavigate();
  const domain = getDomain();
  const showTariff = domain != Domain.SNXT;

  useEffect(() => {
    setPage(PageType.Settings);
  }, []);

  return (
    <div>
      <h2 className="ion-hide-sm-down">{t("Settings")}</h2>
      <SettingsNav />
      <Routes>
        <Route path="/account" element={<SettingsAccount />} />
        {showTariff && (
          <>
            <Route path="/tariffs/*" element={<TariffSettings />} />
            <Route path="/tariff-charges/*" element={<TariffSettings />} />
          </>
        )}
        {/* <Route path="/dashboard" element={<SettingsDashboard />} />
        <Route path="/organisation" element={<SettingsOrganisation />} /> */}
        <Route path="*" element={<Navigate replace to="/settings/account" />} />
      </Routes>
    </div>
  );
};

export default SettingsPage;
