import { ReactNode } from "react";
import { BackIcon } from "../icons";
import useStyles from "./styles";

interface Props {
  children: ReactNode;
}

const Box = (props: Props) => {
  const classes = useStyles();

  return <div className={classes.box}>{props.children}</div>;
};

export default Box;
