import useStyles from "./styles";
import { PageType } from "../../../../hooks/use-navigate";
import { ReactNode } from "react";
import useAuthStore from "../../../../hooks/use-auth";
import { NavLink, useLocation } from "react-router-dom";

interface IProps {
  name: string;
  route: string;
  icon: ReactNode;
  page?: PageType;
  isLogout?: boolean;
}

const NavItem = (props: IProps) => {
  const { name, route, icon, isLogout } = props;
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const { logout } = useAuthStore();

  const onClick = () => {
    logout();
  };

  const isActive =
    pathname.startsWith(route) ||
    (name === "Assets" && pathname.startsWith("/asset"));

  const containerClassNames = [classes.navItem];
  const iconClassNames = [classes.icon];

  if (isActive) {
    containerClassNames.push(classes.navItemCurrent);
    iconClassNames.push(classes.iconCurrent);
  }

  if (isLogout) {
    return (
      <button className={containerClassNames.join(" ")} onClick={onClick}>
        <div className={classes.icon}>{icon}</div>
        {name}
      </button>
    );
  } else {
    return (
      <NavLink to={route} className={containerClassNames.join(" ")}>
        <div className={iconClassNames.join(" ")}>{icon}</div>
        {name}
      </NavLink>
    );
  }
};

export default NavItem;
