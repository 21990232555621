import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useNavigate } from "../hooks/use-navigate";
import { Domain, getDomain } from "../utils/get-domain";

const DeepLinkUrlListener = () => {
  let { goTo } = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      if (event.url.includes("email-login")) {
        const path = getPathFromUrlEvent(event.url);
        goTo(path);
      }
    });
  }, []);

  return null;
};

const getPathFromUrlEvent = (urlEvent: string) => {
  if (urlEvent.includes("parisinsights://")) {
    return urlEvent.replace("parisinsights:/", "");
  }

  if (urlEvent.includes("aileeninsights://")) {
    return urlEvent.replace("aileeninsights:/", "");
  }

  const url = (getDomain() === Domain.SNXT)
    ? process.env.REACT_APP_AI_INSIGHTS_WEBAPP_URL
    : process.env.REACT_APP_PARIS_INSIGHTS_WEBAPP_URL

  return urlEvent.replace(`${url}`,"");
}

export default DeepLinkUrlListener;