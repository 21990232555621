import { Stack } from "@mui/material";
import { TariffPage } from "../../types";
import useNewTariffStore from "../../new-tariff-store";
import AnimationContainer from "../../animation-container";
import DatePicker from "../../../../../../../core/date-picker";
import NewTariffActions from "../../actions";
import useStyles from "./styles";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SimpleTariffDates = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { type, setPage, tariffRates } = useNewTariffStore();

  const onNext = () => {
    let nextPage: TariffPage = "flatRate";

    switch (type) {
      case "eco7":
        nextPage = "eco7";
        break;

      case "custom":
        nextPage = "custom";
        break;

      case "variableRate":
        break;
    }

    setPage(nextPage);
  };

  const onChangeDate = (date: string, field: "startDate" | "endDate") => {
    const newTariffRates = [...tariffRates];
    newTariffRates[0][field] = date;

    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  const { startDate, endDate } = tariffRates[0];

  const canNext = moment(startDate, "DD/MM/YYYY", true).isValid();
  return (
    <div>
      <AnimationContainer>
        <div>{t("Please_enter_start_end_date")}</div>

        <Stack direction="row" justifyContent={"space-around"} marginTop={1}>
          <div>
            <div className={classes.label}>{t("Start_Date")}</div>
            <DatePicker
              date={startDate}
              setDate={(date) => onChangeDate(date, "startDate")}
            />
          </div>

          <div>
            <div className={classes.label}>{t("End_Date")}</div>
            <DatePicker
              date={endDate}
              setDate={(date) => onChangeDate(date, "endDate")}
            />
          </div>
        </Stack>
      </AnimationContainer>
      <br />
      <NewTariffActions
        canBack={true}
        canNext={canNext}
        onBack={() => setPage("details")}
        onNext={onNext}
      />
    </div>
  );
};

export default SimpleTariffDates;
