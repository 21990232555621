import { useEffect, useState } from "react";
import {
  BatteryBoltIcon,
  LightbulbIcon,
  TransformerBoltIcon,
  SolarPanelIcon,
  WindTurbineIcon,
} from "../../../core/icons";
import client from "../../../utils/client";
import { TZ_OFFSET } from "../../../utils/constant";
import useCurrentSiteStore, { SitePage } from "../hooks/use-sites";
import AssetContainer from "./container";
import useStyles from "./styles";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Loader from "../../../core/loader";
import { useTranslation } from "react-i18next";
import SiteAddress from "../site-address";
import moment from "moment";

interface Props {
  siteData: any;
}

const SiteAssets = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [generationAssets, setGenerationAssets] = useState<any[]>([]);
  const [batteryAssets, setBatteryAssets] = useState<any[]>([]);
  const [consumptionPoints, setConsumptionPoints] = useState<any[]>([]);
  const [meteringPoints, setMeteringPoints] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { setPage } = useCurrentSiteStore();
  const { siteId } = useParams();

  useEffect(() => {
    setPage(SitePage.Assets);

    const load = async () => {
      try {
        setLoading(true);

        const res = await client.post(
          "/kdbGateway/assetMetrics/assetsSummaryMetrics",
          { siteId, tzOffset: TZ_OFFSET }
        );

        const {
          generationSummaryMetrics,
          batterySummaryMetrics,
          consumptionSummaryMetrics,
          siteMeterSummaryMetrics,
        } = res.data.response;

        setGenerationAssets(generationSummaryMetrics);
        setBatteryAssets(batterySummaryMetrics);
        setConsumptionPoints(consumptionSummaryMetrics);
        setMeteringPoints(siteMeterSummaryMetrics);
        setLoading(false);
      } catch {
        enqueueSnackbar(t("Error_loading_site_assets"), {
          variant: "error",
          persist: false,
        });
      }
    };

    load();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (
    meteringPoints.length == 0 &&
    consumptionPoints.length == 0 &&
    batteryAssets.length == 0 &&
    generationAssets.length == 0
  )
    return <div>{t("No_assets_display_site")}</div>;

  return (
    <div className={classes.container}>
      {batteryAssets.map((b, index) => {
        return (
          <AssetContainer
            key={b.sym}
            id={b.sym}
            route="battery"
            title={
              batteryAssets.length > 1
                ? t("Battery") + " " + (index + 1)
                : t("Battery")
            }
            subtitle={batteryAssets.length > 1 ? "#" + b.serialNumber : ""}
            icon={
              <div className={classes.rotate}>
                <BatteryBoltIcon transform="inherit" className={classes.icon} />
              </div>
            }
            metrics={[
              {
                name: `${t("Current_Charge")}:`,
                value: (
                  Math.round((b.stateOfCharge + Number.EPSILON) * 100) / 100
                ).toString(),
                unit: "%",
                showOnMobile: true,
                mobileName: `${t("Current_Charge")}:`,
              },
            ]}
            lastUpdated={b.time}
          />
        );
      })}

      {consumptionPoints.map((cp, index) => {
        return (
          <AssetContainer
            key={cp.sym}
            id={cp.sym}
            route="consumption"
            title={t("Consumption")}
            mobileSubtitle={t("last_7_days")}
            icon={<LightbulbIcon className={classes.icon} />}
            metrics={[
              {
                name: `${t("Current_consumption")}:`,
                value: (cp.currentConsumptionKw || 0).toFixed(2),
                unit: t("suffix_kW"),
                showOnMobile: false,
              },
              {
                name: t("Con_last_7_days"),
                value: (cp.weekTotalConsumptionKwh || 0).toFixed(2),
                unit: t("suffix_kWh"),
                showOnMobile: true,
                mobileName: t("Exported"),
              },
            ]}
            lastUpdated={cp.time}
          />
        );
      })}

      {generationAssets.map((g, index) => {
        const isWind =
          g.generationType != null &&
          g.generationType.generationTypeCode == "WI";
        return (
          <AssetContainer
            key={g.sym}
            id={g.sym}
            route="production"
            title={isWind ? "Wind Turbine" : t("Solar_Panel")}
            mobileSubtitle={t("last_7_days")}
            icon={
              isWind ? (
                <WindTurbineIcon className={classes.icon} />
              ) : (
                <SolarPanelIcon className={classes.icon} />
              )
            }
            metrics={[
              {
                name: `${t("Current_generation")}:`,
                value: (g.generationKw || 0).toFixed(2),
                unit: t("suffix_kW"),
                showOnMobile: false,
              },
              {
                name: t("Gen_last_7_days"),
                value: (g.weekTotalGenKwh || 0).toFixed(2),
                unit: t("suffix_kWh"),
                showOnMobile: true,
                mobileName: `${t("Energy_Generated")}`,
              },
            ]}
            lastUpdated={g.time}
          />
        );
      })}

      {meteringPoints.map((mp, index) => {
        return (
          <AssetContainer
            key={mp.sym}
            id={mp.sym}
            route="metering-point"
            title={t("Metering_Point")}
            mobileSubtitle={t("last_7_days")}
            icon={<TransformerBoltIcon className={classes.icon} />}
            metrics={[
              {
                name: t("Exp_last_7_days"),
                value: (mp.weekTotalExportedKwh || 0).toFixed(2),
                unit: t("suffix_kWh"),
                showOnMobile: true,
                mobileName: t("Exported"),
              },
              {
                name: t("Imp_last_7_days"),
                value: (mp.weekTotalImportedKwh || 0).toFixed(2),
                unit: t("suffix_kWh"),
                showOnMobile: true,
                mobileName: t("Imported"),
              },
            ]}
            lastUpdated={mp.time}
          />
        );
      })}

      <div className="ion-hide-sm-up">
        <SiteAddress siteData={props.siteData}></SiteAddress>
      </div>
    </div>
  );
};

export default SiteAssets;
