import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  actions: {
    display: "flex",
    gap: 10,
  },

  subheader: {
    display: "flex",
    alignItems: "center",
    gap: 15,
  },

  change: {
    all: "unset",
    color: theme.primary.buttonBackgroundColor,
    fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

export default useStyles;
