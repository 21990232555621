import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  action: {
    all: "unset",
    display: "flex",
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    color: theme.primary.textColor2,
    cursor: "pointer",

    "&:hover": {
      opacity: 0.7,
    },
  },

  active: {
    backgroundColor: theme.primary.buttonBackgroundColor,
    color: theme.primary.buttonTextColor,
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default useStyles;
