import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import Box from "../../../../../core/box";

interface Props {
  tariff: any;
}

const TariffDetails = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    tariffClass,
    tariffRef,
    tariffName,
    tariffDesc,
    supplierName,
    marketAreaName,
    daylightSavings,
    organisationName,
    customerName,
  } = props.tariff;

  return (
    <div className={classes.tariffDetails}>
      <Box>
        <h3>{t("Tariff_Details")}</h3>

        <div className={classes.detail}>
          <span className={classes.key}>{t("Tariff_Name")}:</span> {tariffName}
        </div>

        <div className={classes.detail}>
          <span className={classes.key}>{t("Customer")}:</span> {customerName}
        </div>

        <div className={classes.detail}>
          <span className={classes.key}>{t("Organisation")}:</span>{" "}
          {organisationName}
        </div>

        <div className={classes.detail}>
          <span className={classes.key}>{t("Tariff_Class")}:</span>{" "}
          {tariffClass}
        </div>
        <div className={classes.detail}>
          <span className={classes.key}>{t("Tariff_Ref")}:</span>
          {tariffRef}
        </div>

        <div className={classes.detail}>
          <span className={classes.key}>{t("Tariff_Description")}:</span>{" "}
          {tariffDesc}
        </div>
        <div className={classes.detail}>
          <span className={classes.key}>{t("Supplier")}:</span> {supplierName}
        </div>
        <div className={classes.detail}>
          <span className={classes.key}>{t("Market_Area")}:</span>{" "}
          {marketAreaName}
        </div>
        <div className={classes.detail}>
          <span className={classes.key}>{t("Apply_Daylight_Savings")}:</span>{" "}
          {daylightSavings ? t("Yes") : t("No")}
        </div>
      </Box>
    </div>
  );
};

export default TariffDetails;
