import { ReactNode } from "react";
import useStyles from "./styles";
import { useNavigate } from "../../../hooks/use-navigate";
import { useLocation } from "react-router-dom";

interface Props {
  icon: ReactNode;
  route: string;
}

const TopBarAction = (props: Props) => {
  const classes = useStyles();
  const { route, icon } = props;
  const location = useLocation();
  const { pathname } = location;

  const isActive = pathname.startsWith(route);

  const style = [classes.action];
  if (isActive) style.push(classes.active);

  const { goTo } = useNavigate();
  return (
    <button className={style.join(" ")} onClick={() => goTo(route)}>
      {icon}
    </button>
  );
};

export default TopBarAction;
