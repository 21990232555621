import React from "react";
import { IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { PageType, useNavigate } from "../../hooks/use-navigate";
import MenuBarHeader from "./menu-bar-header";
import BackArrow from "../../core/icons/arrow-left.svg";
import { useLocation } from "react-router-dom";
import { useTheme } from "react-jss";

interface PageRouteProps {
  page: Page;
  route: string;
  translationId: string;
}

enum Page {
  Battery,
  Consumption,
  Production,
  MeteringPoint,
  Help,
  Settings,
}

const pageRouteProps: PageRouteProps[] = [
  { page: Page.Battery, route: "battery", translationId: "Battery" },
  {
    page: Page.Consumption,
    route: "consumption",
    translationId: "Consumption_Point",
  },
  { page: Page.Production, route: "production", translationId: "Solar_Panel" },
  {
    page: Page.MeteringPoint,
    route: "metering-point",
    translationId: "Metering_Point",
  },
  { page: Page.Help, route: "help", translationId: "Help_and_Support" },
  { page: Page.Settings, route: "settings", translationId: "Account" },
];

function MobileHeader() {
  const { t } = useTranslation();
  const { goBack, page } = useNavigate();
  const { pathname } = useLocation();
  const theme: any = useTheme();

  if (pathname.startsWith("/sites")) return <MenuBarHeader />;

  const routeProps = pageRouteProps.find((page) =>
    pathname.includes(page.route)
  );

  if (!routeProps) return <></>;

  const showBack = pathname.startsWith("/asset");

  return (
    <>
      <IonHeader
        data-testid="ionic-header"
        className="mobile-header"
        mode="ios"
      >
        <IonToolbar
          color="new"
          style={{
            backgroundColor: theme.primary.buttonBackgroundColor,
            color: theme.primary.buttonTextColor,
          }}
        >
          {showBack && (
            <IonIcon
              data-testid="ionic-header-back-arrow"
              size="small"
              icon={BackArrow}
              onClick={() => goBack()}
            />
          )}
          <IonTitle data-testid={`ionic-header-${routeProps.route}-title`}>
            {t(routeProps.translationId)}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    </>
  );
}

export default MobileHeader;
