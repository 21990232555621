import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  date: {
    fontSize: 13,
  },

  label: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.primary.textColor2,
    width: 90,
  },
}));

export default useStyles;
