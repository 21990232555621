import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  chart: {
    paddingBottom: 25,
  },

  button: {
    all: "unset",
    backgroundColor: theme.primary.primary5,
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 15,
    marginBottom: 40,
    marginTop: 10,

    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },

  details: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.grey.grey5,
  },
}));

export default useStyles;
