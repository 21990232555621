import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { DateRange, MQYDateRange, MWDDateRange } from "../../../utils/types";
import DateSelector from "../default";
import { useTranslation } from "react-i18next";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import "./styles.css"

interface Props {
  dateType: MQYDateRange;
  setType: Dispatch<SetStateAction<MQYDateRange>>;
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  graphTitle: string;
  hasCustom?: boolean;
}

const MonthQuarterYearDateSelector = (props: Props) => {
  const { t } = useTranslation();
  const { dateType, setType, setDateRange, dateRange, graphTitle, hasCustom } = props;

  return (
    <IonGrid className={"date-selector"}>
      <IonRow>
        <IonCol className="centre">{graphTitle}</IonCol>
        <IonCol>
          <DateSelector
            currentType={dateType}
            hasCustom={hasCustom}
            customType={MWDDateRange.Custom}
            dates={[
              {
                title: t("Year"),
                dateRage: {
                  startDateTime: moment().subtract(1, "year").toISOString(),
                  endDateTime: moment().toISOString(),
                },
                type: MQYDateRange.Year,
              },
              {
                title: `3 ${t("Months")}`,
                dateRage: {
                  startDateTime: moment().subtract(3, "months").toISOString(),
                  endDateTime: moment().toISOString(),
                },
                type: MQYDateRange.Quarter,
              },
              {
                title: `30 ${t("Days")}`,
                dateRage: {
                  startDateTime: moment().subtract(30, "days").toISOString(),
                  endDateTime: moment().toISOString(),
                },
                type: MQYDateRange.Month,
              },
            ]}
            setType={setType}
            setDateRange={setDateRange}
            dateRange={dateRange}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MonthQuarterYearDateSelector;
