import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import client from "../../../utils/client";
import { enqueueSnackbar } from "notistack";
import useAuthStore from "../../../hooks/use-auth";
import { PageType, useNavigate } from "../../../hooks/use-navigate";
import { useTranslation } from "react-i18next";
import Input from "../../../core/input";
import { Domain, getDomain } from "../../../utils/get-domain";
import Button from "../../../core/button";

const HelpContact = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string | null>(null);
  const [messageSent, setMessageSent] = useState(false);
  const { currentUser } = useAuthStore();
  const { setPage } = useNavigate();
  const domain = getDomain();
  const origin = domain == Domain.TESC ? "parisinsights" : "aileen";

  useEffect(() => {
    setPage(PageType.Help);
  }, []);

  function handleKeyDown(event: { key: string }) {
    if (event.key === "Enter") {
      onSend();
    }
  }

  const onSend = async () => {
    const req = {
      message,
      origin,
    };
    try {
      const res = await client.post("/helpEmail/send", req);

      setMessageSent(true);
      enqueueSnackbar(t("Email_Sent"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_sending_message"), {
        variant: "error",
        persist: false,
      });
    }
  };

  if (messageSent) {
    return (
      <div>
        <h2>{t("Contact_Us")}</h2>

        <div>{t("Message_sent")}</div>
      </div>
    );
  }

  return (
    <div>
      <h2 style={{ paddingBottom: 10 }}>{t("Contact_Us")}</h2>
      <div>
        <Input
          multiline
          rows={4}
          onKeyDown={handleKeyDown}
          placeholder={t("Enter_message_here")}
          value={message}
          onChange={setMessage}
          fullWidth
        />
      </div>

      <Stack marginTop={2}>
        <Button
          style={{ maxWidth: "200px", marginLeft: "auto" }}
          onClick={onSend}
          id="Send-Message-Button"
          variant={"contained"}
        >
          {t("Send_Message")}
        </Button>
      </Stack>
    </div>
  );
};

export default HelpContact;
