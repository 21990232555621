import { Stack } from "@mui/material";
import Button from "../../../../../../../core/button";
import Input from "../../../../../../../core/input";
import Select from "../../../../../../../core/select";
import useNewTariffStore from "../../new-tariff-store";
import TimePicker from "../../../../../../../core/time-picker";
import DaysActive from "../../../../../../../core/days-active";
import Checkbox from "../../../../../../../core/checkbox";
import { useState } from "react";

interface Props {
  chargeIndex: number;
}

const CustomTariffAdditionalCharge = (props: Props) => {
  const { chargeIndex } = props;
  const { tariffRates, customRateIndex, currency, pricingUnits, baseCharges } =
    useNewTariffStore();
  const charge = tariffRates[customRateIndex].additionalCharges![chargeIndex];
  const {
    amount,
    fkPricingUnitId,
    startTime,
    endTime,
    chargeName,
    fkBaseChargeId,
  } = charge;
  const [newCharge, setNewCharge] = useState(chargeName != "");

  const onChangeCharge = (value: any, field: string) => {
    const newTariffRates = [...tariffRates] as any;
    newTariffRates[customRateIndex].additionalCharges[chargeIndex][field] =
      value;
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  const onCheck = (value: boolean) => {
    const newTariffRates = [...tariffRates];
    newTariffRates[customRateIndex].additionalCharges![chargeIndex].chargeName =
      undefined;
    newTariffRates[customRateIndex].additionalCharges![
      chargeIndex
    ].fkBaseChargeId = undefined;
    useNewTariffStore.setState({ tariffRates: newTariffRates });
    setNewCharge(value);
  };

  const onDelete = () => {
    const newTariffRates = [...tariffRates];
    newTariffRates[customRateIndex].additionalCharges!.splice(chargeIndex, 1);
    useNewTariffStore.setState({ tariffRates: newTariffRates });
  };

  const selectedPricingUnit =
    pricingUnits.find((pu) => pu.pricingUnitId == fkPricingUnitId) || {};
  const { pricingUnit } = selectedPricingUnit;
  const datesDisabled = pricingUnit != "per kWh";

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      width={"100%"}
      gap={2}
      marginTop={2}
    >
      <Checkbox
        label="New"
        onChange={onCheck}
        checked={newCharge}
        labelPlacement="top"
      />

      {newCharge ? (
        <Input
          label="Charge Name"
          onChange={(value) => onChangeCharge(value, "chargeName")}
          value={chargeName!}
        />
      ) : (
        <Select
          label="Charge"
          value={fkBaseChargeId}
          onChange={(value) => onChangeCharge(value, "fkBaseChargeId")}
          options={baseCharges.map((bc) => {
            return { value: bc.baseChargeId, label: bc.chargeName };
          })}
        />
      )}

      <Input
        value={amount}
        onChange={(value) => onChangeCharge(value, "amount")}
        label={"Amount"}
        type="number"
        prefix={currency}
      />
      <Select
        label="Pricing Unit "
        value={fkPricingUnitId}
        onChange={(value) => {
          const selectedPricingUnit =
            pricingUnits.find((pu) => pu.pricingUnitId == value) || {};
          const { pricingUnit } = selectedPricingUnit;

          if (pricingUnit != "per kWh") {
            onChangeCharge("", "startTime");
            onChangeCharge("", "endTime");
          }
          onChangeCharge(value, "fkPricingUnitId");
        }}
        options={pricingUnits.map((pu) => {
          return { value: pu.pricingUnitId, label: pu.pricingUnit };
        })}
      />

      <TimePicker
        label="Start Time"
        time={startTime}
        setTime={(time) => onChangeCharge(time, "startTime")}
        disabled={datesDisabled}
      />

      <TimePicker
        label="End Time"
        time={endTime}
        setTime={(time) => onChangeCharge(time, "endTime")}
        disabled={datesDisabled}
      />

      <DaysActive
        onChange={(value, field) => onChangeCharge(value, field)}
        monday={charge.monday}
        tuesday={charge.tuesday}
        wednesday={charge.wednesday}
        thursday={charge.thursday}
        friday={charge.friday}
        saturday={charge.saturday}
        sunday={charge.sunday}
      />

      <Button colour={"error"} onClick={onDelete}>
        X
      </Button>
    </Stack>
  );
};

export default CustomTariffAdditionalCharge;
