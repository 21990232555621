import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  button: {
    all: "unset",
    cursor: "pointer",
    border: "1px solid " + theme.grey.grey3,
    borderRadius: 5,
    backgroundColor: theme.grey.grey1,
    color: theme.grey.grey3,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
  },

  selected: {
    border: "1px solid " + theme.primary.primary5,
    backgroundColor: theme.primary.primary1,
    color: theme.primary.primary5,
  },
}));

export default useStyles;
