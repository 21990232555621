import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "../../../../../../core/button";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import client from "../../../../../../utils/client";
import { TZ_OFFSET } from "../../../../../../utils/constant";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Input from "../../../../../../core/input";
import Select from "../../../../../../core/select";
import TimePicker from "../../../../../../core/time-picker";
import DaysActive from "../../../../../../core/days-active";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../core/modal";

interface Props {
  open: boolean;
  onClose: () => void;
  load: () => void;
  selectedRatePeriod: any;
  currency: string;
}

const EditRatePeriodDialog = (props: Props) => {
  const { open, load, selectedRatePeriod, currency } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [editRatePeriod, setEditRatePeriod] = useState<any>({});

  useEffect(() => {
    setEditRatePeriod({ ...selectedRatePeriod });
  }, [selectedRatePeriod]);

  const onSubmit = async () => {
    try {
      await client.put(
        `/tariffRatePeriods/update/${TZ_OFFSET}`,
        editRatePeriod
      );

      onClose();
      load();
      enqueueSnackbar(t("Rate_Updated"), {
        variant: "success",
        persist: false,
      });
    } catch {
      enqueueSnackbar(t("Error_updating_rate"), {
        variant: "error",
        persist: false,
      });
    }
  };

  const onClose = () => {
    props.onClose();
    setEditRatePeriod({});
  };

  const onChange = (value: any, field: string) => {
    const newDetails = { ...editRatePeriod };
    newDetails[field] = value;

    setEditRatePeriod(newDetails);
  };

  const isValidData =
    editRatePeriod.periodName != "" &&
    editRatePeriod.unitPriceKwh > 0 &&
    editRatePeriod.direction != "" &&
    moment(editRatePeriod.startTime, t("time_format_s"), true).isValid() &&
    moment(editRatePeriod.endTime, t("time_format_s"), true).isValid();

  return (
    <Modal open={open} onClose={onClose}>
      <DialogTitle>{t("Edit_Existing_Rate")}</DialogTitle>
      <DialogContent>
        <div>{t("Enter_details_for_rate")}</div>
        <br />
        <div className={classes.editPeriodGrid}>
          <Input
            label={t("Name")}
            value={editRatePeriod.periodName}
            onChange={(value) => onChange(value, "periodName")}
          />

          <Input
            label={t("Unit_Price")}
            value={editRatePeriod.unitPriceKwh}
            onChange={(value) => onChange(value, "unitPriceKwh")}
            prefix={currency}
          />
          <Select
            label={t("Direction")}
            value={editRatePeriod.direction}
            onChange={(direction) => onChange(direction, "direction")}
            options={[
              { label: t("Import"), value: "Import" },
              { label: t("Export"), value: "Export" },
            ]}
            style={{ width: "100%" }}
          />

          <TimePicker
            label={t("Start_Time")}
            time={editRatePeriod.startTime}
            setTime={(time) => onChange(time + ":00", "startTime")}
          />

          <TimePicker
            label={t("End_Time")}
            time={editRatePeriod.endTime}
            setTime={(time) => onChange(time + ":00", "endTime")}
          />

          <DaysActive
            onChange={onChange}
            monday={editRatePeriod.monday}
            tuesday={editRatePeriod.tuesday}
            wednesday={editRatePeriod.wednesday}
            thursday={editRatePeriod.thursday}
            friday={editRatePeriod.friday}
            saturday={editRatePeriod.saturday}
            sunday={editRatePeriod.sunday}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={onSubmit}
          variant={"contained"}
          disabled={!isValidData}
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default EditRatePeriodDialog;
