import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange, MWDDateRange } from "../../../utils/types";
import CustomDialog from "./custom-dialog";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { isNativeMobile } from "../../../utils/ionic";
import MobileDatePickerModal from "../../../mobile/date-picker-modal";

type DateItem = { title: string; dateRage: DateRange; type: any };

interface Props {
  currentType: any;
  dates: DateItem[];
  setType: Dispatch<SetStateAction<any>>;
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  hasCustom?: boolean;
  customType?: any;
}

const DateSelector = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    currentType,
    dates,
    dateRange,
    setDateRange,
    setType,
    customType,
    hasCustom
  } = props;

  const currentTitle = dates.find(item => item.type === currentType)?.title;

  const [customOpen, setCustomOpen] = useState(false);
  const [currentText, setCurrentText] = useState(currentTitle);

  const customSelected = customType == currentType;
  const customStyle = [classes.button];
  if (customSelected) customStyle.push(classes.selected);

  const updateDateRangeSelection = (selectedType: any) => {
    if (selectedType === MWDDateRange.Custom) {
      setType(MWDDateRange.Custom);
      setCustomOpen(true);
    } else {
      const selectedDate = dates.find((date) => date.type === selectedType);
      if (selectedDate) {
        setCurrentText(selectedDate.title);
        setType(selectedType);
        setDateRange(selectedDate.dateRage);
      }
    }
  };

  return (
    <>
      {isNativeMobile()
       ? <MobileDatePickerModal
          open={customOpen}
          closeDialog={() => setCustomOpen(false)}
          dateRange={dateRange}
          onDateChange={setDateRange}
          setCustomType={() => setType(customType)}
        />
        : <CustomDialog
          open={customOpen}
          closeDialog={() => setCustomOpen(false)}
          dateRange={dateRange}
          onDateChange={setDateRange}
          setCustomType={() => setType(customType)}
        />
      }
      <IonSelect
        interface={isNativeMobile() ? "action-sheet": "popover"}
        value={currentType}
        selectedText={currentText}
        onIonChange={(event) => updateDateRangeSelection(event.detail.value)}
        cancelText={t("Cancel")}
        data-testid={"range-selection-component"}>
        {dates.map((date, index) => (
          <IonSelectOption
            key={index}
            value={date.type}
            data-testid={"option-" + date.title}>
            {date.title}
          </IonSelectOption>
        ))}
       {hasCustom ?? (
        <IonSelectOption
          value={MWDDateRange.Custom}
          data-testid={"option-Custom"}>
          {t("Custom")}
        </IonSelectOption>
       )}
      </IonSelect>
    </>
  );
};

export default DateSelector;
