import useStyles from "./styles";
import useCurrentSiteStore, { SitePage } from "../hooks/use-sites";
import { useEffect, useState } from "react";
import FinanceTotals from "./totals";
import LineGraph from "./line-graph";
import moment from "moment";
import { DateRange, MQYDateRange } from "../../../utils/types";
import MonthQuarterYearDateSelector from "../../../core/period-selector/month-quarter-year";
import { enqueueSnackbar } from "notistack";
import { TZ_OFFSET } from "../../../utils/constant";
import client from "../../../utils/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isNativeMobile } from "../../../utils/ionic";
import Box from "../../../core/box";

interface Props {
  site: any;
}

function mergeTimestamps(
  arrayOfNumbers: number[],
  timestamps: string[]
): { x: number; y: number }[] {
  if (!timestamps) {
    return [];
  }

  return timestamps.map((tz: string, idx: number) => ({
    x: parseInt(tz),
    y: arrayOfNumbers[idx],
  }));
}

const SiteFinance = (props: Props) => {
  const { site } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPage } = useCurrentSiteStore();
  const [dateType, setType] = useState(MQYDateRange.Month);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDateTime: moment().subtract(30, "days").toISOString(),
    endDateTime: moment().toISOString(),
  });
  const [loading, setLoading] = useState(true);
  const { siteId } = useParams();
  const [data, setData] = useState<any>({});

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await client.post("/kdbGateway/site/getFinances", {
        siteId,
        startDate: moment(dateRange.startDateTime).format(t("date_format2")),
        endDate: moment(dateRange.endDateTime).format(t("date_format2")),
        tzOffset: TZ_OFFSET,
      });

      setData(res.data.response);
      setLoading(false);
    } catch {
      enqueueSnackbar(t("Error_loading_finance_data"), {
        variant: "error",
        persist: false,
      });
    }
  };

  useEffect(() => {
    setPage(SitePage.Finance);
    void loadData();
  }, [dateRange]);

  return (
    <div className={classes.container}>
      <MonthQuarterYearDateSelector
        dateType={dateType}
        dateRange={dateRange}
        setType={setType}
        setDateRange={setDateRange}
        graphTitle={t("Totals")}
      />
      {!isNativeMobile() && (
        <>
          <br />
          <FinanceTotals
            wouldHaveSpent={data.totalEstimatedSpend}
            actualSpending={data.totalSpending}
            savings={data.totalSavings}
            levelisedCost={data.levelisedCost}
            totalRev={data.totalExportRevenue}
            currency={site.parentMarketCurrency}
          />
        </>
      )}
      <Box>
        <div className={classes.chart}>
          <LineGraph
            loading={loading}
            labels={data.labels}
            saved={mergeTimestamps(data.savings, data.labels)}
            spending={mergeTimestamps(data.spending, data.labels)}
            wouldHaveSpent={mergeTimestamps(
              data.estimatedSpending,
              data.labels
            )}
            exportedRev={mergeTimestamps(data.exportRevenue, data.labels)}
            currency={site.parentMarketCurrency}
            dateRange={dateRange}
          />
        </div>
      </Box>
      {isNativeMobile() && (
        <>
          <br />
          <FinanceTotals
            wouldHaveSpent={data.totalEstimatedSpend}
            actualSpending={data.totalSpending}
            savings={data.totalSavings}
            levelisedCost={data.levelisedCost}
            totalRev={data.totalExportRevenue}
            currency={site.parentMarketCurrency}
          />
        </>
      )}
    </div>
  );
};

export default SiteFinance;
