import { Stack } from "@mui/material";
import Button from "../../../../../../core/button";
import useNewTariffStore from "../new-tariff-store";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";

interface Props {
  canBack?: boolean;
  canNext?: boolean;
  isSubmit?: boolean;

  onNext?: () => void;
  onBack?: () => void;
}

const NewTariffActions = (props: Props) => {
  const { t } = useTranslation();
  const { canNext, canBack, onNext, onBack, isSubmit } = props;
  const { onClose } = useNewTariffStore();

  return (
    <Stack direction={"row"} justifyContent={"space-between"} marginTop={2}>
      <Button onClick={onClose} colour={"error"} variant={"contained"}>
        {t("Cancel")}
      </Button>
      <Stack direction={"row"} spacing={1}>
        <Button
          onClick={onBack!}
          variant={"contained"}
          disabled={!canBack}
          startIcon={<ArrowLeftIcon />}
        >
          {t("Back")}
        </Button>
        <Button
          onClick={onNext!}
          variant={"contained"}
          disabled={!canNext}
          endIcon={isSubmit ? null : <ArrowRightIcon />}
        >
          {isSubmit ? t("Submit") : t("Next")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default NewTariffActions;
