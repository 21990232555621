import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
  icon: {
    color: theme.primary.primary5,
    cursor: "pointer",
  },

  popover: {
    background: theme.primary.buttonBackgroundColor,
    color: theme.primary.buttonTextColor,
    padding: 5,
  },
}));

export default useStyles;
